import React, { ReactElement } from 'react'

import { REVENUE_TYPE, REVENUE_TYPE_LABEL, RevenueType } from '../../../../types/Report/RevenueType'
import { DataTh, SortControl } from '../../../DataTable'
import { HELP_TEXTS } from '../../../../constants'
import { SortIconState } from '../../../common/SortButton'
import { colors } from '../../../../styleConstants'

interface Props {
  readonly revenueType: RevenueType | null
  readonly sortKey: string
  readonly sortIcon: SortIconState
  readonly onSortClick: (sortKey: string, sortIcon: SortIconState) => void
  readonly onGoalChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

export const RevenueAmountHeader = ({ revenueType, sortKey, sortIcon, onSortClick }: Props): ReactElement | null => {
  if (!revenueType) return null

  return (
    <DataTh backgroundColor={colors.lightSalmon} minWidth="169px">
      <SortControl
        subTitle={'(トランザクション数)'}
        helpText={revenueType === REVENUE_TYPE.PURCHASE ? HELP_TEXTS.REVENUE_PURCHASE : HELP_TEXTS.REVENUE_ESTIMATED}
        helpWidth={300}
        helpLeft={-300}
        sortKey={sortKey}
        sortIcon={sortIcon}
        onSortClick={onSortClick}
      >
        {REVENUE_TYPE_LABEL[revenueType]}
      </SortControl>
    </DataTh>
  )
}
