import * as React from 'react'
import styled from 'styled-components'
import { GoalState } from '..'
import { GoalType, MatchMap, ValueConditionMap } from '../../../../util/Goal'
import { EventCondition } from './EventCondition'
import { InteractiveTooltip } from '../../../Tooltip'

const isUrl = (
  goal: GoalState,
): goal is GoalState & {
  url: Exclude<GoalState['url'], undefined>
} => {
  return goal.type === GoalType.url
}

const isPurchase = (
  goal: GoalState,
): goal is GoalState & {
  purchase: Exclude<GoalState['purchase'], undefined>
} => {
  return goal.type === GoalType.purchase
}

interface Props {
  readonly goal: GoalState
}

export function ConditionCell({ goal }: Props) {
  return (
    <Container>
      {isPurchase(goal) && <div>dataLayer: 自動送信{goal.purchase.autoSendPurchaseEnabled ? 'ON' : 'OFF'}</div>}
      {isUrl(goal) ? (
        <InteractiveTooltip content={goal.url.url}>
          <Url>
            {MatchMap[goal.url.condition]}: {goal.url.url}
          </Url>
        </InteractiveTooltip>
      ) : (
        <Condition>
          <InteractiveTooltip content={makeEventTooltipContent(goal.event)}>
            <div>
              {goal.event?.category_condition && goal.event?.category && (
                <EventCondition
                  type="カテゴリ"
                  condition={MatchMap[goal.event.category_condition]}
                  value={goal.event.category}
                />
              )}
              {goal.event?.action_condition && goal.event?.action && (
                <EventCondition
                  type="アクション"
                  condition={MatchMap[goal.event.action_condition]}
                  value={goal.event.action}
                />
              )}
              {goal.event?.label_condition && goal.event?.label && (
                <EventCondition
                  type="ラベル"
                  condition={MatchMap[goal.event.label_condition]}
                  value={goal.event.label}
                />
              )}
              {goal.event?.value_condition && goal.event?.value != null && (
                <EventCondition
                  type="値"
                  condition={ValueConditionMap[goal.event.value_condition]}
                  value={goal.event.value}
                />
              )}
            </div>
          </InteractiveTooltip>
        </Condition>
      )}
    </Container>
  )
}

const makeEventTooltipContent = (event: GoalState['event']) => {
  if (!event) return null
  return (
    <>
      {event.category && event.category_condition && (
        <TooltipRow>
          {MatchMap[event.category_condition]}：{event.category}
        </TooltipRow>
      )}
      {event.action && event.action_condition && (
        <TooltipRow>
          {MatchMap[event.action_condition]}：{event.action}
        </TooltipRow>
      )}
      {event.label && event.label_condition && (
        <TooltipRow>
          {MatchMap[event.label_condition]}：{event.label}
        </TooltipRow>
      )}
      {event.value != null && event.value_condition && (
        <TooltipRow>
          {ValueConditionMap[event.value_condition]}：{event.value}
        </TooltipRow>
      )}
    </>
  )
}

const TooltipRow = styled.div`
  line-height: 14px;
`

const Container = styled.div`
  width: 340px;
`
const Condition = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
const Url = styled(Condition)`
  cursor: pointer;
`
