import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../../styleConstants'
import { Link } from '@gatsbyjs/reach-router'

interface DataContentBaseLinkProps {
  $bold?: boolean
  $underline?: boolean
}

const DataContentLinkBaseStyle = `
  word-break: break-all;
  color: ${colors.link.base};
  text-decoration: none;
  cursor: pointer;
  &:visited {
    color: ${colors.link.visited};
  }
`

const getDataContentLinkCommonStyles = ({ $bold, $underline }: DataContentBaseLinkProps) => `
  ${DataContentLinkBaseStyle}
  ${$bold ? 'font-weight: bold;' : ''}
  ${$underline ? 'text-decoration: underline;' : ''}
`

interface DataContentLinkProps {
  to: string
  bold?: boolean
  underline?: boolean
}

export const DataContentLink: React.FC<DataContentLinkProps> = ({ children, to, bold, underline }) => (
  <StyledDataContentLink to={to} $bold={bold} $underline={underline}>
    {children}
  </StyledDataContentLink>
)

const StyledDataContentLink = styled(Link)<DataContentBaseLinkProps>`
  ${getDataContentLinkCommonStyles}
`
