import * as React from 'react'
import { RouteComponentProps } from '@gatsbyjs/reach-router'
import { ContextType, useContextState } from './state'
import { SCOPE_TYPE, ScopeType, useScopeType } from '../../util/hooks/useScopeType'
import { SORT_KEYS, SortKey } from '../../types/Report/PageReport/SortKey'
import { useGoalId } from '../../util/hooks/useGoalId'
import { useGoalList } from '../../util/hooks/api/Goal/useGoalList'
import { ProjectContext } from '../../pages/ProjectRoot'
import { SORT_ICONS } from '../../components/common/SortButton'
import { useTimelineFirstChoice } from '../../util/hooks/cookie/useTimelineFirstChoice'
import { useTimelineSecondChoice } from '../../util/hooks/cookie/useTimelineSecondChoice'
import { CHOICE_DATASET_KEYS } from '../../util/hooks/api/PageReport/TimelineReport/constants'

export const PageReportContext = React.createContext<ContextType>(null as any)

interface Props extends RouteComponentProps {
  readonly children?: React.ReactNode | React.ReactNode[]
}

/**
 * ページレポートのstate情報を他のレポート系ページで使用できるようProviderで定義する
 *
 * @param {Object} props - The props object containing the children component.
 * @param {React.ReactNode} props.children - The child component(s) to be wrapped.
 */
export function PageReportProvider({ children }: Props) {
  const {
    state: { projectId },
  } = React.useContext(ProjectContext)

  const { scopeType } = useScopeType()
  const { goalId } = useGoalId({ projectId })
  const { getGoal } = useGoalList({ projectId })
  const { firstChoice, setFirstChoice } = useTimelineFirstChoice()
  const { secondChoice, setSecondChoice } = useTimelineSecondChoice()

  const contextValue = useContextState()
  const { sortKey, setSortKey, setSortIcon } = contextValue

  if (!matchSortKeyAndScopeType(sortKey, scopeType)) {
    setSortKey(getDefaultSortKey(scopeType))
  }

  // 売上関連のゴール選択ではなくなった場合、ソートや時系列グラフの指標を解除する
  if (!getGoal(goalId)?.isRevenue) {
    if (sortKey === SORT_KEYS.REVENUE_AMOUNT) {
      setSortKey(getDefaultSortKey(scopeType))
      setSortIcon(SORT_ICONS.DOWN)
    }

    if (firstChoice === CHOICE_DATASET_KEYS.REVENUE_AMOUNT) {
      setFirstChoice(null)
    } else if (secondChoice === CHOICE_DATASET_KEYS.REVENUE_AMOUNT) {
      setSecondChoice(null)
    }
  }

  return <PageReportContext.Provider value={contextValue}>{children}</PageReportContext.Provider>
}

function matchSortKeyAndScopeType(sortKey: SortKey, scopeType: ScopeType): boolean {
  // ソートキーがセッション数・ユーザー数ではない場合、チェック不要
  if (sortKey !== SORT_KEYS.SESSION_COUNT && sortKey !== SORT_KEYS.USER_COUNT) return true

  return (
    (sortKey === SORT_KEYS.SESSION_COUNT && scopeType === SCOPE_TYPE.SESSION) ||
    (sortKey === SORT_KEYS.USER_COUNT && scopeType === SCOPE_TYPE.USER)
  )
}

function getDefaultSortKey(scopeType: ScopeType): SortKey {
  return scopeType === SCOPE_TYPE.USER ? SORT_KEYS.USER_COUNT : SORT_KEYS.SESSION_COUNT
}
