export const REVENUE_TYPE = {
  PURCHASE: 1,
  ESTIMATED: 2,
} as const

export type RevenueType = typeof REVENUE_TYPE[keyof typeof REVENUE_TYPE]

export const REVENUE_TYPE_LABEL = {
  [REVENUE_TYPE.PURCHASE]: 'EC売上〔JPY〕',
  [REVENUE_TYPE.ESTIMATED]: '見込売上〔JPY〕',
} as const
