import * as React from 'react'
import { RouteComponentProps } from '@gatsbyjs/reach-router'

import { GlobalContextType, useContextState } from './state'
import { useReportFilterSelects } from '../../util/hooks/api/Filter/useReportFilterSelects'
import { ProjectContext } from '../../pages/ProjectRoot'
import { ReportContext } from '../ReportProvider'
import { useScopeType } from '../../util/hooks/useScopeType'
import { useDeviceType } from '../../util/hooks/useDeviceType'
import { useGoalId } from '../../util/hooks/useGoalId'
import { useFilterContentEventsExists } from '../../util/hooks/cookie/useFilterContentEventsExists'

export const CustomFilterContext = React.createContext<GlobalContextType>(null as any)

interface Props extends RouteComponentProps {
  readonly children?: React.ReactNode | React.ReactNode[]
}

/**
 * レポートのカスタムフィルターで共通使用する state, actions をグローバルで使用できるよう Provider で定義する
 * actionsのコードがボリュームあるため、ReportContextとは別で管理する。
 *
 * @param {Object} props - The props object containing the children component.
 * @param {React.ReactNode} props.children - The child component(s) to be wrapped.
 */
export function CustomFilterProvider(props: Props) {
  const contextState = useContextState()
  const { state, actions, addonFilterState, cachedReportParams, setCachedReportParams, setIsReportParamsChanged } =
    contextState

  const {
    state: { projectId },
  } = React.useContext(ProjectContext)
  const {
    state: { calenderState },
  } = React.useContext(ReportContext)

  const { data, isLoading } = useReportFilterSelects({ projectId: projectId.toString() })
  const { scopeType } = useScopeType()
  const { deviceType } = useDeviceType()
  const { goalId } = useGoalId({ projectId })
  const { filterContentEventsExists } = useFilterContentEventsExists()

  /**
   * 初期化処理
   * URLパラメータ関連の処理で必要な情報をセットする
   */
  React.useEffect(() => {
    if (isLoading) return

    if (data) actions.setReportFilterSelects(data)
  }, [data])

  /**
   * カスタムディメンションレポートなどの他レポートからページレポートに遷移した時の検索の状態が変更されたらキャッシュをクリアする。
   * キャッシュ用途は、カスタムディメンションレポート／流入レポートなどでリンク遷移から各レポートにに戻った際、
   * 状態が違えばページャーをリセットするために利用
   */
  React.useEffect(() => {
    if (!addonFilterState) {
      setCachedReportParams(undefined)
      return
    }

    setCachedReportParams({
      calenderState,
      goalId,
      scopeType,
      deviceType,
      customFilterState: state.customFilterState,
      filterContentEventsExists,
    })
  }, [addonFilterState])

  React.useEffect(() => {
    if (!addonFilterState || !cachedReportParams) return
    const calenderMatched = calenderState === cachedReportParams?.calenderState
    const goalMatched = goalId === cachedReportParams?.goalId
    const scopeTypeMatched = scopeType === cachedReportParams?.scopeType
    const deviceTypeMatched = deviceType === cachedReportParams?.deviceType
    const customFilterMatched =
      JSON.stringify(state.customFilterState) === JSON.stringify(cachedReportParams?.customFilterState)

    if (!calenderMatched || !goalMatched || !scopeTypeMatched || !deviceTypeMatched || !customFilterMatched) {
      setIsReportParamsChanged(true)
      setCachedReportParams(undefined)
    }
  }, [calenderState, goalId, scopeType, deviceType, state.customFilterState])

  if (!state.selectsData) return null

  return <CustomFilterContext.Provider value={contextState}>{props.children}</CustomFilterContext.Provider>
}
