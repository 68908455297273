export const SEARCH_OPTIONS = {
  INCLUDED_TYPES: {
    match: 0, // 一致
    exclusion: 1, // 除外
  },
  NAMES: {
    url: 0,
    title: 1,
  },
  MATCH_TYPES: {
    full: 0, // 完全一致
    head: 1, // 先頭一致
    partial: 2, // 部分一致
    regexp: 3, // 正規表現一致
  },
}
