// Deprecated:
// このファイルは極力利用しない
// https://github.com/uncovertruth/content-analytics/pull/2781/files#r1904872533

import {
  ContentScreenshotResponse,
  ContentTagResponse,
  ManagedContentResponse,
} from './responses/ContentReportResponse'

// POST /api/login/
// 複数チームに所属している場合
export interface TeamSmallData {
  readonly id: number
  readonly name: string
}

export interface LoginSuccessData {
  readonly code: string
  readonly auth_token: string
}
export interface LoginData {
  readonly code: string
  readonly teams: Array<TeamSmallData>
}

// POST /api/signup/verify/
export interface SignupVerifyData {
  readonly email: string
  readonly team_name: string
}

// POST /api/invite/verify/
export interface InviteVerifyData {
  readonly email: string
  readonly team_name: string
  readonly projects: Array<{
    readonly name: string
  }>
}

// POST /api/password_reset/verify/
export interface PasswordResetVerifyData {
  readonly code: string
  readonly teams: Array<TeamSmallData>
}

// GET /api/admins/
export interface AdminsData {
  readonly count: number
  readonly results: Array<{
    readonly id: number
    readonly username: string
    readonly email: string
    readonly role: number
    readonly role_name: string
    readonly created_at: string
    readonly updated_at: string
  }>
}

// GET,PATCH /api/user/
export interface UserData {
  readonly id: number
  readonly username: string
  readonly email: string
  readonly role_name: string
  readonly permissions: any
  readonly team: TeamData
  readonly created_at: string
  readonly updated_at: string
}

// GET /api/users/
export interface UsersData {
  readonly count: number
  readonly results: Array<{
    readonly id: number
    readonly username: string
    readonly email: string
    readonly role: number
    readonly role_name: string
    readonly created_at: string
    readonly updated_at: string
  }>
}

// GET,PATCH /api/team/
export interface PlanData {
  readonly name: string
  readonly display_name: string
  readonly report_days_limit: number
  readonly display_monthly_crawl_limit: number
  readonly can_track_content_event: boolean
}
export interface TeamData {
  readonly name: string
  readonly slug: string
  readonly plan: PlanData
  readonly created_at: string
  readonly updated_at: string
}

// GET /api/projects/
export interface ProjectSmallData {
  readonly id: number
  readonly name: string
  readonly url: string
}
export interface ProjectsData {
  readonly count: number
  readonly results: Array<ProjectSmallData>
}

// POST /api/projects/
// GET, PATCH /api/projects/{project_id}/
export interface ProjectData {
  readonly id: number
  readonly name: string
  readonly url: string
  readonly tag: string
}

// GET /api/invite/
export interface InviteData {
  readonly count: number
  readonly results: Array<{
    readonly id: number
    readonly team: number
    readonly user: number
    readonly email: string
    readonly role: number
    readonly invited_projects: Array<{
      readonly project: number
      readonly role: number
    }>
  }>
}

// GET /api/restricted_ips/
export interface RestrictedIpsData {
  readonly count: number
  readonly results: Array<{
    readonly id: number
    readonly created_at: string
    readonly updated_at: string
    readonly address: string
    readonly user: {
      readonly id: number
      readonly username: string
      readonly email: string
      readonly role: number
      readonly role_name: string
      readonly created_at: string
      readonly updated_at: string
    }
    readonly memo: string
  }>
}

// GET /api/auditlog/
export interface AuditLogData {
  readonly count: number
  readonly results: Array<{
    readonly created_at: string
    readonly event: string
    readonly id: number
    readonly message: string
    readonly project: {
      readonly id: number
      readonly name: string
      readonly url: string
      readonly tag: string
    }
    readonly remote_addr: string
    readonly user: {
      readonly id: number
      readonly username: string
      readonly email: string
      readonly role: number
      readonly role_name: string
    }
  }>
}

// GET /api/capture_limits/
export interface CaptureLimitsData {
  readonly count: number
  readonly results: Array<{
    readonly id: number
    readonly created_at: string
    readonly updated_at: string
    readonly project: string
    readonly ratio: number
    readonly monthly_limit: number
    readonly project_name: string
    readonly estimate_count: number
    readonly estimate_excess_rate: number
  }>
  readonly team_monthly_limit: number
}

// GET /api/gcp_sas/
export interface GcpSasData {
  readonly count: number
  readonly results: Array<{
    readonly id: number
    readonly user: {
      readonly id: number
      readonly username: string
      readonly created_at: string
      readonly updated_at: string
    }
    readonly gcp_sa_id: string
    readonly email: string
    readonly gcs_bucket_name: string
    readonly sa_key_remaining: string
    readonly created_at: string
    readonly updated_at: string
  }>
}

// GET /api/gcp_sas/{sa_id}/keys/
export interface GcpSasKeysData {
  readonly count: number
  readonly results: Array<{
    readonly id: number
    readonly user: {
      readonly id: number
      readonly username: string
      readonly created_at: string
      readonly updated_at: string
    }
    readonly gcp_sa: string
    readonly gcp_sa_key_id: string
    readonly description: string
    readonly created_at: string
    readonly updated_at: string
  }>
}

// GET /api/gcp_sas/{sa_id}/gcs_exports/
export interface GcpSasGcsExportsData {
  readonly count: number
  readonly results: Array<{
    readonly id: number
    readonly user: {
      readonly id: number
      readonly username: string
      readonly created_at: string
      readonly updated_at: string
    }
    readonly project: {
      readonly id: number
      readonly name: string
      readonly created_at: string
      readonly updated_at: string
    }
    readonly latest_exported_data_date: string
    readonly latest_contents_master_file_url: string
    readonly latest_tag_data_file_url: string
    readonly latest_contents_tags_file_url: string
    readonly report_days_limit: number
    readonly created_at: string
    readonly updated_at: string
  }>
}

// GET /api/projects/{id}/members/
export interface MembersData {
  readonly count: number
  readonly results: Array<{
    readonly id: number
    readonly project: number
    readonly role: number
    readonly user: {
      readonly id: number
      readonly username: string
      readonly email: string
      readonly role: number
      readonly role_name: string
      readonly created_at: string
      readonly updated_at: string
    }
    readonly role_name: string
    readonly created_at: string
    readonly updated_at: string
  }>
}

// GET /api/projects/{project_id}/members/invite/
export interface MembersInviteData {
  readonly count: number
  readonly results: Array<{
    readonly id: number
    readonly project: number
    readonly user: number
    readonly email: string
    readonly role: number
    readonly created_at: string
    readonly updated_at: string
  }>
}

// GET /api/projects/{project_id}/page_reports/available_page_layouts/
export interface PageReportsAvailablePageLayoutsData {
  readonly count: number
  readonly results: Array<{
    readonly page_layout: number
  }>
  readonly is_exists_urls: boolean
}

// GET /api/projects/{project_id}/page_reports/{page_report_id}/available_page_layouts/`,
export interface PageReportAvailablePageLayoutsData {
  readonly count: number
  readonly results: Array<{
    readonly page_layout: number
  }>
}

// GET /api/projects/{project_id}/pv/
export interface PvData {
  readonly page_views: Array<{
    readonly year_month: string
    readonly count: number
  }>
}

// GET /api/projects/${project_id}/custom_dimensions/
export interface CustomDimensionsData {
  readonly count: number
  readonly results: Array<{
    readonly id: number
    readonly index: number
    readonly name: string
    readonly scope: number
    readonly activated: boolean
    readonly query_sourced: boolean
    readonly created_at: string
    readonly updated_at: string
  }>
  readonly remained_registrations: number
}

// GET /api/projects/{project_id}/page_reports/custom_dimensions/
export interface CustomDimensionData {
  readonly id: number
  readonly index: number
  readonly name: string
  readonly scope: number
  readonly activated: boolean
  readonly query_sourced: boolean
}
export interface PageReportsCustomDimensionsData {
  readonly count: number
  readonly results: Array<CustomDimensionData>
}

// GET /api/projects/{project_id}/ipaddress_filters/
export interface IpaddressFiltersData {
  readonly count: number
  readonly results: Array<{
    readonly id: number
    readonly name: string
    readonly match_type: number
    readonly ipaddress: string
    readonly created_at: string
    readonly updated_at: string
  }>
}

// GET /api/projects/{project_id}/cross_domains/
export interface CrossDomainsData {
  readonly count: number
  readonly results: Array<{
    readonly id: number
    readonly name: string
    readonly allow_subdomain: boolean
    readonly created_at: string
    readonly updated_at: string
  }>
}

// GET /api/projects/{project_id}/site_responsive/
export interface SiteResponsiveData {
  readonly mobile_max_width: number | null
  readonly pc_min_width: number | null
  readonly created_at: string
  readonly updated_at: string
}

// GET /api/projects/{project_id}/url_limit/
export interface UrlLimitData {
  readonly is_unlimited: boolean
  readonly url_limit: number
  readonly page_captures: number
}

// GET /api/projects/{project_id}/page_capture_url_check/
export interface PageCaptureUrlCheckData {
  readonly id: number
  readonly created_at: string
  readonly updated_at: string
  readonly url_value: string
  readonly url_match_type: number
  readonly event_agg_type: number
  readonly interval_type: number
  readonly page_count: number
  readonly estimate_count: number
}

// GET /api/projects/{id}/url_parameters/
export interface UrlParametersData {
  readonly count: number
  readonly results: Array<{
    readonly id: number
    readonly name: string
    readonly created_at: string
    readonly updated_at: string
  }>
}

// GET /api/projects/{project_id}/page_captures/
// GET /api/projects/{project_id}/page_captures/?url_value={search_text}`
export interface PageCaptureItem {
  readonly id: number
  readonly created_at: string
  readonly updated_at: string
  readonly url_value: string
  readonly url_match_type: number
  readonly event_agg_type: number
  readonly interval_type: number
  readonly page_count: number
  readonly estimate_count: number
}

export interface PageCapturesData {
  readonly count: number
  readonly results: Array<PageCaptureItem>
  readonly total_page_count: number
  readonly total_estimate_count: number
  readonly monthly_limit: number
  readonly is_limited: boolean
}

// GET /api/projects/{project_id}/page_capture_estimate_count/?{param}
export interface PageCaptureEstimateCountData {
  readonly page_count: number
  readonly estimate_count: number
}

// GET /api/projects/{project_id}/content_segmentation/histories/?{param}
interface PageHistory {
  readonly screenshot_url: string
  readonly created_at: string
}

export const CS_ERROR_TYPES = {
  SYSTEM_ERROR: 1,
  CONNECTION_TIMEOUT: 2,
  CONNECTION_REFUSED: 3,
  ALERT_DISPLAYED: 4,
  PAGE_NOT_FOUND: 5,
  PAGE_RESPONSE_ERROR: 6,
} as const
export type CsErrorType = typeof CS_ERROR_TYPES[keyof typeof CS_ERROR_TYPES]

interface ContentSegmentationHistory {
  readonly id: number
  readonly status_name: string
  readonly error_type: CsErrorType | null
  readonly response_error_http_status_code: number | null
  readonly page_id: string
  readonly page_history: PageHistory
}

export interface ContentSegmentationHistoriesData {
  readonly count: number
  readonly results: Array<{
    readonly id: number
    readonly url_id: string
    readonly url_value: string
    readonly title: string
    readonly requested_at: string
    readonly pc_cs_history: ContentSegmentationHistory
    readonly mobile_cs_history: ContentSegmentationHistory
  }>
  readonly page_capture_count: number
  readonly page_capture_limit: number
  readonly page_capture_remaining: number
}

// GET /api/projects/{project_id}/pages/{page_id}/content_segmentation/histories/?{param}
export interface PageContentSegmentationHistoriesData {
  readonly count: number
  readonly results: Array<{
    readonly id: number
    readonly requested_at: string
    readonly status_name: string
    readonly error_type: CsErrorType | null
    readonly response_error_http_status_code: number | null
    readonly username: string
    readonly page_history: {
      readonly screenshot_url: string
      readonly screenshot_width: number
      readonly created_at: string
      readonly content_locations: [
        {
          readonly id: number
          readonly partial_content_id: string
          readonly top_left_y: number
          readonly top_left_x: number
          readonly height: number
          readonly width: number
        },
      ]
    }
  }>
  readonly is_locked_cs_manually: boolean
}

// GET /api/projects/{project_id}/partial_contents/{id}/
export interface PartialContentsData {
  readonly id: number
  readonly display_content_id: string
  readonly mobile_screenshot: ContentScreenshotResponse | null
  readonly pc_screenshot: ContentScreenshotResponse | null
  readonly managed_content: ManagedContentResponse | null
  readonly created_at: string
  readonly updated_at: string
  readonly content_tags: ContentTagResponse[]
}

// GET /api/projects/{project_id}/page_reports/{id}/info/
export interface PageCaptureInfoData {
  readonly id: number
  readonly url: string
  readonly title: string
  readonly page_capture: {
    readonly id: number
    readonly created_at: string
    readonly updated_at: string
    readonly url_value: string
    readonly url_match_type: number
    readonly event_agg_type: number
    readonly event_agg_type_name: string
    readonly interval_type: number
    readonly interval_type_name: string
  }
  readonly created_at: string
  readonly updated_at: string
}

type StatusName = '成功' | '取得待ち' | 'エラー'

// GET /api/projects/{project_id}/urls/{url_id}/target_contents/
export interface TargetContentsData {
  count: number
  next: string
  previous: string
  results: {
    id: number
    content_name: string
    css_selector: string
    target_content_results: {
      id: number
      page_layout: number
      page_layout_name: string
      status_name: StatusName
      error_type_name: string
      content_screenshot: {
        id: number
        width: number
        height: number
        image_url: string
        css_selector: string
        target_type_name: string
        created_at: string
        updated_at: string
      }
      created_at: string
      updated_at: string
    }[]
    created_at: string
    updated_at: string
  }[]
}

// POST /api/projects/{project_id}/urls/{url_id}/target_contents/
export interface CreateTargetContentsData {
  id: number
  content_name: string
  css_selector: string
  target_content_results: [
    {
      id: number
      page_layout: 1
      page_layout_name: string
      status_name: StatusName
      error_type_name: string
      content_screenshot: {
        id: number
        width: number
        height: number
        image_url: string
        css_selector: string
        target_type_name: string
        created_at: string
        updated_at: string
      }
      created_at: string
      updated_at: string
    },
  ]
  created_at: string
  updated_at: string
}

// GET /api/projects/{project_id}/urls/{url_id}/target_contents/{id}
export interface ParcialTargetContentsData {
  id: number
  content_name: string
  css_selector: string
  target_content_results: [
    {
      id: number
      page_layout: number
      page_layout_name: string
      status_name: StatusName
      error_type_name: string
      content_screenshot: {
        id: number
        width: number
        height: number
        image_url: string
        css_selector: string
        target_type_name: string
        created_at: string
        updated_at: string
      }
      created_at: string
      updated_at: string
    },
  ]
  created_at: string
  updated_at: string
}

// PUT /api/projects/{project_id}/urls/{url_id}/target_contents/{id}
export interface EditTargetContentsData {
  id: number
  content_name: string
  css_selector: string
  target_content_results: [
    {
      id: number
      page_layout: number
      page_layout_name: string
      status_name: StatusName
      error_type_name: string
      content_screenshot: {
        id: number
        width: number
        height: number
        image_url: string
        css_selector: string
        target_type_name: string
        created_at: string
        updated_at: string
      }
      created_at: string
      updated_at: string
    },
  ]
  created_at: string
  updated_at: string
}

// DELETE /api/projects/{project_id}/urls/{url_id}/target_contents/{id}
export interface DeleteTargetContentsData {}
