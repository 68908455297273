import React, { ReactElement } from 'react'

import { DataTd, SubText } from '../../../DataTable'
import { RevenueType } from '../../../../types/Report/RevenueType'

interface Props {
  readonly revenueType: RevenueType | null
  readonly amount: number
  readonly count: number
}

export const RevenueAmountSummary = ({ revenueType, amount, count }: Props): ReactElement | null => {
  if (!revenueType) return null

  return (
    <DataTd>
      <div>{amount.toLocaleString()}</div>
      <SubText>({count.toLocaleString()})</SubText>
    </DataTd>
  )
}
