/**
 * ページレポートのソート条件を定義する定数オブジェクト。
 * APIリクエストでソート条件となるキー群を提供する。
 */
export const SORT_KEYS = {
  SESSION_COUNT: 'page_sessions',
  USER_COUNT: 'page_users',
  LANDING_COUNT: 'page_landing_count',
  BOUNCE_RATE: 'page_bounce_rate',
  EXIT_RATE: 'page_exit_rate',
  GOAL_COUNT: 'page_conversions',
  GOAL_RATE: 'page_conversions_rate',
  REVENUE_AMOUNT: 'page_revenue_amount',
} as const

export type SortKey = typeof SORT_KEYS[keyof typeof SORT_KEYS]
