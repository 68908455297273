import { useQuery, useQueryClient } from '@tanstack/react-query'
import { NEWS_LIST_QUERY_KEY } from './constants'
import { request } from '../../request'

export const NEWS_TYPES = {
  NEWS: 1,
  MAINTENANCE: 2,
} as const
export type NewsType = typeof NEWS_TYPES[keyof typeof NEWS_TYPES]

export const NEWS_TYPE_LABELS = {
  [NEWS_TYPES.NEWS]: 'お知らせ',
  [NEWS_TYPES.MAINTENANCE]: 'メンテナンス',
}
export type NewsTypeLabel = typeof NEWS_TYPE_LABELS[keyof typeof NEWS_TYPE_LABELS]

export interface NewsResponse {
  readonly id: number
  readonly title: string
  readonly news_type: NewsType
  readonly start_time: string
  readonly is_public: boolean
  readonly end_time: string
  readonly external_link: string
}

export interface NewsListResponse {
  readonly results: Array<NewsResponse>
  readonly count: number
}

export interface NewsData {
  readonly id: number
  readonly title: string
  readonly newsType: NewsType
  readonly newsTypeLabel: NewsTypeLabel
  readonly startTime: string
  readonly isPublic: boolean
  readonly endTime: string
  readonly externalLink: string
}

export interface NewsListData {
  readonly results: Array<NewsData>
  readonly count: number
}

export const useNewsList = () => {
  const queryClient = useQueryClient()

  const queryResult = useQuery({
    queryKey: [NEWS_LIST_QUERY_KEY],
    queryFn: () => fetch(),
    select: (response: NewsListResponse): NewsListData => {
      return {
        results: response.results.map((item) => ({
          id: item.id,
          title: item.title,
          newsType: item.news_type,
          newsTypeLabel: NEWS_TYPE_LABELS[item.news_type],
          startTime: item.start_time,
          endTime: item.end_time,
          isPublic: item.is_public,
          externalLink: item.external_link,
        })),
        count: response.count,
      }
    },
  })

  const invalidate = async () => await queryClient.invalidateQueries({ queryKey: [NEWS_LIST_QUERY_KEY] })

  return {
    ...queryResult,
    invalidate,
  }
}
const fetch = async () => {
  return await request<NewsListResponse>('GET', `/api/news`, true)
}
