import * as React from 'react'
import styled from 'styled-components'
import { InteractiveTooltip, TOOLTIP_DELAY } from '../../../components/Tooltip'
import { TrafficReportItemData } from '../../../util/hooks/api/TrafficReport/types'
import { DIMENSION_IDS, DIMENSION_LABELS } from '../../../util/hooks/api/TrafficReport/constants'
import { useTrafficReportDimensionChoices } from '../../../util/hooks/cookie/useTrafficReportDimensionChoices'
import {
  DIMENSION_STRING_WITH_EMPTY_CONDITIONS,
  dimensionStringWithEmptyConditionOptions,
} from '../../../util/hooks/api/Filter/constants'

export const DIMENSION_VALUE_MAP = {
  [DIMENSION_IDS.CHANNEL]: (item: TrafficReportItemData) => item.channelName,
  [DIMENSION_IDS.SOURCE]: (item: TrafficReportItemData) => item.firstTrafficSource,
  [DIMENSION_IDS.MEDIUM]: (item: TrafficReportItemData) => item.firstTrafficMedium,
  [DIMENSION_IDS.CAMPAIGN]: (item: TrafficReportItemData) => item.firstTrafficCampaign,
} as const

const EMPTY_LABEL =
  dimensionStringWithEmptyConditionOptions.find(
    (option) => option.value === DIMENSION_STRING_WITH_EMPTY_CONDITIONS.EMPTY,
  )?.label || ''

interface Props {
  children: React.ReactElement
  item: TrafficReportItemData
}

export const DimensionTooltip: React.FC<Props> = ({ children, item }) => {
  const { dimensionChoices } = useTrafficReportDimensionChoices()

  const dimensionTexts = dimensionChoices.map((dimensionId) => {
    const value = DIMENSION_VALUE_MAP[dimensionId](item)
    return `${DIMENSION_LABELS[dimensionId]}: ${value === '' ? EMPTY_LABEL : value}`
  })

  if (dimensionTexts.length === 0) return null

  const tooltipContent = (
    <HelpText>
      <div>この条件に絞り込むフィルタを適用しページレポートに移動する</div>
      <List>
        {dimensionTexts.map((text) => (
          <Item key={text}>{text}</Item>
        ))}
      </List>
    </HelpText>
  )

  return (
    // NOTE: tippyのaccessibility警告を回避するためにdivでラップが必要だった
    <div>
      <InteractiveTooltip content={tooltipContent} delay={TOOLTIP_DELAY} offset={[-130, 32]}>
        {children}
      </InteractiveTooltip>
    </div>
  )
}

const HelpText = styled.div`
  text-align: left;
`

const List = styled.ul`
  margin: 0;
  padding: 0 0 0 14px;
`

const Item = styled.li``
