import * as React from 'react'
import {
  DataTable,
  DataTh,
  DataTd,
  SortControl,
  SubText,
  DataDataBar,
  DataTr,
  DataSummaryTr,
  CELL_BACKGROUND_COLORS,
  INDEX_COLUMN_WIDTH,
} from '../../../components/DataTable'
import { DataContentLink } from '../../../components/DataTable/DataContentLink'
import { SCOPE_TYPE, useScopeType } from '../../../util/hooks/useScopeType'
import { CustomDimensionValueReport } from '../../../util/hooks/api/CustomDimensionReport/useCustomDimensionValueReport'
import { DATA_BAR_COLORS } from '../../../styleConstants'
import { HELP_TEXTS } from '../../../constants'
import { SORT_KEYS } from '../../../util/hooks/api/CustomDimensionReport/constants'
import { CustomDimensionReportContext } from '../../../contexts/CustomDimentionReportContext'
import { ProjectContext } from '../../ProjectRoot'
import { CustomFilterContext } from '../../../contexts/CustomFilterContext'
import { CustomFilterState } from '../../../util/hooks/api/Filter/types'
import {
  AGGREGATE_CONDITIONS,
  CONDITION_TYPES,
  DIMENSION_CATEGORIES,
  DIMENSION_MATCH_CONDITIONS,
  DIMENSION_KEYS,
} from '../../../util/hooks/api/Filter/constants'
import { AGGREGATE_SCOPES } from '../../../util/hooks/api/Filter/constants'

interface Props {
  reportData?: CustomDimensionValueReport
}

export const ReportTable = ({ reportData }: Props) => {
  if (!reportData) return null

  const { allSessionCount, allUserCount, allGoalCount, allGoalRate, customDimensionId } = reportData

  const {
    state: { baseUrl },
  } = React.useContext(ProjectContext)

  const { actions } = React.useContext(CustomDimensionReportContext)
  const { actions: customFilterActions } = React.useContext(CustomFilterContext)

  const { scopeType, scopeTypeLabel } = useScopeType()

  const isScopeTypeUser = scopeType === SCOPE_TYPE.USER
  const viewCountHelpText = isScopeTypeUser ? HELP_TEXTS.USER_COUNT : HELP_TEXTS.SESSION_COUNT
  const viewCountSortKey = isScopeTypeUser ? SORT_KEYS.USER_COUNT : SORT_KEYS.SESSION_COUNT

  const makePageReportUrlWithFilter = (customDimensionValue: string) => {
    const addonFilterState = makeAddonFilterState(customDimensionValue, customDimensionId)
    return customFilterActions.makePageReportUrlWithHashFilter(baseUrl, addonFilterState, window.location.search)
  }

  /**
   * 追加するフィルターをカスタムディメンションの各行のデータから作成する
   * @param {string} value - カスタムディメンションの値
   * @param {number} customDimensionId - カスタムディメンションID
   * @return {CustomFilterState[]} - カスタムフィルター状態
   */
  const makeAddonFilterState = (value: string, customDimensionId: number): CustomFilterState[] => {
    return [
      {
        aggregate: {
          unit: AGGREGATE_SCOPES.SESSION,
          condition: AGGREGATE_CONDITIONS.INCLUDE,
        },
        dimensions: [
          {
            states: [
              {
                id: DIMENSION_KEYS.CUSTOM_DIMENSION,
                condition: DIMENSION_MATCH_CONDITIONS.FULL,
                value,
                customDimensionId,
                name: customFilterActions.getCustomDimensionData(customDimensionId)?.name || '',
                dimensionCategory: DIMENSION_CATEGORIES.CUSTOM_DIMENSION,
                conditionType: CONDITION_TYPES.STRING,
                target: undefined,
                fromValue: undefined,
                toValue: undefined,
              },
            ],
          },
        ],
      },
    ]
  }

  return (
    <DataTable>
      <thead>
        <DataTr>
          <DataTh noBorderRight minWidth={INDEX_COLUMN_WIDTH}></DataTh>
          <DataTh fillWidth textAlign="left">
            カスタムディメンション
          </DataTh>
          <DataTh backgroundColor={CELL_BACKGROUND_COLORS.VIEW} minWidth="126px">
            <SortControl
              subTitle={'(対全体比率)'}
              helpText={viewCountHelpText}
              helpWidth={200}
              sortKey={viewCountSortKey}
              sortIcon={actions.getSortIcon(viewCountSortKey)}
              onSortClick={actions.setSortValues}
            >
              {scopeTypeLabel}数
            </SortControl>
          </DataTh>
          <DataTh backgroundColor={CELL_BACKGROUND_COLORS.VIEW} minWidth="119px">
            <SortControl
              subTitle={'(対全体比率)'}
              helpText={isScopeTypeUser ? HELP_TEXTS.USER_GOAL_COUNT : HELP_TEXTS.SESSION_GOAL_COUNT}
              helpWidth={200}
              sortKey={SORT_KEYS.GOAL_COUNT}
              sortIcon={actions.getSortIcon(SORT_KEYS.GOAL_COUNT)}
              onSortClick={actions.setSortValues}
            >
              ゴール数
            </SortControl>
          </DataTh>
          <DataTh backgroundColor={CELL_BACKGROUND_COLORS.VIEW} minWidth="119px">
            <SortControl
              helpText={isScopeTypeUser ? HELP_TEXTS.USER_GOAL_RATE : HELP_TEXTS.SESSION_GOAL_RATE}
              helpLeft={-150}
              sortKey={SORT_KEYS.GOAL_RATE}
              sortIcon={actions.getSortIcon(SORT_KEYS.GOAL_RATE)}
              onSortClick={actions.setSortValues}
            >
              ゴール率
            </SortControl>
          </DataTh>
        </DataTr>
      </thead>

      <tbody>
        <DataSummaryTr>
          <DataTd noBorderRight></DataTd>
          <DataTd textAlign="left">全体</DataTd>
          <DataTd>
            <div>{(isScopeTypeUser ? allUserCount : allSessionCount).toLocaleString()}</div>
            <SubText>(100.0%)</SubText>
          </DataTd>
          <DataTd>
            <div>{allGoalCount.toLocaleString()}</div>
            <SubText>(100.0%)</SubText>
          </DataTd>
          <DataTd>{allGoalRate.toFixed(2)}%</DataTd>
        </DataSummaryTr>

        {reportData.results.map((item) => (
          <DataTr key={item.index}>
            <DataTd noBorderRight>{item.index}</DataTd>
            <DataTd textAlign="left" ellipsis tooltipContent={item.customDimensionValue}>
              <DataContentLink to={makePageReportUrlWithFilter(item.customDimensionValue)}>
                {item.customDimensionValue}
              </DataContentLink>
            </DataTd>
            <DataTd noPadding>
              <DataDataBar
                barPercent={isScopeTypeUser ? item.userScaledPercent : item.sessionScaledPercent}
                barColor={DATA_BAR_COLORS.COUNT}
              >
                <div>{(isScopeTypeUser ? item.userCount : item.sessionCount).toLocaleString()}</div>
                <SubText>
                  ({isScopeTypeUser ? item.userOverallRatio.toFixed(1) : item.sessionOverallRatio.toFixed(1)}%)
                </SubText>
              </DataDataBar>
            </DataTd>
            <DataTd noPadding>
              <DataDataBar barPercent={item.goalCountScaledPercent} barColor={DATA_BAR_COLORS.COUNT}>
                <div>{item.goalCount.toLocaleString()}</div>
                <SubText>({item.goalCountOverallRatio.toFixed(1)}%)</SubText>
              </DataDataBar>
            </DataTd>
            <DataTd>{item.goalRate.toFixed(2)}%</DataTd>
          </DataTr>
        ))}
      </tbody>
    </DataTable>
  )
}
