import { differenceInDays, isBefore, isEqual } from 'date-fns'

// 年月日を取得(YYYY/MM/DD)
export function getDateStringYMD(date: Date, division: string = '/'): string {
  // TODO
  const yyyy = date.getFullYear()
  const mm = ('0' + (date.getMonth() + 1)).slice(-2)
  const dd = ('0' + date.getDate()).slice(-2)
  return yyyy + division + mm + division + dd
}

// 年月日を取得(YYYY年MM月DD日)
export function getDateStringYMDJa(date: Date): string {
  const yyyy = date.getFullYear()
  const mm = ('0' + (date.getMonth() + 1)).slice(-2)
  const dd = ('0' + date.getDate()).slice(-2)
  return yyyy + '年' + mm + '月' + dd + '日'
}

// 年月を取得(YYYY/MM)
export function getDateStringYM(date: Date, division: string = '/'): string {
  const yyyy = date.getFullYear()
  const mm = ('0' + (date.getMonth() + 1)).slice(-2)
  return yyyy + division + mm
}

// 2021-10-15T15:52:26.131894+09:00 の形式から Date の形式に変換
export function parseDate(time: string): Date {
  return new Date(Date.parse(time))
}

// 2021-10-15T15:52:26.131894+09:00 の形式から 2021/10/15 15:52:26 の形式に変換
export function getDateYYYYMMDDhhmiss(time: string): string {
  const d = parseDate(time)
  const format = 'YYYY/MM/DD hh:mi:ss'
  return format
    .replace(/YYYY/g, String(d.getFullYear()))
    .replace(/MM/g, ('0' + (d.getMonth() + 1)).slice(-2))
    .replace(/DD/g, ('0' + d.getDate()).slice(-2))
    .replace(/hh/g, ('0' + d.getHours()).slice(-2))
    .replace(/mi/g, ('0' + d.getMinutes()).slice(-2))
    .replace(/ss/g, ('0' + d.getSeconds()).slice(-2))
}

// 2021-10-15T15:52:26.131894+09:00 の形式から 2021/10/15 15:52 の形式に変換
export function getDateYYYYMMDDhhmi(time: string): string {
  const d = parseDate(time)
  const format = 'YYYY/MM/DD hh:mi'
  return format
    .replace(/YYYY/g, String(d.getFullYear()))
    .replace(/MM/g, ('0' + (d.getMonth() + 1)).slice(-2))
    .replace(/DD/g, ('0' + d.getDate()).slice(-2))
    .replace(/hh/g, ('0' + d.getHours()).slice(-2))
    .replace(/mi/g, ('0' + d.getMinutes()).slice(-2))
}

// 2021-10-15T15:52:26.131894+09:00 の形式から 21/10/15 の形式に変換
export function getDateYYMMDD(time: string): string {
  const d = parseDate(time)
  const format = 'YYYY/MM/DD'
  return format
    .replace(/YYYY/g, String(d.getFullYear()).slice(2))
    .replace(/MM/g, ('0' + (d.getMonth() + 1)).slice(-2))
    .replace(/DD/g, ('0' + d.getDate()).slice(-2))
}

// 2021-10-15T15:52:26.131894+09:00 の形式から 2021/10/15 の形式に変換
export function getDateYYYYMM(time: string): string {
  const d = parseDate(time)
  const format = 'YYYY/MM'
  return format.replace(/YYYY/g, String(d.getFullYear())).replace(/MM/g, ('0' + (d.getMonth() + 1)).slice(-2))
}

// YYYYMMDD文字列をDateに変換する
export function yyyymmddToDate(time: string) {
  let y = Number(time.slice(0, 4)) // YYYY
  let m = Number(time.slice(4, 6)) // MM
  let d = Number(time.slice(6, 8)) // DD
  return new Date(+y, +m - 1, d)
}

export function isSameOrBefore(date: Date, dateToCompare: Date): boolean {
  return isEqual(date, dateToCompare) || isBefore(date, dateToCompare)
}

export function isSameOrBeforeByDay(date: Date, dateToCompare: Date) {
  return differenceInDays(date, dateToCompare) <= 0
}

export function isSameOrAfterByDay(date: Date, dateToCompare: Date) {
  return differenceInDays(dateToCompare, date) <= 0
}
