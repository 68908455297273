import * as React from 'react'
import { CookiesProvider } from 'react-cookie'
import { request } from './util/request'
import { UserData } from './util/Response'
import { UrlParams } from './util/UrlParams'
import { OptionProps } from './components/common/Select'

interface GlobalContextType {
  readonly state: State
  readonly actions: Actions
}

export interface AccountInfo {
  readonly userName: string
  readonly userId: number
  readonly projectName: string
  readonly projectId: number
  readonly teamName: string
  readonly teamCode: string
  readonly roleName: string
  readonly permissions: any
  readonly goals: number[]
}

export interface PlanInfo {
  readonly name: string
  readonly reportDaysLimit: number
  readonly displayMonthlyCrawlLimit: number
  readonly canTrackContentEvent: boolean
}

interface State {
  readonly newsState: {
    readonly isVisible: boolean
  }
  readonly pageReportState: {
    readonly dispCount: number // ページレポートの表示件数
    readonly dispCountOptions: OptionProps[]
  }
  readonly captureControlState: {
    // キャプチャ管理
    readonly searchText: string // 絞り込み文字列
  }
  readonly captureHistoryState: {
    // キャプチャ履歴
    readonly dispCount: number // 表示件数
    readonly dispCountOptions: OptionProps[]
    readonly searchText: string // 絞り込み文字列
    readonly status: boolean[] // チェックボックス用ステータス
    readonly text: string // TODO:一時対応用なので削除予定
    readonly isNarrowDown: boolean // 絞り込みが適用されているか
  }
  readonly AccountInfo: AccountInfo
  readonly PlanInfo: PlanInfo
}

// 表示件数の初期値
const DefaultDispCount = 20

class Actions {
  constructor(private readonly state: State, private readonly setState: React.Dispatch<React.SetStateAction<State>>) {}

  // ユーザー情報(アカウント、プラン)を保存
  setUserInfo = async (
    userName: string,
    userId: number,
    teamName: string,
    teamCode: string,
    roleName: string,
    permissions: any,
    planName: string,
    reportDaysLimit: number,
    displayMonthlyCrawlLimit: number,
    canTrackContentEvent: boolean,
  ) => {
    this.setState({
      ...this.state,
      AccountInfo: {
        ...this.state.AccountInfo,
        userName: userName,
        userId: userId,
        teamName: teamName,
        teamCode: teamCode,
        roleName: roleName,
        permissions: permissions,
      },
      PlanInfo: {
        name: planName,
        reportDaysLimit: reportDaysLimit,
        displayMonthlyCrawlLimit: displayMonthlyCrawlLimit,
        canTrackContentEvent: canTrackContentEvent,
      },
    })
  }

  // プロジェクト情報保存
  setProjectInfo = (projectName: string, projectId: number, goals: number[]) => {
    this.setState({
      ...this.state,
      AccountInfo: {
        ...this.state.AccountInfo,
        projectName: projectName,
        projectId: projectId,
        goals: goals,
      },
    })
  }

  // 状態リセット
  clearState = () => {
    this.setState({
      ...this.state,
      newsState: {
        isVisible: false,
      },
      pageReportState: {
        ...this.state.pageReportState,
      },
      AccountInfo: {
        userName: '',
        userId: 0,
        projectName: '',
        projectId: 0,
        teamName: '',
        teamCode: '',
        roleName: '',
        permissions: '',
        goals: [],
      },
      PlanInfo: {
        name: '',
        reportDaysLimit: 0,
        displayMonthlyCrawlLimit: 0,
        canTrackContentEvent: false,
      },
    })
  }

  // 権限更新
  updatePermissions = async () => {
    try {
      const user: UserData = await request('GET', '/api/user/', true)
      this.setState({
        ...this.state,
        AccountInfo: {
          ...this.state.AccountInfo,
          permissions: user.permissions,
        },
      })
    } catch {}
  }

  setName = (userName: string, projectName: string) => {
    this.setState({
      ...this.state,
      AccountInfo: {
        ...this.state.AccountInfo,
        userName: userName ? userName : this.state.AccountInfo.userName,
        projectName: projectName ? projectName : this.state.AccountInfo.projectName,
      },
    })
  }

  // レポートページのURLパラメータから情報セット
  setReportUrlParam = (param: URLSearchParams) => {
    const { disp } = UrlParams.getReportPageUrlParams(param)
    const dispCount = disp ? Number(disp) : this.state.pageReportState.dispCount

    // 表示件数のエラーチェック
    let setDisp = dispCount
    // 一致する要素なし
    if (this.state.pageReportState.dispCountOptions.filter((option) => option.value === setDisp).length === 0) {
      setDisp = DefaultDispCount
    }

    this.setState({
      ...this.state,
      pageReportState: {
        ...this.state.pageReportState,
        dispCount: setDisp,
      },
    })
  }

  // ページレポートの表示件数変更
  onPageReportDispChange = (value: number) => {
    this.setState({
      ...this.state,
      pageReportState: { ...this.state.pageReportState, dispCount: value },
    })
  }

  // キャプチャ管理の検索テキストを保存
  setCaptureControlSearchText = (text: string) => {
    this.setState({
      ...this.state,
      captureControlState: {
        ...this.state.captureControlState,
        searchText: text,
      },
    })
  }

  // キャプチャ履歴の検索テキストを保存
  setCaptureHistorySearchText = (text: string) => {
    this.setState({
      ...this.state,
      captureHistoryState: {
        ...this.state.captureHistoryState,
        searchText: text,
      },
    })
  }

  // キャプチャ履歴の検索条件保存
  setCaptureHistoryNarrowDown = (status: boolean[], text: string) => {
    // 有効な条件がある
    const validStatus = status.filter((state) => state).length != 0 || text !== ''
    this.setState({
      ...this.state,
      captureHistoryState: {
        ...this.state.captureHistoryState,
        status: status,
        text: text,
        isNarrowDown: validStatus,
      },
    })
  }

  // キャプチャ履歴の検索条件リセット
  resetCaptureHistoryNarrowDown = () => {
    this.setState({
      ...this.state,
      captureHistoryState: {
        ...this.state.captureHistoryState,
        status: [false, false, false, false],
        text: '',
        isNarrowDown: false,
      },
    })
  }

  // キャプチャ履歴の表示件数変更
  onCaptureHistoryDispChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      ...this.state,
      captureHistoryState: { ...this.state.captureHistoryState, dispCount: Number(e.target.value) },
    })
  }

  // お知らせの表示フラグを保存
  setNewsVisible = (flag: boolean) => {
    this.setState({
      ...this.state,
      newsState: {
        ...this.state.newsState,
        isVisible: flag,
      },
    })
  }
}

const initialState: State = {
  newsState: {
    isVisible: false,
  },
  pageReportState: {
    dispCount: DefaultDispCount, // ページレポートの表示件数
    dispCountOptions: [20, 50, 100, 500, 1000].map((num) => ({
      label: num.toLocaleString(),
      value: num,
    })),
  },
  captureControlState: {
    searchText: '',
  },
  captureHistoryState: {
    dispCount: 10,
    dispCountOptions: [
      { label: '10', value: 10 },
      { label: '20', value: 20 },
      { label: '30', value: 30 },
      { label: '50', value: 50 },
      { label: '100', value: 100 },
    ],
    searchText: '',
    status: [false, false, false, false], // チェックボックス用ステータス
    text: '', // TODO:一時対応用なので削除すること
    isNarrowDown: false,
  },
  AccountInfo: {
    userName: '',
    userId: 0,
    projectName: '',
    projectId: 0,
    teamName: '',
    teamCode: '',
    roleName: '',
    permissions: '',
    goals: [],
  },
  PlanInfo: {
    name: '',
    reportDaysLimit: 0,
    displayMonthlyCrawlLimit: 0,
    canTrackContentEvent: false,
  },
}

function useGlobalState(): GlobalContextType {
  const [state, setState] = React.useState<State>(initialState)
  const actions = new Actions(state, setState)
  return { state, actions }
}

// NOTE: The default value will be never used, so we set it `null as any`.
/* eslint-disable @typescript-eslint/no-explicit-any */
export const GlobalContext = React.createContext<GlobalContextType>(null as any)
/* eslint-enable @typescript-eslint/no-explicit-any */

interface Props {
  readonly children?: React.ReactNode
}

export function GlobalContextWrapper({ children }: Props) {
  const context = useGlobalState()
  return (
    <CookiesProvider>
      <GlobalContext.Provider value={context}>{children}</GlobalContext.Provider>
    </CookiesProvider>
  )
}
