import * as React from 'react'
import styled from 'styled-components'
import { Link, navigate } from '@gatsbyjs/reach-router'
import { DATA_BAR_COLORS, colors } from '../../styleConstants'
import { OpenInNew } from '@styled-icons/material/OpenInNew'
import { SCOPE_TYPE, useScopeType } from '../../util/hooks/useScopeType'
import { InteractiveTooltip, TOOLTIP_DELAY } from '../Tooltip'
import { InformationTooltip } from '../Tooltip/IconTooltip'
import { CELL_BACKGROUND_COLORS, DataDataBar, DataTd, DataTr, SubText } from '../DataTable'
import { RevenueType } from '../../types/Report/RevenueType'
import { RevenueAmountData } from '../report/table/data/RevenueAmountData'

export const gridLayout = {
  minWidth: '820px',
  maxWidth: '1400px',
  headerHeight: '51px',
  height: '38px',
  row: {
    index: '60px',
    url: '300px',
    icon: '30px',
    viewCount: '126px',
    pageLandingCount: '138px',
    pageBounceRate: '90px',
    pageExitRate: '90px',
    pageConversions: '119px',
    pageConversionsRate: '102px',
    pageRevenueAmount: '169px',
  },
  fontSize: '12px',
}

export const gridLayoutRowGroups = {
  url: parseInt(gridLayout.row.index) + parseInt(gridLayout.row.url) + parseInt(gridLayout.row.icon) + 'px',
  withoutUrl:
    parseInt(gridLayout.row.viewCount) +
    parseInt(gridLayout.row.pageLandingCount) +
    parseInt(gridLayout.row.pageBounceRate) +
    parseInt(gridLayout.row.pageExitRate) +
    parseInt(gridLayout.row.pageConversions) +
    parseInt(gridLayout.row.pageConversionsRate) +
    'px',
}

export interface PageReportItem {
  readonly id: number | null
  readonly url: string
  readonly title: string
  readonly pageSessions: number
  readonly pageSessionsScaledPercent: number
  readonly pageSessionsOverallRatio: number
  readonly pageUsers: number
  readonly pageUsersScaledPercent: number
  readonly pageUsersOverallRatio: number
  readonly pageLandingCount: number
  readonly pageLandingCountScaledPercent: number
  readonly pageLandingCountOverallRatio: number
  readonly pageBounceRate: number
  readonly pageBounceRateScaledPercent: number
  readonly pageExitRate: number
  readonly pageExitRateScaledPercent: number
  readonly pageConversions: number
  readonly pageConversionsScaledPercent: number
  readonly pageConversionsOverallRatio: number
  readonly pageConversionsRate: number
  readonly pageConversionsRateScaledPercent: number
  readonly pageRevenueCount: number
  readonly pageRevenueAmount: number
  readonly pageRevenueAmountScaledPercent: number
  readonly contentEventsExists: boolean
}

interface Props {
  readonly projectId: string
  readonly item: PageReportItem
  readonly dataIndex: number
  readonly baseUrl?: string
  readonly revenueType: RevenueType | null
}

export function PageGridItem({ item, dataIndex, baseUrl, revenueType }: Props) {
  const { scopeType } = useScopeType()

  const [viewCount, viewScaledPercent, viewOverallRatio] =
    scopeType === SCOPE_TYPE.USER
      ? [item.pageUsers, item.pageUsersScaledPercent, item.pageUsersOverallRatio]
      : [item.pageSessions, item.pageSessionsScaledPercent, item.pageSessionsOverallRatio]

  const onClick = (url: string) => {
    navigate(url)
  }
  const url = `${baseUrl}report/content/${item.id}${window.location.search}`

  const linkDisabled = !item.contentEventsExists

  return (
    <DataTr key={dataIndex} {...(linkDisabled && { backgroundColor: CELL_BACKGROUND_COLORS.DISABLED })}>
      <DataTd noBorderRight>{dataIndex}</DataTd>
      <DataTd textAlign="left" ellipsis>
        <RowUrl>
          <Box
            linkDisabled={linkDisabled}
            {...(!linkDisabled && {
              onClick: () => onClick(url),
            })}
          >
            <InteractiveTooltip
              content={
                <>
                  {item.url}
                  <br />
                  {item.title}
                </>
              }
              delay={TOOLTIP_DELAY}
            >
              <div>
                <Url>{linkDisabled ? <>{item.url}</> : <DesignedLink to={url}>{item.url}</DesignedLink>}</Url>
                <Title>{item.title}</Title>
              </div>
            </InteractiveTooltip>
          </Box>
          <IconArea>
            {linkDisabled && (
              <IconBox>
                <InformationTooltip width={300} iconSize="20px" iconColor={colors.lightBlue}>
                  検索対象期間に当ページのデータが計測されていないため
                  <br />
                  コンテンツレポートへ遷移できません。
                </InformationTooltip>
              </IconBox>
            )}
            <IconBox>
              <a href={`${item.url}`} target="_blank" rel="noopener">
                <OpenInNew size={18} color={`${colors.gray600}`} />
              </a>
            </IconBox>
          </IconArea>
        </RowUrl>
      </DataTd>
      <DataTd noPadding>
        <DataDataBar barPercent={viewScaledPercent} barColor={DATA_BAR_COLORS.COUNT}>
          <div>{viewCount.toLocaleString()}</div>
          <SubText>({viewOverallRatio.toFixed(1)}%)</SubText>
        </DataDataBar>
      </DataTd>
      <DataTd noPadding>
        <DataDataBar barPercent={item.pageLandingCountScaledPercent} barColor={DATA_BAR_COLORS.COUNT}>
          <div>{item.pageLandingCount.toLocaleString()}</div>
          <SubText>({item.pageLandingCountOverallRatio.toFixed(1)}%)</SubText>
        </DataDataBar>
      </DataTd>
      <DataTd noPadding>
        <DataDataBar barPercent={item.pageBounceRateScaledPercent} barColor={DATA_BAR_COLORS.RATE}>
          <div>{item.pageBounceRate.toFixed(1)}%</div>
        </DataDataBar>
      </DataTd>
      <DataTd noPadding>
        <DataDataBar barPercent={item.pageExitRateScaledPercent} barColor={DATA_BAR_COLORS.RATE}>
          <div>{item.pageExitRate.toFixed(1)}%</div>
        </DataDataBar>
      </DataTd>
      <DataTd noPadding>
        <DataDataBar barPercent={item.pageConversionsScaledPercent} barColor={DATA_BAR_COLORS.COUNT}>
          <div>{item.pageConversions.toLocaleString()}</div>
          <SubText>({item.pageConversionsOverallRatio.toFixed(1)}%)</SubText>
        </DataDataBar>
      </DataTd>
      <DataTd noPadding>
        <DataDataBar barPercent={item.pageConversionsRateScaledPercent} barColor={DATA_BAR_COLORS.RATE}>
          <div>{item.pageConversionsRate.toFixed(2)}%</div>
        </DataDataBar>
      </DataTd>
      <RevenueAmountData
        revenueType={revenueType}
        amount={item.pageRevenueAmount}
        count={item.pageRevenueCount}
        barPercent={item.pageRevenueAmountScaledPercent}
      />
    </DataTr>
  )
}

const RowUrl = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: ${gridLayout.height};
`

const Box = styled.div<{ linkDisabled: boolean }>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  ${(props) => !props.linkDisabled && `cursor: pointer;`}
`

const Url = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const DesignedLink = styled(Link)`
  word-break: break-all;
  line-height: 14px;
  color: ${colors.link.base};

  &:visited {
    color: ${colors.link.visited};
  }
`

const Title = styled.div`
  font-size: ${gridLayout.fontSize};
  color: ${colors.gray500};
  line-height: 14px;
  margin-top: 6px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const IconBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: ${gridLayout.row.icon};
  min-width: ${gridLayout.row.icon};
  height: 100%;
  min-height: ${gridLayout.height};
`

const IconArea = styled.div`
  display: flex;
`
