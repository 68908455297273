import React, { ReactElement } from 'react'

import { DataDataBar, DataTd, SubText } from '../../../DataTable'
import { RevenueType } from '../../../../types/Report/RevenueType'
import { DATA_BAR_COLORS } from '../../../../styleConstants'

interface Props {
  readonly revenueType: RevenueType | null
  readonly amount: number
  readonly count: number
  readonly barPercent: number
}

export const RevenueAmountData = ({ revenueType, amount, count, barPercent }: Props): ReactElement | null => {
  if (!revenueType) return null

  return (
    <DataTd noPadding>
      <DataDataBar barPercent={barPercent} barColor={DATA_BAR_COLORS.REVENUE}>
        <div>{amount.toLocaleString()}</div>
        <SubText>({count.toLocaleString()})</SubText>
      </DataDataBar>
    </DataTd>
  )
}
