import * as React from 'react'
import { useQuery } from '@tanstack/react-query'

import { CalenderState } from '../../../../components/common/DayPickerRange'
import { ScopeType, useScopeType } from '../../useScopeType'
import { CustomFilterState } from '../Filter/types'
import { getDateStringYMD } from '../../../Date'
import { getScopeTypeApiValue } from '../../../getScopeTypeApiValue'
import { makeReportFilters } from '../../../makeReportFilters'
import { ErrorType, request } from '../../../request'
import { TRAFFIC_REPORT_QUERY_KEY } from '../constants'
import { DeviceType, getDeviceLayoutNumber, useDeviceType } from '../../useDeviceType'
import { useGoalList } from '../Goal/useGoalList'
import { useGoalId } from '../../useGoalId'
import { ReportContext } from '../../../../contexts/ReportProvider'
import { CustomFilterContext } from '../../../../contexts/CustomFilterContext'
import { TrafficReportContext } from '../../../../contexts/TrafficReportContext'
import {
  SortKey,
  TrafficReportData,
  TrafficReportItemResponse,
  TrafficReportPayload,
  TrafficReportResponse,
} from './types'
import { Percent } from '../../../Parse'
import { safeDivide } from '../../../safeDivide'
import { ListItemsCountChoice } from '../../../../contexts/TrafficReportContext/state'
import { convertSortOrder } from '../CustomDimensionReport/utils'
import { SortIconState } from '../../../../components/common/SortButton'
import { getScaledPercent } from '../../../getScaledPercent'
import { DimensionId } from './types'
import { useTrafficReportDimensionChoices } from '../../cookie/useTrafficReportDimensionChoices'

interface Props {
  readonly projectId: number
  readonly enabled?: boolean
}

export const useTrafficReport = ({ projectId, enabled = true }: Props) => {
  const {
    state: { calenderState, uuid },
  } = React.useContext(ReportContext)
  const {
    state: { customFilterState },
  } = React.useContext(CustomFilterContext)
  const { listItemsCount, pagerIndex, sortKey, sortIcon, searchText } = React.useContext(TrafficReportContext)

  const { scopeType } = useScopeType()
  const { deviceType } = useDeviceType()
  const { goalId } = useGoalId({ projectId })

  const { getGoal } = useGoalList({ projectId })
  const goal = getGoal(goalId)

  const [errorMessage, setErrorMessage] = React.useState('')

  const { dimensionChoices } = useTrafficReportDimensionChoices()

  const queryKey = [
    TRAFFIC_REPORT_QUERY_KEY,
    {
      uuid,
      projectId,
      calenderState,
      goal,
      scopeType,
      deviceType,
      customFilterState,
      searchText,
      listItemsCount,
      pagerIndex,
      sortKey,
      sortIcon,
      dimensionChoices,
    },
  ]

  const queryResult = useQuery({
    queryKey,
    queryFn: async () => {
      const requestBody = makeRequestBody(
        calenderState,
        goalId,
        scopeType,
        deviceType,
        customFilterState,
        searchText,
        listItemsCount,
        pagerIndex,
        sortKey,
        sortIcon,
        dimensionChoices,
      )
      return await request<TrafficReportResponse>(
        'POST',
        `/api/projects/${projectId}/traffic_report/`,
        true,
        JSON.stringify(requestBody),
      )
    },
    select: (response: TrafficReportResponse): TrafficReportData => {
      if (errorMessage) setErrorMessage('')

      const sessionCounts = response.results.map((item) => item.session_count)
      const userCounts = response.results.map((item) => item.user_count)
      const bounceRates = response.results.map((item) => item.bounce_rate)
      const goalCounts = response.results.map((item) => item.goal_count)
      const goalRates = response.results.map((item) => item.goal_rate)
      const revenueAmounts = response.results.map((item) => item.revenue_amount)

      const minSessionCount = Math.min(...sessionCounts)
      const maxSessionCount = Math.max(...sessionCounts)
      const minUserCount = Math.min(...userCounts)
      const maxUserCount = Math.max(...userCounts)
      const minBounceRate = Math.min(...bounceRates)
      const maxBounceRate = Math.max(...bounceRates)
      const minGoalCount = Math.min(...goalCounts)
      const maxGoalCount = Math.max(...goalCounts)
      const minGoalRate = Math.min(...goalRates)
      const maxGoalRate = Math.max(...goalRates)
      const minRevenueAmount: number = Math.min(...revenueAmounts)
      const maxRevenueAmount: number = Math.max(...revenueAmounts)

      return {
        count: response.count,
        revenueType: response.revenue_type,
        allSessionCount: response.all_session_count,
        allUserCount: response.all_user_count,
        allBounceRate: Percent.parse(response.all_bounce_rate),
        allGoalCount: response.all_goal_count,
        allGoalRate: Percent.parse(response.all_goal_rate),
        allRevenueCount: response.all_revenue_count,
        allRevenueAmount: response.all_revenue_amount,
        results: response.results.map((item: TrafficReportItemResponse, index: number) => {
          return {
            index: listItemsCount * pagerIndex + (index + 1),
            channelName: item.channel_name,
            firstTrafficSource: item.first_traffic_source,
            firstTrafficMedium: item.first_traffic_medium,
            firstTrafficCampaign: item.first_traffic_campaign,
            sessionCount: item.session_count,
            sessionScaledPercent: getScaledPercent(item.session_count, minSessionCount, maxSessionCount),
            sessionOverallRatio: Percent.parse(safeDivide(item.session_count, response.all_session_count)),
            userCount: item.user_count,
            userScaledPercent: getScaledPercent(item.user_count, minUserCount, maxUserCount),
            userOverallRatio: Percent.parse(safeDivide(item.user_count, response.all_user_count)),
            bounceRate: Percent.parse(item.bounce_rate),
            bounceRateScaledPercent: getScaledPercent(item.bounce_rate, minBounceRate, maxBounceRate),
            goalCount: item.goal_count,
            goalCountScaledPercent: getScaledPercent(item.goal_count, minGoalCount, maxGoalCount),
            goalOverallRatio: Percent.parse(safeDivide(item.goal_count, response.all_goal_count)),
            goalRate: Percent.parse(item.goal_rate),
            goalRateScaledPercent: getScaledPercent(item.goal_rate, minGoalRate, maxGoalRate),
            revenueCount: item.revenue_count,
            revenueAmount: item.revenue_amount,
            revenueAmountScaledPercent: getScaledPercent(item.revenue_amount, minRevenueAmount, maxRevenueAmount),
          }
        }),
      }
    },
    onError: (error: ErrorType) => {
      if (typeof error === 'string') setErrorMessage(error)
    },
    enabled: !!goal && enabled,
  })

  return {
    ...queryResult,
    errorMessage,
  }
}

const makeRequestBody = (
  calenderState: CalenderState,
  goalId: number,
  scopeType: ScopeType,
  deviceType: DeviceType,
  customFilterState: CustomFilterState[],
  searchText: string,
  listItemsCount: ListItemsCountChoice,
  pagerIndex: number,
  sortKey: SortKey,
  sortIcon: SortIconState,
  dimensionChoices: DimensionId[],
): TrafficReportPayload => {
  return {
    search_from: getDateStringYMD(calenderState.startDate, '-'),
    search_to: getDateStringYMD(calenderState.endDate, '-'),
    goal_id: goalId,
    scope_type: getScopeTypeApiValue(scopeType),
    page_layout: getDeviceLayoutNumber(deviceType),
    filters: makeReportFilters(customFilterState),
    search_text: searchText,
    dimension_ids: dimensionChoices,
    limit: listItemsCount,
    offset: pagerIndex * listItemsCount,
    sort_key: sortKey,
    sort_order: convertSortOrder(sortIcon),
  }
}
