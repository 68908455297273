import * as React from 'react'

import { toLocaleStringWithCeil } from '../../../util/toLocaleStringWithCeil'
import { DataSummaryTr, DataTd, SubText } from '../../DataTable'
import { RevenueType } from '../../../types/Report/RevenueType'
import { RevenueAmountSummary } from '../../report/table/summary/RevenueAmountSummary'

interface Props {
  readonly allViewCount: number
  readonly allLandingCount: number
  readonly allBounceRate: number
  readonly allExitRate: number
  readonly allConversions: number
  readonly allConversionsRate: number
  readonly allRevenueCount: number
  readonly allRevenueAmount: number
  readonly revenueType: RevenueType | null
}
export function SummaryRow({
  allViewCount,
  allLandingCount,
  allBounceRate,
  allExitRate,
  allConversions,
  allConversionsRate,
  allRevenueCount,
  allRevenueAmount,
  revenueType,
}: Props) {
  return (
    <DataSummaryTr>
      <DataTd noBorderRight></DataTd>
      <DataTd textAlign="left">全体</DataTd>
      <DataTd>
        <div>{toLocaleStringWithCeil(allViewCount)}</div>
        <SubText>(100.0%)</SubText>
      </DataTd>
      <DataTd>
        <div>{toLocaleStringWithCeil(allLandingCount)}</div>
        <SubText>(100.0%)</SubText>
      </DataTd>
      <DataTd>{allBounceRate.toFixed(1)}%</DataTd>
      <DataTd>{allExitRate.toFixed(1)}%</DataTd>
      <DataTd>
        <div>{allConversions.toLocaleString()}</div>
        <SubText>(100.0%)</SubText>
      </DataTd>
      <DataTd>{allConversionsRate.toFixed(2)}%</DataTd>
      <RevenueAmountSummary revenueType={revenueType} amount={allRevenueAmount} count={allRevenueCount} />
    </DataSummaryTr>
  )
}
