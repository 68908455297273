import * as React from 'react'

import styled from 'styled-components'

interface Props {
  readonly type: string
  readonly condition: string
  readonly value: string | number
}

export function EventCondition(props: Props) {
  return (
    <Container>
      <Type>{props.type}</Type>
      <Content>
        {props.condition}: {props.value}
      </Content>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  line-height: 21px;
  & > * {
    flex-shrink: 0;
  }
`
const Type = styled.div`
  width: 100px;
`
const Content = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 244px;
`
