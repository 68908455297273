import * as React from 'react'
import { useCookies } from 'react-cookie'
import { GlobalContext } from '../../../GlobalState'

const HIDE_FLG_ON = '1'

const getCookieName = (newsId: number | undefined, userId: number | undefined): string => {
  if (newsId !== undefined && userId !== undefined) {
    return `hide_news_${userId}_${newsId}`
  } else {
    return 'unknown'
  }
}

/**
 * お知らせの表示状態を管理する
 *
 * @returns {object} - お知らせの表示状態を管理する
 */
export const useNewsVisible = (newsId: number | undefined) => {
  const {
    state: { AccountInfo },
  } = React.useContext(GlobalContext)
  const cookieName = getCookieName(newsId, AccountInfo.userId)

  const [cookies, setCookie] = useCookies([cookieName])

  const checkVisible = () => cookies[cookieName] !== HIDE_FLG_ON

  const [isNewsVisible, setIsNewsVisible] = React.useState(checkVisible())

  React.useEffect(() => {
    setIsNewsVisible(checkVisible)
  }, [cookieName])

  const hideNews = () => {
    const expireTime = 90 * 24 * 60 * 60 * 1000 // 3 months
    const expires = new Date(new Date().getTime() + expireTime)
    setCookie(cookieName, HIDE_FLG_ON, { expires, path: '/' })
    setIsNewsVisible(false)
  }

  return { isNewsVisible, hideNews }
}
