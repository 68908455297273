import * as React from 'react'
import {
  DataTable,
  DataTh,
  DataTd,
  DataTr,
  CELL_BACKGROUND_COLORS,
  SortControl,
  SubText,
  DataSummaryTr,
  DataDataBar,
  INDEX_COLUMN_WIDTH,
} from '../../../components/DataTable'
import { DataContentLink } from '../../../components/DataTable/DataContentLink'
import { SCOPE_TYPE, useScopeType } from '../../../util/hooks/useScopeType'
import { DimensionId, TrafficReportData, TrafficReportItemData } from '../../../util/hooks/api/TrafficReport/types'
import {
  DIMENSION_ID_TO_KEY,
  DIMENSION_IDS,
  DIMENSION_LABELS,
  SORT_KEYS,
} from '../../../util/hooks/api/TrafficReport/constants'
import { DATA_BAR_COLORS } from '../../../styleConstants'
import { HELP_TEXTS } from '../../../constants'
import { useTrafficReportDimensionChoices } from '../../../util/hooks/cookie/useTrafficReportDimensionChoices'
import { DimensionCells } from './DimensionCells'
import { DimensionHeaders } from './DimensionHeaders'
import { TrafficReportContext } from '../../../contexts/TrafficReportContext'
import { ProjectContext } from '../../ProjectRoot'
import { DIMENSION_VALUE_MAP, DimensionTooltip } from './DimensionTooltip'
import { SortIconState } from '../../../components/common/SortButton'
import { CustomFilterContext } from '../../../contexts/CustomFilterContext'
import { makeDimensionState } from '../../../contexts/CustomFilterContext/state'
import { DIMENSION_MATCH_CONDITIONS } from '../../../util/hooks/api/Filter/constants'
import { CustomFilterState } from '../../../util/hooks/api/Filter/types'
import { AGGREGATE_CONDITIONS } from '../../../util/hooks/api/Filter/constants'
import { AGGREGATE_SCOPES } from '../../../util/hooks/api/Filter/constants'
import { RevenueAmountHeader } from '../../../components/report/table/headers/RevenueAmountHeader'
import { RevenueAmountSummary } from '../../../components/report/table/summary/RevenueAmountSummary'
import { RevenueAmountData } from '../../../components/report/table/data/RevenueAmountData'

interface Props {
  readonly data?: TrafficReportData
}

export const DIMENSION_TABLE_CONFIG = {
  [DIMENSION_IDS.CHANNEL]: {
    sortKey: SORT_KEYS.CHANNEL_NAME,
    label: DIMENSION_LABELS[DIMENSION_IDS.CHANNEL],
    getItemData: (item: TrafficReportItemData) => item.channelName,
  },
  [DIMENSION_IDS.SOURCE]: {
    sortKey: SORT_KEYS.FIRST_TRAFFIC_SOURCE,
    label: DIMENSION_LABELS[DIMENSION_IDS.SOURCE],
    getItemData: (item: TrafficReportItemData) => item.firstTrafficSource,
  },
  [DIMENSION_IDS.MEDIUM]: {
    sortKey: SORT_KEYS.FIRST_TRAFFIC_MEDIUM,
    label: DIMENSION_LABELS[DIMENSION_IDS.MEDIUM],
    getItemData: (item: TrafficReportItemData) => item.firstTrafficMedium,
  },
  [DIMENSION_IDS.CAMPAIGN]: {
    sortKey: SORT_KEYS.FIRST_TRAFFIC_CAMPAIGN,
    label: DIMENSION_LABELS[DIMENSION_IDS.CAMPAIGN],
    getItemData: (item: TrafficReportItemData) => item.firstTrafficCampaign,
  },
} as const

/**
 * 流入レポートのレポートテーブルをレンダリングする
 *
 * @param {Props} param - The input parameters for the method.
 * @param {TrafficReportData} param.data - 流入レポートのデータ
 */
export const ReportTable = ({ data }: Props) => {
  if (!data) return null

  const {
    allSessionCount,
    allUserCount,
    allBounceRate,
    allGoalCount,
    allGoalRate,
    allRevenueCount,
    allRevenueAmount,
    revenueType,
  } = data
  const items = data.results

  const { scopeType, scopeTypeLabel } = useScopeType()
  const { getSortIcon, setSortValues, resetPagerIndex } = React.useContext(TrafficReportContext)
  const {
    state: { baseUrl },
  } = React.useContext(ProjectContext)
  const { dimensionChoices } = useTrafficReportDimensionChoices()
  const { actions: customFilterActions } = React.useContext(CustomFilterContext)

  const isScopeTypeUser = scopeType === SCOPE_TYPE.USER

  const handleSort = (newSortKey: string, newSortIcon: SortIconState) => {
    resetPagerIndex()
    setSortValues(newSortKey, newSortIcon)
  }

  const makePageReportUrlWithFilter = (item: TrafficReportItemData) => {
    const addonFilterState = makeAddonFilterState(item, dimensionChoices)
    return customFilterActions.makePageReportUrlWithHashFilter(baseUrl, addonFilterState, window.location.search)
  }

  /**
   * 追加するフィルターを流入レポートの各行のデータから作成する
   * @param {TrafficReportItemData} trafficReportItem - トラフィックレポートの行データ
   * @param {DimensionId[]} dimensionChoices - 選択されたディメンションID配列。これらの項目をフィルターに追加する
   * @return {CustomFilterState[]} - カスタムフィルター状態
   */
  const makeAddonFilterState = (
    trafficReportItem: TrafficReportItemData,
    dimensionChoices: DimensionId[],
  ): CustomFilterState[] => {
    return [
      {
        aggregate: {
          unit: AGGREGATE_SCOPES.SESSION,
          condition: AGGREGATE_CONDITIONS.INCLUDE,
        },
        dimensions: dimensionChoices.map((dimensionId) => {
          const dimensionKey = DIMENSION_ID_TO_KEY[dimensionId]
          const value = DIMENSION_VALUE_MAP[dimensionId](trafficReportItem) ?? ''
          const condition = value === '' ? DIMENSION_MATCH_CONDITIONS.EMPTY : DIMENSION_MATCH_CONDITIONS.FULL

          return {
            states: [makeDimensionState(dimensionKey, condition, value)],
          }
        }),
      },
    ]
  }

  return (
    <DataTable>
      <thead data-testid="report-table-header">
        <DataTr>
          <DataTh noBorderRight minWidth={INDEX_COLUMN_WIDTH}></DataTh>
          <DimensionHeaders />
          <DataTh backgroundColor={CELL_BACKGROUND_COLORS.VIEW} minWidth="126px">
            <SortControl
              subTitle={'(対全体比率)'}
              helpText={isScopeTypeUser ? HELP_TEXTS.USER_COUNT : HELP_TEXTS.SESSION_COUNT}
              helpWidth={200}
              sortKey={isScopeTypeUser ? SORT_KEYS.USER_COUNT : SORT_KEYS.SESSION_COUNT}
              sortIcon={getSortIcon(isScopeTypeUser ? SORT_KEYS.USER_COUNT : SORT_KEYS.SESSION_COUNT)}
              onSortClick={handleSort}
            >
              {scopeTypeLabel}数
            </SortControl>
          </DataTh>
          <DataTh backgroundColor={CELL_BACKGROUND_COLORS.VIEW} minWidth="90px">
            <SortControl
              helpText={HELP_TEXTS.BOUNCE_RATE}
              helpWidth={200}
              sortKey={SORT_KEYS.BOUNCE_RATE}
              sortIcon={getSortIcon(SORT_KEYS.BOUNCE_RATE)}
              onSortClick={handleSort}
            >
              直帰率
            </SortControl>
          </DataTh>
          <DataTh backgroundColor={CELL_BACKGROUND_COLORS.VIEW} minWidth="119px">
            <SortControl
              subTitle={'(対全体比率)'}
              helpText={isScopeTypeUser ? HELP_TEXTS.USER_GOAL_COUNT : HELP_TEXTS.SESSION_GOAL_COUNT}
              helpWidth={200}
              sortKey={SORT_KEYS.GOAL_COUNT}
              sortIcon={getSortIcon(SORT_KEYS.GOAL_COUNT)}
              onSortClick={handleSort}
            >
              ゴール数
            </SortControl>
          </DataTh>
          <DataTh backgroundColor={CELL_BACKGROUND_COLORS.VIEW} minWidth="102px">
            <SortControl
              helpText={isScopeTypeUser ? HELP_TEXTS.USER_GOAL_RATE : HELP_TEXTS.SESSION_GOAL_RATE}
              helpLeft={-150}
              sortKey={SORT_KEYS.GOAL_RATE}
              sortIcon={getSortIcon(SORT_KEYS.GOAL_RATE)}
              onSortClick={handleSort}
            >
              ゴール率
            </SortControl>
          </DataTh>
          <RevenueAmountHeader
            revenueType={revenueType}
            sortKey={SORT_KEYS.REVENUE_AMOUNT}
            sortIcon={getSortIcon(SORT_KEYS.REVENUE_AMOUNT)}
            onSortClick={handleSort}
          />
        </DataTr>
      </thead>

      <tbody>
        <DataSummaryTr>
          <DataTd noBorderRight></DataTd>
          <DataTd textAlign="left" colSpan={dimensionChoices.length}>
            全体
          </DataTd>
          <DataTd>
            <div>{(isScopeTypeUser ? allUserCount : allSessionCount).toLocaleString()}</div>
            <SubText>(100.0%)</SubText>
          </DataTd>
          <DataTd>{allBounceRate.toFixed(1)}%</DataTd>
          <DataTd>
            <div>{allGoalCount.toLocaleString()}</div>
            <SubText>(100.0%)</SubText>
          </DataTd>
          <DataTd>{allGoalRate.toFixed(2)}%</DataTd>
          <RevenueAmountSummary revenueType={revenueType} amount={allRevenueAmount} count={allRevenueCount} />
        </DataSummaryTr>

        {items.map((item) => (
          <DataTr key={item.index}>
            <DataTd noBorderRight>{item.index}</DataTd>
            <DimensionCells item={item} />
            <DataTd noPadding>
              <DataDataBar
                barPercent={isScopeTypeUser ? item.userScaledPercent : item.sessionScaledPercent}
                barColor={DATA_BAR_COLORS.COUNT}
              >
                <DimensionTooltip item={item}>
                  <span>
                    <DataContentLink to={makePageReportUrlWithFilter(item)} bold underline>
                      {(isScopeTypeUser ? item.userCount : item.sessionCount).toLocaleString()}
                    </DataContentLink>
                  </span>
                </DimensionTooltip>
                <SubText>
                  ({isScopeTypeUser ? item.userOverallRatio.toFixed(1) : item.sessionOverallRatio.toFixed(1)}%)
                </SubText>
              </DataDataBar>
            </DataTd>
            <DataTd noPadding>
              <DataDataBar barPercent={item.bounceRateScaledPercent} barColor={DATA_BAR_COLORS.RATE}>
                <div>{item.bounceRate.toFixed(1)}%</div>
              </DataDataBar>
            </DataTd>
            <DataTd noPadding>
              <DataDataBar barPercent={item.goalCountScaledPercent} barColor={DATA_BAR_COLORS.COUNT}>
                <div>{item.goalCount.toLocaleString()}</div>
                <SubText>({item.goalOverallRatio.toFixed(1)}%)</SubText>
              </DataDataBar>
            </DataTd>
            <DataTd noPadding>
              <DataDataBar barPercent={item.goalRateScaledPercent} barColor={DATA_BAR_COLORS.RATE}>
                <div>{item.goalRate.toFixed(2)}%</div>
              </DataDataBar>
            </DataTd>
            <RevenueAmountData
              revenueType={revenueType}
              amount={item.revenueAmount}
              count={item.revenueCount}
              barPercent={item.revenueAmountScaledPercent}
            />
          </DataTr>
        ))}
      </tbody>
    </DataTable>
  )
}
