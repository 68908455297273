import * as React from 'react'
import styled from 'styled-components'
import { GRID_BORDER_COLOR, DataBarColorType, colors } from '../../styleConstants'
import { HeaderCellHelp } from '../ContentReport/cells/HeaderCellHelp'
import { SORT_ICONS, SortButton, SortIconState } from '../common/SortButton'
import { InteractiveTooltip, TOOLTIP_DELAY } from '../Tooltip'
import { DimensionBackgroundColor } from '../../pages/TrafficReport/utils'

const DEFAULT_MIN_WIDTH = '400px'
export const INDEX_COLUMN_WIDTH = '52px'

export const CELL_BACKGROUND_COLORS = {
  CONTENT: colors.lightCyan,
  VIEW: colors.lightSalmon,
  GOAL: colors.peachPuff,
  CLICK: colors.lightYellow,
  SUMMARY: colors.gray100,
  DISABLED: colors.gray200,
}
export type CellBackgroundColor = typeof CELL_BACKGROUND_COLORS[keyof typeof CELL_BACKGROUND_COLORS]

interface BaseCellProps {
  noPadding?: boolean
  fillWidth?: boolean
  minWidth?: string
  noBorderRight?: boolean
  textAlign?: 'left' | 'right' | 'center'
  verticalAlign?: 'top' | 'middle'
  whiteSpace?: 'nowrap' | 'normal'
  ellipsis?: boolean
  colSpan?: number
}

const BaseCell = styled.td.attrs<BaseCellProps>(({ colSpan }) => ({
  colSpan: colSpan,
}))<BaseCellProps>`
  padding: ${({ noPadding }) => (noPadding ? '0' : '8px')};
  width: ${({ fillWidth }) => (fillWidth ? '100%' : 'auto')};
  min-width: ${({ minWidth, fillWidth }) => (minWidth ? minWidth : fillWidth ? DEFAULT_MIN_WIDTH : 'auto')};
  border: 1px solid ${GRID_BORDER_COLOR};
  border-left: none;
  ${({ noBorderRight }) => noBorderRight && 'border-right: none;'};
  font-size: 14px;
  line-height: 1.5;
  text-align: ${({ textAlign = 'right' }) => textAlign};
  vertical-align: ${({ verticalAlign = 'middle' }) => verticalAlign};
  white-space: ${({ whiteSpace = 'nowrap' }) => whiteSpace};
  ${({ ellipsis }) =>
    ellipsis &&
    `
      max-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
  `}
`

interface DataTableScrollProps {
  height: string
}

export const DataTableScroll: React.FC<DataTableScrollProps> = ({ children, height }) => (
  <StyledDataTableScroll height={height}>{children}</StyledDataTableScroll>
)

const StyledDataTableScroll = styled.div<DataTableScrollProps>`
  height: ${({ height }) => height};
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
`

interface DataTableProps {
  maxWidth?: string
}

export const DataTable: React.FC<DataTableProps> = ({ children, ...props }) => (
  <StyledDataTable data-testid="data-table" {...props}>
    {children}
  </StyledDataTable>
)

const StyledDataTable = styled.table<DataTableProps>`
  width: 100%;
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth};`}
  border-collapse: collapse;
  border-left: 1px solid ${GRID_BORDER_COLOR};
  background-color: ${colors.white};
`

interface DataTrProps {
  backgroundColor?: CellBackgroundColor
}

export const DataTr: React.FC<DataTrProps> = ({ children, backgroundColor }) => (
  <StyledDataTr backgroundColor={backgroundColor}>{children}</StyledDataTr>
)

const StyledDataTr = styled.tr<DataTrProps>`
  ${({ backgroundColor }) =>
    backgroundColor
      ? `background-color: ${backgroundColor};`
      : `&:hover {
          background-color: ${colors.gray50};
        }`}
`

export const DataSummaryTr: React.FC = ({ children }) => <StyledDataSummaryTr>{children}</StyledDataSummaryTr>

const StyledDataSummaryTr = styled.tr`
  background: ${CELL_BACKGROUND_COLORS.SUMMARY};
  font-weight: bold;
`

interface DataThProps extends BaseCellProps {
  fixedRow?: boolean
  backgroundColor?: CellBackgroundColor | DimensionBackgroundColor
}

export const DataTh: React.FC<DataThProps> = (props) => <StyledDataTh {...props} />

const StyledDataTh = styled(BaseCell).attrs({ as: 'th' })<DataThProps>`
  background: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : CELL_BACKGROUND_COLORS.CONTENT)};
  font-size: 12px;
  font-weight: bold;

  ${({ fixedRow }) =>
    fixedRow &&
    `
    position: sticky;
    top: 0;
    left: 0;
    border-top: none;

    ::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-top: 1px solid ${GRID_BORDER_COLOR};
    }
    ::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-bottom: 1px solid ${GRID_BORDER_COLOR};
    }
  `}
`

interface DataTdProps extends BaseCellProps {
  tooltipContent?: string | React.ReactNode
}

export const DataTd: React.FC<DataTdProps> = ({ tooltipContent, children, ...props }) => {
  return tooltipContent ? (
    <StyledDataTd {...props}>
      <InteractiveTooltip content={tooltipContent} delay={TOOLTIP_DELAY} offset={[8, 10]}>
        <DataTdInner ellipsis={props.ellipsis}>{children}</DataTdInner>
      </InteractiveTooltip>
    </StyledDataTd>
  ) : (
    <StyledDataTd {...props}>{children}</StyledDataTd>
  )
}

const StyledDataTd = styled(BaseCell)<DataTdProps>``
const DataTdInner = styled.div<{ ellipsis?: boolean }>`
  ${({ ellipsis }) =>
    ellipsis &&
    `
      overflow: hidden;
      text-overflow: ellipsis;
  `}
`

interface DataDataBarProps {
  barPercent: number
  barColor: DataBarColorType
}

export const DataDataBar: React.FC<DataDataBarProps> = ({ children, ...props }) => (
  <StyledDataDataBar {...props} data-testid="data-bar" data-test-scaled-percent={props.barPercent}>
    {children}
  </StyledDataDataBar>
)

const StyledDataDataBar = styled.div<DataDataBarProps>`
  padding: 8px;
  background: linear-gradient(
    to right,
    ${({ barColor, barPercent }) => `${barColor} ${barPercent}%`},
    transparent ${({ barPercent }) => barPercent}%
  );
  background-size: 100% 24px;
  background-repeat: no-repeat;
  background-position: center;
`

interface SortControlProps {
  readonly subTitle?: string
  readonly sortKey: string
  readonly sortIcon: SortIconState
  readonly onSortClick: (sortKey: string, sortIcon: SortIconState) => void
  readonly helpText?: string
  readonly helpWidth?: number
  readonly helpLeft?: number
  readonly textAlign?: 'left' | 'center'
}

export const SortControl: React.FC<SortControlProps> = ({
  children,
  subTitle,
  sortKey,
  sortIcon,
  onSortClick,
  helpText,
  helpWidth,
  helpLeft,
  textAlign = 'center',
}) => {
  // 現状のソート状況が降順の場合は、次回は昇順を設定。それ以外の場合は、次回は降順を設定する。
  const handleSortClick = () => onSortClick(sortKey, sortIcon === SORT_ICONS.DOWN ? SORT_ICONS.UP : SORT_ICONS.DOWN)

  return (
    <DataHeadBox onClick={handleSortClick}>
      <HorizontalDivide textAlign={textAlign}>
        <VerticalDivide>
          <div>{children}</div>
          {subTitle && <SubText>{subTitle}</SubText>}
        </VerticalDivide>
        {helpText && (
          <HeaderCellHelp width={helpWidth || 200} left={helpLeft}>
            {helpText}
          </HeaderCellHelp>
        )}
        <SortButton elementKey={sortKey} sortIcon={sortIcon} />
      </HorizontalDivide>
    </DataHeadBox>
  )
}

const DataHeadBox = styled.a`
  display: block;
  cursor: pointer;
`

const HorizontalDivide = styled.div<{ textAlign: 'left' | 'center' }>`
  display: flex;
  justify-content: ${({ textAlign }) => (textAlign === 'left' ? 'flex-start' : 'center')};
  align-items: center;
`

const VerticalDivide = styled.div`
  display: flex;
  flex-direction: column;
`

export const SubText: React.FC = ({ children }) => <StyledSubText>{children}</StyledSubText>

const StyledSubText = styled.div`
  font-size: 12px;
  font-weight: normal;
  color: ${colors.gray750};
`
