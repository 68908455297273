import * as React from 'react'
import cc from 'classcat'
import styled from 'styled-components'
import { colors } from '../../styleConstants'
import {
  CaptureControlGridItem,
  CaptureControlItem,
  CaptureControlItemKey,
  gridSize,
  SortState,
} from './CaptureControlGridItem'
import { SortButton } from '../common/SortButton'
import { HelpTooltip } from '../Tooltip/IconTooltip'

interface Props {
  readonly items: CaptureControlItem[]
  readonly totalCount: number // 合計ページ数
  readonly totalEstimate: number // 合計月間取得回数目安
  readonly selectedId?: number
  readonly sortState: SortState
  readonly canChange?: boolean
  readonly canDelete?: boolean
  readonly matchedId: number | null
  readonly onSort: (key: CaptureControlItemKey) => void
  readonly onEdit: (id: number, isUnlimited?: boolean) => void
  readonly onDelete: (id: number) => void
  readonly isUnlimited: boolean
  readonly listRef: React.RefObject<HTMLUListElement>
}

export function CaptureControlGrid(props: Props) {
  const {
    items,
    totalCount,
    totalEstimate,
    selectedId,
    sortState,
    canChange,
    canDelete,
    matchedId,
    onEdit,
    onDelete,
    isUnlimited,
    listRef,
  } = props
  const [state, setState] = React.useState({ id: 0, opened: false })
  // popup制御
  const onClick = (id: number) => {
    setState({ ...state, id: id, opened: true })
  }
  const onClose = () => {
    if (state.opened) {
      setState({ ...state, id: 0, opened: false })
    }
  }

  const element = items.map((item, index) => {
    return (
      <Li
        data-id={item.id}
        key={index}
        className={cc([selectedId === item.id ? 'on' : 'off', { highlight: item.id === matchedId }])}
      >
        <CaptureControlGridItem
          item={item}
          opened={state.opened}
          selectedId={state.id}
          canChange={canChange}
          canDelete={canDelete}
          onClick={onClick}
          onClose={onClose}
          onEdit={onEdit}
          onDelete={onDelete}
          isUnlimited={isUnlimited}
          ref={item.itemRef}
        />
      </Li>
    )
  })
  // const onSortClick = (elementKey: any) => {
  //   onSort(elementKey)
  // }

  return (
    <Container>
      <HeaderWrapper>
        <Header>
          {/* 条件 */}
          <Row className="pattern">
            <HeaderContents>条件</HeaderContents>
            <HelpTooltip width={400} left={-100}>
              <Table style={{ width: '390px' }}>
                <thead>
                  <tr>
                    <Td className="header">ページURL</Td>
                    <Td>完全一致</Td>
                    <Td>指定したページURLと完全に一致するページ</Td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <Td className="header"></Td>
                    <Td>前方一致</Td>
                    <Td>指定したページURLの前方が一致しているページ</Td>
                  </tr>
                  <tr>
                    <Td className="header"></Td>
                    <Td>正規表現一致</Td>
                    <Td>指定したページの正規表現が一致しているページ</Td>
                  </tr>
                  <tr>
                    <Td className="header"></Td>
                    <Td>部分一致</Td>
                    <Td>指定したページの一部分が一致しているページ</Td>
                  </tr>
                </tbody>
              </Table>
            </HelpTooltip>
            {false && <SortButton elementKey={'pattern'} sortIcon={sortState['pattern']} />}
          </Row>

          {/* ページ */}
          <Row className="page">
            <HeaderContents>ページ</HeaderContents>
            <HelpTooltip width={250}>
              <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'left' }}>
                <div style={{ color: `${colors.white}` }}>
                  Content AnalyticsではURL末尾のスラッシュは削除で統一しています。
                </div>
                <div style={{ color: `${colors.white}`, marginTop: '1rem' }}>{'(例)'}</div>
                <div style={{ color: `${colors.white}` }}>{'o https://www.uncovertruth.co.jp'}</div>
                <div style={{ color: `${colors.white}` }}>{'X https://www.uncovertruth.co.jp/'}</div>
              </div>
            </HelpTooltip>
          </Row>

          {/* 取得可否 */}
          <Row className="event">
            <HeaderContents>取得可否</HeaderContents>
            <HelpTooltip width={450} left={-225}>
              <Table style={{ width: '440px' }}>
                <thead>
                  <tr>
                    <Td className="header">取得可否</Td>
                    <Td>取得する</Td>
                    <Td>ページURLで指定したページのキャプチャを取得します</Td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <Td className="header"></Td>
                    <Td>取得しない</Td>
                    <Td>ページURLで指定したページのキャプチャは取得しません</Td>
                  </tr>
                </tbody>
              </Table>
            </HelpTooltip>
            {false && <SortButton elementKey={'event'} sortIcon={sortState['event']} />}
          </Row>

          {/* 頻度 */}
          <Row className="interval">
            <HeaderContents>頻度</HeaderContents>
            <HelpTooltip width={600} left={-250}>
              <Table style={{ width: '590px' }}>
                <thead>
                  <tr>
                    <Td className="header" style={{ width: '40px' }}>
                      頻度
                    </Td>
                    <Td style={{ width: '70px' }}>{'高(各日間隔)'}</Td>
                    <Td>指定のページで、「取得する」を選択した場合に一日に一回程度の頻度でキャプチャを取得します</Td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <Td className="header"></Td>
                    <Td>{'中(各週間隔)'}</Td>
                    <Td>指定のページで、「取得する」を選択した場合に七日に一回程度の頻度でキャプチャを取得します</Td>
                  </tr>
                  <tr>
                    <Td className="header"></Td>
                    <Td>{'低(各月間隔)'}</Td>
                    <Td>指定のページで、「取得する」を選択した場合に月に一回程度の頻度でキャプチャを取得します</Td>
                  </tr>
                  <tr>
                    <Td className="header"></Td>
                    <Td>{'初回のみ'}</Td>
                    <Td>指定のページで、「取得する」を選択した場合に最初の一回だけキャプチャを取得します</Td>
                  </tr>
                </tbody>
              </Table>
            </HelpTooltip>
            {false && <SortButton elementKey={'interval'} sortIcon={sortState['interval']} />}
          </Row>

          {/* ページ数 */}
          <Row className="count">
            <HeaderContents>ページ数</HeaderContents>
            {false && <SortButton elementKey={'count'} sortIcon={sortState['count']} />}
          </Row>

          {/* 月間キャプチャ数予測 */}
          <Row className="estimate">
            <HeaderContents>月間</HeaderContents>
            <HeaderContents>キャプチャ数予測</HeaderContents>
            {false && <SortButton elementKey={'estimate'} sortIcon={sortState['estimate']} />}
          </Row>

          {/* 編集 */}
          <Row className="edit">
            <HeaderContents></HeaderContents>
            {false && <SortButton elementKey={'edit'} sortIcon={sortState['edit']} />}
          </Row>
        </Header>

        {/* 集計表示 */}
        <Content>
          <Tally className="pattern">合計</Tally>
          <Tally className="page"></Tally>
          <Tally className="event"></Tally>
          <Tally className="interval"></Tally>
          <Tally className="count">{totalCount.toLocaleString()}</Tally>
          <Tally className="estimate">{totalEstimate.toLocaleString()}</Tally>
          <Tally className="edit"></Tally>
        </Content>
      </HeaderWrapper>

      {/* リスト表示 */}
      <Ul ref={listRef} onScroll={onClose}>
        {element}
      </Ul>
    </Container>
  )
}

const Container = styled.div`
  min-width: ${gridSize.minWidth};
  background-color: ${colors.white};
`

const HeaderWrapper = styled.header`
  overflow-y: scroll;
`
const Header = styled.header`
  display: flex;
  align-items: center;
  height: ${gridSize.headerHeight};
  box-sizing: border-box;
`

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  border-top: 1px solid ${colors.gray300};
  border-bottom: 1px solid ${colors.gray300};
  border-left: 1px solid ${colors.gray300};
  box-sizing: border-box;

  &.pattern {
    width: ${gridSize.row.pattern};
    min-width: ${gridSize.row.pattern};
    max-width: ${gridSize.row.pattern};
    background-color: ${colors.lightCyan};
  }

  &.page {
    justify-content: flex-start;
    padding-left: 1rem;
    width: 100%;
    min-width: ${gridSize.row.page};
    background-color: ${colors.lightCyan};
  }

  &.event {
    width: ${gridSize.row.event};
    min-width: ${gridSize.row.event};
    max-width: ${gridSize.row.event};
    background-color: ${colors.lightCyan};
  }

  &.interval {
    width: ${gridSize.row.interval};
    min-width: ${gridSize.row.interval};
    max-width: ${gridSize.row.interval};
    background-color: ${colors.lightCyan};
  }

  &.count {
    width: ${gridSize.row.count};
    min-width: ${gridSize.row.count};
    max-width: ${gridSize.row.count};
    background-color: ${colors.lightSalmon};
  }

  &.estimate {
    flex-direction: column;
    width: ${gridSize.row.estimate};
    min-width: ${gridSize.row.estimate};
    max-width: ${gridSize.row.estimate};
    background-color: ${colors.lightSalmon};
  }

  &.edit {
    width: ${gridSize.row.edit};
    min-width: ${gridSize.row.edit};
    max-width: ${gridSize.row.edit};
    background-color: ${colors.lightSalmon};
    border-right: 1px solid ${colors.gray300};
  }
`

const HeaderContents = styled.div`
  font-size: 0.9rem;
  font-weight: 600;
`

const Content = styled.div`
  display: flex;
  align-items: center;
  height: ${gridSize.minHeight};
  font-size: 0.8rem;
  font-weight: 600;
  box-sizing: border-box;
`

const Tally = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  font-weight: 600;
  height: 100%;
  text-align: center;
  background-color: ${colors.gray100};
  border-left: 1px solid ${colors.gray300};
  border-bottom: 1px solid ${colors.gray300};
  box-sizing: border-box;

  &.count,
  &.estimate {
    justify-content: flex-end;
    word-break: break-all;
    padding: 0 1rem;
  }

  &.pattern {
    width: ${gridSize.row.pattern};
    min-width: ${gridSize.row.pattern};
    max-width: ${gridSize.row.pattern};
  }

  &.page {
    justify-content: flex-start;
    padding-left: 1rem;
    width: 100%;
    min-width: ${gridSize.row.page};
  }

  &.event {
    width: ${gridSize.row.event};
    min-width: ${gridSize.row.event};
    max-width: ${gridSize.row.event};
  }

  &.interval {
    width: ${gridSize.row.interval};
    min-width: ${gridSize.row.interval};
    max-width: ${gridSize.row.interval};
  }

  &.count {
    width: ${gridSize.row.count};
    min-width: ${gridSize.row.count};
    max-width: ${gridSize.row.count};
  }

  &.estimate {
    width: ${gridSize.row.estimate};
    min-width: ${gridSize.row.estimate};
    max-width: ${gridSize.row.estimate};
  }

  &.edit {
    width: ${gridSize.row.edit};
    min-width: ${gridSize.row.edit};
    max-width: ${gridSize.row.edit};
    border-right: 1px solid ${colors.gray300};
  }
`

const Ul = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: calc(100vh - 450px);
  overflow-y: scroll;
`

const Li = styled.li`
  background-color: ${colors.white};
  box-sizing: border-box;

  &.off {
    background-color: ${colors.white};
  }

  &:hover {
    background-color: ${colors.gray50};
  }

  &.on {
    background-color: ${colors.gray200};
  }

  &.highlight {
    border: 1px solid ${colors.orange};
    background-color: ${colors.lightSalmon};
    & + & {
      border-top: none;
    }
  }
`

const Table = styled.table`
  background-color: ${colors.white};
  color: ${colors.black};
  border-collapse: collapse;
  border: 1px solid ${colors.black};
`

const Td = styled.td`
  text-align: left;
  border: 1px solid ${colors.black};
  padding: 0.3rem;

  &.header {
    background-color: ${colors.gray200};
  }
`

// const Th = styled.th`
// text-align:left;
// border: 1px solid ${colors.black};
// padding: 0.2rem;
// `
