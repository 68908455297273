import * as React from 'react'
import styled from 'styled-components'
import { colors, layout } from '../../../styleConstants'
import { IconButton } from '../Button'
import { FilterAlt } from '@styled-icons/boxicons-regular'
import { HeaderDeviceTypeButtons } from '../HeaderDeviceTypeButtons'
import { DeviceType } from '../../../util/hooks/useDeviceType'
import { type Item, ToolMenu } from '../ToolMenu'
import { Filters } from './Filters'
import { Plus } from '@styled-icons/boxicons-regular/Plus'
import {
  AGGREGATE_CONDITIONS,
  AGGREGATE_SCOPES,
  CONDITION_TYPES,
  DEFAULT_DIMENSION_OPTIONS,
  DIMENSION_CATEGORIES,
  DIMENSION_KEYS,
  DIMENSION_MATCH_CONDITIONS,
} from '../../../util/hooks/api/Filter/constants'
import { CustomFilterState } from '../../../util/hooks/api/Filter/types'
import { HelpTooltip } from '../../Tooltip/IconTooltip'
import { FilterButton } from './FilterButton'
import { CustomFilterContext } from '../../../contexts/CustomFilterContext'
import { useReportSettings } from '../../../util/hooks/useReportSettings'
import { ProjectContext } from '../../../pages/ProjectRoot'
import { ReportContext } from '../../../contexts/ReportProvider'
import { useGoalId } from '../../../util/hooks/useGoalId'

interface Props {
  readonly canView: boolean
  readonly filterHidden?: boolean
  readonly onFilterClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  readonly onChangeDevice?: (deviceType: DeviceType) => void
  readonly toolMenuItems?: Item[]
  readonly toolMenuLeftSideElement?: React.ReactElement
  readonly selectedDevice: DeviceType
  readonly onQuickFilterClick?: () => void
}

export function Toolbar({
  canView,
  filterHidden = false,
  onFilterClick,
  onChangeDevice,
  toolMenuItems,
  toolMenuLeftSideElement,
  selectedDevice,
  onQuickFilterClick,
}: Props) {
  if (!canView) return null

  const {
    state: { projectId },
  } = React.useContext(ProjectContext)
  const {
    state: { goalOptions },
  } = React.useContext(ReportContext)
  const {
    state,
    actions: { isFilterApplied, filtersCount, alreadyHasTargetDimension, onApply },
  } = React.useContext(CustomFilterContext)

  const { goalId } = useGoalId({ projectId })

  const { isCompleted } = useReportSettings({ projectId })

  if (!isCompleted) return null

  const goal = { id: goalId, name: goalOptions.find((option) => option.value === goalId)?.label }

  const toggleFilterState = (targetDimension: CustomFilterState) => {
    return alreadyHasTargetDimension(targetDimension)
      ? state.customFilterState.filter((filter) => JSON.stringify(filter) !== JSON.stringify(targetDimension))
      : [...state.customFilterState, targetDimension]
  }

  // 広告レポートボタンで割り込ませるフィルター
  const targetAdDimension = makeAdCustomFilterState()

  // ゴールレポートボタンで割り込ませるフィルター
  const targetGoalDimension: CustomFilterState = {
    aggregate: {
      unit: AGGREGATE_SCOPES.SESSION,
      condition: AGGREGATE_CONDITIONS.INCLUDE,
    },
    dimensions: [
      {
        states: [
          {
            id: DIMENSION_KEYS.GOAL,
            goalId: goal?.id || 0,
            name: goal?.name || '',
            dimensionCategory: DIMENSION_CATEGORIES.GOAL,
            conditionType: CONDITION_TYPES.CHOICE,
            target: undefined,
            condition: DIMENSION_MATCH_CONDITIONS.INCLUDE,
            value: '',
            fromValue: '',
            toValue: '',
          },
        ],
      },
    ],
  }

  const handleFilterAdButton = () => {
    onApply(toggleFilterState(targetAdDimension))
    onQuickFilterClick && onQuickFilterClick()
  }

  const handleFilterGoalButton = () => {
    onApply(toggleFilterState(targetGoalDimension))
    onQuickFilterClick && onQuickFilterClick()
  }

  return (
    <Container>
      <LeftColumn>
        {selectedDevice && <HeaderDeviceTypeButtons selectedDevice={selectedDevice} onChangeDevice={onChangeDevice!} />}
        {!filterHidden && (
          <>
            <DesignedIconButton
              filterApply={isFilterApplied}
              iconLeft={<FilterAlt size={15} />}
              onClick={onFilterClick}
            >
              {isFilterApplied ? `フィルタ（${filtersCount}）` : 'フィルタ'}
            </DesignedIconButton>
            <Buttons>
              <FilterButton
                onClick={handleFilterAdButton}
                alreadyHasTargetDimension={alreadyHasTargetDimension(targetAdDimension)}
              >
                <Plus color={colors.contentBlue.blue7} size={16} />
                広告レポート
                <HelpTooltip width={450}>
                  <HelpText>
                    広告から流入したセッションに絞る
                    <br />
                    <br />
                    ※広告とは・・・
                    <br />
                    チャネルで、下記の正規表現に一致するもの
                    <br />
                    ^(Paid.+|Display|Affiliates)$
                  </HelpText>
                </HelpTooltip>
              </FilterButton>
              <FilterButton
                onClick={handleFilterGoalButton}
                alreadyHasTargetDimension={alreadyHasTargetDimension(targetGoalDimension)}
              >
                <Plus color={colors.contentBlue.blue7} size={16} />
                ゴールレポート
                <HelpTooltip width={210}>
                  <HelpText>選択中のゴールに到達したセッションに絞る</HelpText>
                </HelpTooltip>
              </FilterButton>
            </Buttons>
            {isFilterApplied && <Filters />}
          </>
        )}
      </LeftColumn>
      <RightColumn>
        {toolMenuLeftSideElement && toolMenuLeftSideElement}
        {toolMenuItems && <ToolMenu items={toolMenuItems} />}
      </RightColumn>
    </Container>
  )
}

/**
 * 広告のカスタムフィルター条件を作成する
 *
 * @return {CustomFilterState} 広告系のチャネル情報がセットされたCustomFilterState
 */
function makeAdCustomFilterState(): CustomFilterState {
  const opt = DEFAULT_DIMENSION_OPTIONS.find((opt) => opt.id === DIMENSION_KEYS.CHANNEL)
  if (!opt) throw new Error(`The option "${DIMENSION_KEYS.CHANNEL}" does not exists`)

  return {
    aggregate: {
      unit: AGGREGATE_SCOPES.SESSION,
      condition: AGGREGATE_CONDITIONS.INCLUDE,
    },
    dimensions: [
      {
        states: [
          {
            id: DIMENSION_KEYS.CHANNEL,
            name: opt.name,
            dimensionCategory: opt.dimensionCategory,
            conditionType: opt.conditionType,
            target: undefined,
            condition: DIMENSION_MATCH_CONDITIONS.REGEXP,
            value: '^(Paid.+|Display|Affiliates)$',
            fromValue: '',
            toValue: '',
          },
        ],
      },
    ],
  }
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  padding: 0 32px;
  width: 100%;
  height: ${layout.reportMenuHeight};
  background-color: ${colors.blue};
`

const LeftColumn = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  white-space: nowrap;
`

const RightColumn = styled.div`
  display: flex;
  gap: 16px;
`

const DesignedIconButton = styled(IconButton)<{ filterApply: boolean }>`
  min-width: 126px;
  flex-shrink: 0;

  ${({ filterApply }) =>
    filterApply
      ? `background-color: ${colors.contentOrange.orange6};
          &:hover {
          background-color: ${colors.contentOrange.orange5}
        }`
      : null};
`

const Buttons = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`
const HelpText = styled.div`
  text-align: left;
  font-weight: 400;
`
