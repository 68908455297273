import * as React from 'react'
import styled from 'styled-components'
import { colors, layout } from '../../styleConstants'
import { NormalButton } from '../common/Button'

interface Props {
  readonly loading: boolean
}

export function Welcome({ loading }: Props) {
  const [open, setOpen] = React.useState(true)
  if (!open) return null
  return (
    <Backdrop>
      <ModalWrapper>
        <Container>
          <Label>CONTENT ANALYTICSへ</Label>
          <Label>ようこそ！</Label>
          <div style={{ margin: '2rem auto 1rem', fontSize: '0.9rem' }}>
            設定を完了して、ページレポートを表示してみましょう！
          </div>
          <NormalButton
            disabled={loading}
            onClick={() => {
              setOpen(false)
            }}
          >
            設定を始める
          </NormalButton>
        </Container>
      </ModalWrapper>
    </Backdrop>
  )
}

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: ${layout.welcomePopupZIndex};
`
const ModalWrapper = styled.div`
  position: relative;
  width: 500px;
`

const Container = styled.div`
  background-color: ${colors.white};
  padding: 24px 32px 32px;
  border-radius: 0 0 2px 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Label = styled.div`
  color: ${colors.lightBlue};
  font-size: 2rem;
  font-weight: ${layout.boldFontWeight};
  margin-bottom: 0.5rem;
`
