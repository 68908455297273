import * as React from 'react'
import { Project } from './project'
import { Goal, INITIAL_GOAL } from './goal'
import { Query, QueryStep } from './query'
import { Member, MemberStep } from './member'
import { Dimension } from './dimension'
import { IpAddress, IpAddressStep } from './ipAddress'
import { CrossDomain, CrossDomainStep } from './crossDomain'
import { SiteResponsive, SiteResponsiveStep } from './siteResponsive'
import { Invite, InviteStep } from './invite'
import { request } from '../../util/request'
import { Role } from '../../util/Authority'
import {
  ProjectData,
  MembersData,
  UrlParametersData,
  AdminsData,
  CustomDimensionsData,
  IpaddressFiltersData,
  CrossDomainsData,
  SiteResponsiveData,
  MembersInviteData,
} from '../../util/Response'
import { GoalType, Condition, MatchMap } from '../../util/Goal'
import { useToast } from '../../util/hooks/useToast'
import { GoalState } from '../../components/list/GoalList'
import { QueryState } from '../../components/list/QueryList'
import { MemberState, AdminState } from '../../components/list/MemberList'
import { DimensionState } from '../../components/list/DimensionList'
import { IpAddressState } from '../../components/list/IpAddressList'
import { CrossDomainState } from '../../components/list/CrossDomainList'
import { UserState } from '../../components/list/UserList'
import { GoalsData } from '../../util/hooks/api/Goal'

const CATEGORY_TITLE = [
  { category: 'project', title: 'プロジェクト情報' },
  { category: 'trackingcode', title: 'プロジェクト情報' },
  { category: 'goal', title: 'ゴール' },
  { category: 'ip_address', title: 'IPアドレスフィルター' },
  { category: 'site_responsive', title: 'PC・Mobile振り分け' },
  { category: 'query', title: 'クエリーパラメータ' },
  { category: 'cross_domain', title: 'クロスドメイン' },
  { category: 'dimension', title: 'カスタムディメンション' },
  { category: 'member', title: 'メンバー' },
  { category: 'invite', title: 'メンバー' },
] as const

interface PageContextType {
  readonly state: State
  readonly pageActions: Actions
}

export interface State {
  readonly loading: boolean
  readonly reload: boolean

  // プロジェクト情報
  readonly project: ProjectSettingsType

  // ゴール情報
  readonly goal: {
    readonly goals: GoalState[]
    readonly selectedId: number
    readonly type: number
    readonly name: string
    readonly condition: number
    readonly url: string
    readonly eventSelected: boolean[]
    readonly eventCondition: (number | null)[]
    readonly eventValue: string[]
    readonly autoSendPurchaseEnabled: boolean
    readonly estimatedRevenueEnabled: boolean
    readonly estimatedRevenueAmount?: number
    readonly step: number
    readonly deleteId: number
    readonly deleteName: string
    readonly goalOptions: { label: string; value: number }[]
  }

  // クエリーパラメータ
  readonly query: {
    readonly queries: QueryState[]
    readonly selectedId: number
    readonly baseQuery: string
    readonly editQuery: string
    readonly disabled: boolean
    readonly step: number // 処理ステップ
  }

  // トラッキングコード
  readonly trackingCode: string

  // メンバー情報
  readonly member: {
    readonly members: MemberState[]
    readonly selectedId: number
    readonly selectedName: string
    readonly selectedRole: number
    readonly email: string
    readonly addDisabled: boolean
    readonly editDisabled: boolean
    readonly step: number // 処理ステップ
    readonly inviteMemberRole: number
    readonly inviteDisabled: boolean
    readonly isSearch: boolean // 検索状態
    readonly inviteMessage: string
  }

  // 組織管理者一覧
  readonly admins: AdminState[]

  // カスタムディメンション
  readonly dimension: {
    readonly dimensions: DimensionState[]
    readonly opened: boolean
    readonly index: number
    readonly name: string
    readonly scope: number
    readonly querySourced: boolean
    readonly activated: boolean
    readonly remained: number
    readonly disabled: boolean
    readonly created: boolean
    readonly editId: number
    readonly isConfirm: boolean
    readonly message: string
  }

  // IPアドレスフィルター
  readonly ipAddress: {
    readonly selectedId: number
    readonly disabled: boolean
    readonly name: string
    readonly condition: number
    readonly ipAddress: string
    readonly baseName: string
    readonly baseCondition: number
    readonly baseIpAddress: string
    readonly ipAddresses: IpAddressState[]
    readonly step: number // 処理ステップ
    readonly isNameValid: boolean
    readonly isConditionValid: boolean
    readonly isIpAddressValid: boolean
  }

  // クロスドメイン
  readonly crossDomain: {
    readonly crossDomains: CrossDomainState[]
    readonly selectedId: number
    readonly disabled: boolean
    readonly name: string
    readonly subdomain: boolean
    readonly baseName: string
    readonly baseSubdomain: boolean
    readonly step: number
    readonly isDomainValid: boolean
    readonly isSubdomainValid: boolean
  }

  // サイトレスポンシブ
  readonly siteResponsive: {
    readonly isUserAgent: boolean
    readonly mobileMaxWidth: number | string
    readonly tabletMinWidth: number | string
    readonly tabletMaxWidth: number | string
    readonly pcMinWidth: number | string

    // リセット可能かを調べる用
    readonly baseUserAgent: boolean
    readonly baseMobileMaxWidth: number | string
    readonly basePcMinWidth: number | string

    readonly disabled: boolean
    readonly isReset: boolean
    readonly step: number
  }

  // 招待ユーザー
  readonly invite: {
    readonly users: UserState[]
    readonly selectedId: number
    readonly selectedEmail: string
    readonly step: number // 処理ステップ
  }

  // 汎用
  readonly projectErrorMessage?: string
  readonly goalErrorMessage?: string
  readonly queryErrorMessage?: string
  readonly memberErrorMessage?: string
  readonly dimensionErrorMessage?: string
  readonly ipAddressErrorMessage?: string
  readonly crossDomainErrorMessage?: string
  readonly siteResponsiveErrorMessage?: string
  readonly inviteErrorMessage?: string
  readonly modalErrorMessage?: string
  readonly errorMessage?: string
  readonly toastMessage: string
}

export interface ProjectSettingsType {
  readonly id: number
  readonly name: string
  readonly url: string
  readonly baseName: string // 比較用
  readonly baseUrl: string // 比較用
  readonly nameDisabled: boolean
  readonly urlDisabled: boolean
  readonly disabled: boolean
  readonly updated: boolean
}

export class Actions {
  project: Project
  goal: Goal
  query: Query
  member: Member
  dimension: Dimension
  ipAddress: IpAddress
  crossDomain: CrossDomain
  siteResponsive: SiteResponsive
  invite: Invite

  constructor(
    private readonly state: State,
    private readonly setState: React.Dispatch<React.SetStateAction<State>>,
    private readonly openToast: ({ message }: { message: string }) => void,
  ) {
    this.project = new Project(state, setState, openToast)
    this.goal = new Goal(state, setState)
    this.query = new Query(state, setState)
    this.member = new Member(state, setState)
    this.dimension = new Dimension(state, setState)
    this.ipAddress = new IpAddress(state, setState)
    this.crossDomain = new CrossDomain(state, setState)
    this.siteResponsive = new SiteResponsive(state, setState)
    this.invite = new Invite(state, setState)
  }

  fetch = async (id: string) => {
    try {
      const project: ProjectData = await request('GET', `/api/projects/${id}/`, true)
      const goals: GoalsData = await request('GET', `/api/projects/${id}/goals/`, true)
      const members: MembersData = await request('GET', `/api/projects/${id}/members/`, true)
      const urlParams: UrlParametersData = await request('GET', `/api/projects/${id}/url_parameters/`, true)
      const admins: AdminsData = await request('GET', `/api/admins/`, true)
      const dimensions: CustomDimensionsData = await request('GET', `/api/projects/${id}/custom_dimensions/`, true)
      const ipAddresses: IpaddressFiltersData = await request('GET', `/api/projects/${id}/ipaddress_filters/`, true)
      const crossDomains: CrossDomainsData = await request('GET', `/api/projects/${id}/cross_domains/`, true)
      const siteResponsive: SiteResponsiveData = await request('GET', `/api/projects/${id}/site_responsive/`, true)
      const inviteUsers: MembersInviteData = await request('GET', `/api/projects/${id}/members/invite/`, true)

      // タブレットの幅を計算
      const { minWidth, maxWidth } = this.siteResponsive.getTabletWidth(
        siteResponsive.mobile_max_width,
        siteResponsive.pc_min_width,
      )
      this.state.toastMessage && this.openToast({ message: this.state.toastMessage })

      this.setState({
        ...this.state,
        loading: false,
        reload: false,
        project: {
          ...this.state.project,
          id: project.id,
          name: project.name,
          url: project.url,
          baseName: project.name,
          baseUrl: project.url,
        },
        goal: {
          ...this.state.goal,
          goals:
            goals.results.length === 0
              ? []
              : goals.results.map((goal) => {
                  const estimatedRevenue = goal.estimated_revenue ?? undefined

                  if (goal.goal_type === GoalType.url) {
                    return {
                      id: goal.id,
                      name: goal.name,
                      type: goal.goal_type,
                      url: goal.goal_url
                        ? {
                            condition: goal.goal_url.condition,
                            url: goal.goal_url.url,
                          }
                        : undefined,
                      estimatedRevenue: estimatedRevenue,
                    }
                  }
                  if (goal.goal_type === GoalType.purchase) {
                    return {
                      id: goal.id,
                      name: goal.name,
                      type: goal.goal_type,
                      purchase: goal.purchase
                        ? {
                            autoSendPurchaseEnabled: goal.purchase.auto_send_purchase_enabled,
                          }
                        : undefined,
                    }
                  }
                  return {
                    id: goal.id,
                    name: goal.name,
                    type: goal.goal_type,
                    event: goal.goal_event
                      ? {
                          category: goal.goal_event.category,
                          action: goal.goal_event.action,
                          label: goal.goal_event.label,
                          value: goal.goal_event.value === null ? 0 : goal.goal_event.value,
                          category_condition: goal.goal_event.category_condition,
                          action_condition: goal.goal_event.action_condition,
                          label_condition: goal.goal_event.label_condition,
                          value_condition: goal.goal_event.value_condition,
                        }
                      : undefined,
                    estimatedRevenue: estimatedRevenue,
                  }
                }),
        },
        query: {
          ...this.state.query,
          queries:
            urlParams.results.length === 0
              ? []
              : urlParams.results.map((param) => {
                  return {
                    id: param.id,
                    name: param.name,
                  }
                }),
        },
        trackingCode: project.tag,
        member: {
          ...this.state.member,
          members:
            members.results.length === 0
              ? []
              : members.results.map((member) => {
                  return {
                    id: member.id,
                    userName: member.user.username,
                    email: member.user.email,
                    role: member.role,
                    roleName: member.role_name,
                  }
                }),
        },
        admins:
          admins.results.length === 0
            ? []
            : admins.results.map((admin) => {
                return {
                  id: admin.id,
                  name: admin.username,
                  email: admin.email,
                }
              }),
        dimension: {
          ...this.state.dimension,
          dimensions:
            dimensions.results.length === 0
              ? []
              : dimensions.results.map((dimension) => {
                  return {
                    id: dimension.id,
                    index: dimension.index,
                    name: dimension.name,
                    scope: dimension.scope,
                    querySourced: dimension.query_sourced,
                    activated: dimension.activated,
                  }
                }),
          remained: dimensions.remained_registrations,
        },
        ipAddress: {
          ...this.state.ipAddress,
          ipAddresses:
            ipAddresses.results.length === 0
              ? []
              : ipAddresses.results.map((ipaddress) => {
                  return {
                    id: ipaddress.id,
                    name: ipaddress.name,
                    condition: ipaddress.match_type,
                    ipAddress: ipaddress.ipaddress,
                  }
                }),
        },
        crossDomain: {
          ...this.state.crossDomain,
          crossDomains:
            crossDomains.results.length === 0
              ? []
              : crossDomains.results.map((crossDomain) => {
                  return {
                    id: crossDomain.id,
                    name: crossDomain.name,
                    allow_subdomain: crossDomain.allow_subdomain,
                    updatedAt: crossDomain.updated_at,
                    createdAt: crossDomain.created_at,
                  }
                }),
        },
        siteResponsive: {
          ...this.state.siteResponsive,
          isUserAgent: siteResponsive.mobile_max_width === null && siteResponsive.pc_min_width === null,
          mobileMaxWidth: siteResponsive.mobile_max_width === null ? '' : siteResponsive.mobile_max_width,
          tabletMinWidth: minWidth,
          tabletMaxWidth: maxWidth,
          pcMinWidth: siteResponsive.pc_min_width === null ? '' : siteResponsive.pc_min_width,
          baseUserAgent: siteResponsive.mobile_max_width === null && siteResponsive.pc_min_width === null,
          baseMobileMaxWidth: siteResponsive.mobile_max_width === null ? '' : siteResponsive.mobile_max_width,
          basePcMinWidth: siteResponsive.pc_min_width === null ? '' : siteResponsive.pc_min_width,
          disabled: true,
          isReset: false,
        },
        invite: {
          ...this.state.invite,
          users: inviteUsers.results.map((result) => {
            return {
              id: result.id,
              userName: '',
              email: result.email,
              role: result.role,
              roleName: '',
            }
          }),
        },
        errorMessage: '',
        projectErrorMessage: '',
        goalErrorMessage: '',
        queryErrorMessage: '',
        memberErrorMessage: '',
        siteResponsiveErrorMessage: '',
        toastMessage: '',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        loading: false,
        errorMessage: typeof e === 'string' ? e : 'プロジェクト情報の取得に失敗しました。',
      })
    }
  }

  // 日時の文字列をラベル用に変換
  changeTimeString = (str: string) => {
    return str.slice(0, 4) + '/' + str.slice(4)
  }

  getCategoryTitle = (categoryStr: string) => {
    return CATEGORY_TITLE.find(({ category }) => category === categoryStr)?.title || ''
  }

  // スナックバーリセット
  resetState = () => {
    this.setState({
      ...this.state,
      project: {
        ...this.state.project,
        updated: false,
      },
    })
  }
}

const initialState: State = {
  loading: true,
  reload: false,
  project: {
    id: 0,
    name: '',
    url: '',
    baseName: '',
    baseUrl: '',
    nameDisabled: true,
    urlDisabled: true,
    disabled: true,
    updated: false,
  },
  goal: {
    ...INITIAL_GOAL,
    goals: [],
    deleteId: 0,
    deleteName: '',
    goalOptions: [
      { label: MatchMap[Condition.perfect], value: Condition.perfect },
      { label: MatchMap[Condition.head], value: Condition.head },
      { label: MatchMap[Condition.regexp], value: Condition.regexp },
    ],
  },
  query: {
    queries: [],
    selectedId: 0,
    baseQuery: '',
    editQuery: '',
    disabled: true,
    step: QueryStep.none,
  },
  trackingCode: '',
  member: {
    members: [],
    selectedId: 0,
    selectedName: '',
    selectedRole: Role.Project.none,
    email: '',
    addDisabled: true,
    editDisabled: true,
    step: MemberStep.none,
    inviteMemberRole: Role.Project.member,
    inviteDisabled: true,
    isSearch: false,
    inviteMessage: '',
  },
  admins: [],
  dimension: {
    dimensions: [],
    opened: false,
    index: 0,
    name: '',
    scope: 1,
    querySourced: false,
    activated: true,
    remained: 0,
    disabled: true,
    created: true,
    editId: 0,
    isConfirm: false,
    message: '',
  },
  ipAddress: {
    selectedId: 0,
    disabled: true,
    name: '',
    condition: 0,
    ipAddress: '',
    baseName: '',
    baseCondition: 0,
    baseIpAddress: '',
    ipAddresses: [],
    step: IpAddressStep.none,
    isNameValid: false,
    isConditionValid: false,
    isIpAddressValid: false,
  },
  crossDomain: {
    crossDomains: [],
    selectedId: 0,
    disabled: true,
    name: '',
    subdomain: false,
    baseName: '',
    baseSubdomain: false,
    step: CrossDomainStep.none,
    isDomainValid: false,
    isSubdomainValid: false,
  },
  siteResponsive: {
    isUserAgent: true,
    mobileMaxWidth: '',
    tabletMinWidth: '',
    tabletMaxWidth: '',
    pcMinWidth: '',
    baseUserAgent: false,
    baseMobileMaxWidth: '',
    basePcMinWidth: '',
    disabled: true,
    isReset: false,
    step: SiteResponsiveStep.none,
  },
  invite: {
    users: [],
    selectedId: 0,
    selectedEmail: '',
    step: InviteStep.none,
  },
  projectErrorMessage: '',
  goalErrorMessage: '',
  memberErrorMessage: '',
  crossDomainErrorMessage: '',
  siteResponsiveErrorMessage: '',
  inviteErrorMessage: '',
  modalErrorMessage: '',
  toastMessage: '',
}

export function usePageState(): PageContextType {
  const [state, setState] = React.useState<State>(initialState)
  const { openToast } = useToast()
  const pageActions = new Actions(state, setState, openToast)
  return { state, pageActions }
}
