import * as React from 'react'
import styled from 'styled-components'
import { colors } from '../../styleConstants'
import { Modal, ModalButtonWrapper, ModalContentWrapper } from '../../components/common/Modal'
import { AddButton, CancelButton, NormalButton } from '../../components/common/Button'
import { Select } from '../../components/common/Select'
import { Input } from '../../components/common/Input'
import { Close as CloseIcon } from '@styled-icons/evaicons-solid/Close'
import { InputErrorMessage } from '../../components/errors/InputErrorMessage'
import { SearchOption } from '../../util/hooks/api/PageReport/types'
import { SEARCH_OPTIONS } from '../../util/hooks/api/PageReport/constants'
import { PageReportContext } from '../../contexts/PageReportContext'
import { deepCopy } from '../../util/copy'

const LAYOUT = {
  INNER_HEIGHT: '240px',
  ITEM_HEIGHT: '40px',
}

const selectOptions1 = [
  { label: '一致', value: SEARCH_OPTIONS.INCLUDED_TYPES.match },
  { label: '除外', value: SEARCH_OPTIONS.INCLUDED_TYPES.exclusion },
]

const selectOptions2 = [
  { label: 'URL', value: SEARCH_OPTIONS.NAMES.url },
  { label: 'タイトル', value: SEARCH_OPTIONS.NAMES.title },
]

const selectOptions3 = [
  { label: '完全一致', value: SEARCH_OPTIONS.MATCH_TYPES.full },
  { label: '先頭一致', value: SEARCH_OPTIONS.MATCH_TYPES.head },
  { label: '部分一致', value: SEARCH_OPTIONS.MATCH_TYPES.partial },
  { label: '正規表現一致', value: SEARCH_OPTIONS.MATCH_TYPES.regexp },
]

const SEARCH_OPTION_INITIAL_ITEM = {
  condition: [SEARCH_OPTIONS.INCLUDED_TYPES.match, SEARCH_OPTIONS.NAMES.url, SEARCH_OPTIONS.MATCH_TYPES.full],
  searchValue: '',
}

interface Props {
  readonly onClose: () => void
  readonly resetPaginate: () => void
  readonly clearSearchText: () => void
}

export function SearchOptionModal({ onClose, resetPaginate, clearSearchText }: Props) {
  const { searchOptions, setSearchOptions } = React.useContext(PageReportContext)
  const [searchOptionsTemp, setSearchOptionsTemp] = React.useState<SearchOption[]>(searchOptions)
  const [disabled, setDisabled] = React.useState(false)

  const handleOptionSelect = (event: React.ChangeEvent<HTMLSelectElement>, index: number, condition: number) => {
    const newOptions = deepCopy(searchOptionsTemp)
    newOptions[index].condition[condition] = Number(event.currentTarget.value)
    setSearchOptionsTemp(newOptions)
  }

  const handleInputChange = (event: React.FormEvent<HTMLInputElement>, index: number) => {
    const newOptions = deepCopy(searchOptionsTemp)
    newOptions[index].searchValue = event.currentTarget.value
    setSearchOptionsTemp(newOptions)
    setDisabled(isDisabledSearchOptions(newOptions))
  }

  const handleAdd = () => {
    const newOptions = [...searchOptionsTemp, SEARCH_OPTION_INITIAL_ITEM]
    setSearchOptionsTemp(newOptions)
    setDisabled(isDisabledSearchOptions(newOptions))
  }

  const handleDelete = (index: number) => {
    const newOptions = searchOptionsTemp.filter((_, i) => i !== index)
    setSearchOptionsTemp(newOptions)
    setDisabled(isDisabledSearchOptions(newOptions))
  }

  const handleApply = () => {
    const newOptions = searchOptionsTemp.map((obj) => {
      return { ...obj, error: undefined }
    })
    setSearchOptions(newOptions)
    resetPaginate()
    onClose()
    // 高度な絞り込み実行時には入力フォームはクリアする
    clearSearchText()
  }

  const handleReset = () => {
    setSearchOptions([])
    resetPaginate()
    onClose()
  }

  const element = searchOptionsTemp.map((item, index) => {
    return (
      <form data-id={index} key={`content-${index}`} onSubmit={(e) => e.preventDefault()}>
        <Content>
          <DesignedSelect
            defaultValue={item.condition[0]}
            options={selectOptions1}
            onChange={(e) => handleOptionSelect(e, index, 0)}
          />
          <DesignedSelect
            defaultValue={item.condition[1]}
            options={selectOptions2}
            onChange={(e) => handleOptionSelect(e, index, 1)}
          />
          <DesignedSelect
            defaultValue={item.condition[2]}
            options={selectOptions3}
            onChange={(e) => handleOptionSelect(e, index, 2)}
          />
          <SearchValue>
            <DesignedInput
              type="text"
              value={item.searchValue}
              onChange={(e) => handleInputChange(e, index)}
              look={!!item.error?.value ? 'red' : undefined}
            />
            {item.error?.value?.map((message, index) => (
              <InputErrorMessage key={index}>{message}</InputErrorMessage>
            ))}
          </SearchValue>
          <CloseButtonWrapper>
            <CloseButton onClick={() => handleDelete(index)} />
          </CloseButtonWrapper>
        </Content>
        {searchOptionsTemp.length > 0 && searchOptionsTemp.length !== index + 1 && <AndLabel>AND</AndLabel>}
      </form>
    )
  })
  return (
    <Modal isOpen={true} onClose={onClose} title="検索オプション" width={900}>
      <ModalContentWrapper>
        {searchOptionsTemp.length > 0 && (
          <OptionBox>
            <CancelButton onClick={handleReset}>リセット</CancelButton>
          </OptionBox>
        )}
        <ScrollArea>
          {element}
          <div style={{ marginTop: '1rem' }}>
            <AddButton onClick={handleAdd}>条件を追加</AddButton>
          </div>
        </ScrollArea>
      </ModalContentWrapper>
      <ModalButtonWrapper>
        <CancelButton onClick={onClose}>キャンセル</CancelButton>
        <NormalButton disabled={disabled} onClick={handleApply}>
          検索
        </NormalButton>
      </ModalButtonWrapper>
    </Modal>
  )
}

function isDisabledSearchOptions(state: SearchOption[]) {
  if (state.length === 0) {
    return true
  }

  for (let i = 0; i < state.length; ++i) {
    if (state[i].searchValue === '') {
      return true
    }
  }
  return false
}

const OptionBox = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
`

const ScrollArea = styled.div`
  display: flex;
  width: 100%;
  max-height: ${LAYOUT.INNER_HEIGHT};
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
`

const Content = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
`

const AndLabel = styled.div`
  margin: 0.5rem 0;
  font-size: 0.8rem;
  font-weight: 600;
`

const DesignedSelect = styled(Select)`
  height: ${LAYOUT.ITEM_HEIGHT};
  font-size: 0.8rem;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
`

const DesignedInput = styled(Input)`
  width: 100%;
  height: ${LAYOUT.ITEM_HEIGHT};
`

const SearchValue = styled.div`
  width: 100%;
`

const CloseButton = styled(CloseIcon).attrs({
  size: 28,
  color: `${colors.gray500}`,
})`
  cursor: pointer;
`

const CloseButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
`
