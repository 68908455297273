import * as React from 'react'

import { useScopeType } from '../../useScopeType'
import { request } from '../../../request'
import { UseMutationResult, useMutation } from '@tanstack/react-query'
import { useDeviceType } from '../../useDeviceType'
import { useFilterContentEventsExists } from '../../cookie/useFilterContentEventsExists'
import { useGoalId } from '../../useGoalId'
import { downloadBlobFile } from '../../../downloadUtils'
import { makeTimelineRequestBody } from './useTimelineReport'
import { ReportContext } from '../../../../contexts/ReportProvider'
import { CustomFilterContext } from '../../../../contexts/CustomFilterContext'
import { useTimelineAggregationDateType } from '../../cookie/useTimelineAggregationDateType'
import { PageReportContext } from '../../../../contexts/PageReportContext'

interface TimelineDownloadResponse {
  readonly download_url: string
}

interface Props {
  projectId: string
}

/**
 * 時系列グラフデータのCSVダウンロードAPIのhook
 *
 * @param {Object} props - The props object containing the necessary parameters.
 * @param {number} props.projectId - The ID of the project.
 * @returns {UseMutationResult} - The mutation result object.
 */
export const usePageReportTimelineCsvDownload = ({
  projectId,
}: Props): UseMutationResult<void, unknown, void, unknown> => {
  const {
    state: { calenderState },
  } = React.useContext(ReportContext)
  const {
    state: { customFilterState },
  } = React.useContext(CustomFilterContext)
  const { searchText, searchOptions } = React.useContext(PageReportContext)

  const { scopeType } = useScopeType()
  const { deviceType } = useDeviceType()
  const { goalId } = useGoalId({ projectId: Number(projectId) })
  const { filterContentEventsExists } = useFilterContentEventsExists()
  const { timelineAggregationDateType: aggregationDateType } = useTimelineAggregationDateType()

  return useMutation({
    mutationFn: async () => {
      const requestBody = makeTimelineRequestBody(
        aggregationDateType,
        scopeType,
        deviceType,
        goalId,
        calenderState,
        searchText,
        searchOptions,
        customFilterState,
        filterContentEventsExists,
      )
      const res = await request<TimelineDownloadResponse>(
        'POST',
        `/api/projects/${projectId}/timeline_report/download/`,
        true,
        JSON.stringify(requestBody),
      )
      await downloadBlobFile({
        downloadUrl: res.download_url,
        defaultFilename: 'contentanalytics_timeline_report.csv',
      })
    },
  })
}
