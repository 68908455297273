import React, { ReactElement } from 'react'
import styled from 'styled-components'

import { Select } from '../../../common/Select'
import { colors } from '../../../../styleConstants'
import { setSearchParamsGoalId, useGoalId } from '../../../../util/hooks/useGoalId'
import { ReportContext } from '../../../../contexts/ReportProvider'

interface Props {
  readonly projectId: number
  readonly onGoalChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

/**
 * ゴール選択コンポーネントをレンダリングする
 *
 * @param {Object} props - The component props.
 * @param {number} props.projectId - The project ID.
 * @param {Function} props.onGoalChange - ゴール変更時に各ページ固有の処理を実行するコールバック関数
 * @returns {ReactElement} - The rendered goal selection component.
 */
export const GoalSelect = ({ projectId, onGoalChange }: Props): ReactElement | null => {
  const {
    state: { goalOptions },
  } = React.useContext(ReportContext)

  if (!goalOptions.length) return null

  const { goalId, setGoalId } = useGoalId({ projectId: projectId })

  /**
   * ゴール変更時に各ページ共通の処理を実行するhandler関数
   * propsでコールバック関数（onGoalChange）が渡された場合は、合わせて実行する
   *
   * @param {React.ChangeEvent<HTMLSelectElement>} e - The event object representing the change event.
   * @returns {void}
   */
  const handleGoalChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const newGoalId = Number(e.currentTarget.value)
    setGoalId(newGoalId)
    setSearchParamsGoalId(newGoalId)
    if (onGoalChange) onGoalChange(e)
  }

  return (
    <Container>
      <StyledLabel htmlFor="goal-select">ゴール:</StyledLabel>
      <StyledSelect id="goal-select" options={goalOptions} value={goalId} onChange={handleGoalChange} />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  & span {
    flex-shrink: 0;
  }
`

const StyledSelect = styled(Select)`
  width: 180px;
  padding: 0 4px;
  height: 30px;
  color: ${colors.black};
  border: 1px solid ${colors.datePickerBorderWhite};
  border-radius: 0;
  box-sizing: content-box;
  outline: none;
`

const StyledLabel = styled.label`
  font-weight: bold;
  color: ${colors.white};
`
