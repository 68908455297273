import * as React from 'react'
import { ReactElement } from 'react'
import styled from 'styled-components'

import { colors } from '../../../../styleConstants'
import { DayPickerRange } from '../../DayPickerRange'
import { HeaderScopeTypeButtons } from '../../HeaderScopeTypeButtons'
import { GoalSelect } from '../../../report/filters/GoalSelect'
import { useReportSettings } from '../../../../util/hooks/useReportSettings'
import { ProjectContext } from '../../../../pages/ProjectRoot'
import { GlobalContext } from '../../../../GlobalState'

interface Props {
  readonly goalHidden?: boolean
  readonly onDateRangeApply?: () => void
  readonly onGoalChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
  readonly onScopeTypeChange?: () => void
}

/**
 * 集計オプションコンポーネントをレンダリングする
 *
 * @param {Props} props - The props object.
 * @param {boolean} props.goalHidden - ゴール選択ボタンの非表示フラグ
 * @param {Function} props.onDateRangeApply - カレンダー範囲の適用時に呼び出される関数
 * @param {Function} props.onGoalChange - ゴールが変更されたときに呼び出される関数
 * @param {Function} props.onScopeTypeChange - 集計範囲が変更されたときに呼び出される関数
 *
 * @return {ReactElement | null} コンポーネントを返す（ただし、レポート閲覧準備ができてない場合はnullを返す）
 */
export function AggregationOption({
  goalHidden,
  onDateRangeApply,
  onGoalChange,
  onScopeTypeChange,
}: Props): ReactElement | null {
  const {
    state: { PlanInfo },
  } = React.useContext(GlobalContext)

  const {
    state: { projectId },
  } = React.useContext(ProjectContext)

  const { isCompleted } = useReportSettings({
    projectId: projectId,
  })
  if (!isCompleted) return null

  return (
    <Container>
      <DayPickerRange dayLimit={PlanInfo.reportDaysLimit} onApply={onDateRangeApply!} />
      {!goalHidden && <GoalSelect projectId={projectId} onGoalChange={onGoalChange} />}
      <ButtonArea>{projectId && <HeaderScopeTypeButtons onScopeTypeChange={onScopeTypeChange} />}</ButtonArea>
    </Container>
  )
}
const Container = styled.div`
  display: flex;
  margin-right: 16px;
  align-items: center;
  flex-shrink: 0;
  gap: 16px;
`

const ButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  border-left: 1px solid ${colors.white};
`
