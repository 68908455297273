import * as React from 'react'
import styled, { css } from 'styled-components'
import { colors } from '../../../styleConstants'
import { DIMENSION_IDS, DIMENSION_LABELS } from '../../../util/hooks/api/TrafficReport/constants'
import { DimensionId } from '../../../util/hooks/api/TrafficReport/types'
import { NormalButton, CancelButtonWithBorder } from '../../../components/common/Button'
import {
  setSearchParamsDimensionChoices,
  useTrafficReportDimensionChoices,
} from '../../../util/hooks/cookie/useTrafficReportDimensionChoices'
import { InteractiveTooltip } from '../../../components/Tooltip'
import { getDimensionBackgroundColorByOrder, getDimensionBorderByOrder } from '../utils'
import { HeaderCellHelp } from '../../../components/ContentReport/cells/HeaderCellHelp'
import { HELP_TEXTS } from '../../../constants'
import { TrafficReportContext } from '../../../contexts/TrafficReportContext'

const DIMENSION_HELP_CONTENTS: Record<DimensionId, HelpContent> = {
  [DIMENSION_IDS.CHANNEL]: {
    text: HELP_TEXTS.DIMENSION_CHANNEL_NAME,
    width: 400,
  },
  [DIMENSION_IDS.SOURCE]: {
    text: HELP_TEXTS.DIMENSION_FIRST_TRAFFIC_SOURCE,
    width: 300,
  },
  [DIMENSION_IDS.MEDIUM]: {
    text: HELP_TEXTS.DIMENSION_FIRST_TRAFFIC_MEDIUM,
    width: 300,
  },
  [DIMENSION_IDS.CAMPAIGN]: {
    text: HELP_TEXTS.DIMENSION_FIRST_TRAFFIC_CAMPAIGN,
    width: 300,
  },
} as const

const sortChoicedDimensionsByFirst = (dimensionChoices: DimensionId[]) => {
  const unChoicedDimensions = Object.values(DIMENSION_IDS).filter((id) => !dimensionChoices.includes(id))
  return [...dimensionChoices, ...unChoicedDimensions]
}

interface HelpContent {
  text: string
  width: number
}

/**
 * トラフィックレポートのディメンション選択チェックボックス
 *
 * @remarks
 * - チェックボックスをクリックし適用ボタンを押すことでディメンション（チャネル、参照元、メディア、キャンペーン）の表示・非表示を切り替えることができます
 * - 選択状態はCookieに保存され、ページをリロードしても維持されます
 * - 選択した順番は配列として保存されます。
 *
 * @returns {JSX.Element} ディメンション選択用のチェックボックスグループ
 */
export const DimensionCheckboxes = () => {
  const { resetPagerIndex } = React.useContext(TrafficReportContext)

  const { dimensionChoices, setDimensionChoices } = useTrafficReportDimensionChoices()
  const [tempChoices, setTempChoices] = React.useState<DimensionId[]>(dimensionChoices)

  const sortedDimensions = sortChoicedDimensionsByFirst(dimensionChoices)

  const hasChanges =
    dimensionChoices.length !== tempChoices.length || !dimensionChoices.every((dim, i) => tempChoices[i] === dim)
  const hasChoices = tempChoices.length > 0

  const handleClickChoice = (clickedDimension: DimensionId) => {
    // すでに選択されている場合は削除する
    if (tempChoices.includes(clickedDimension)) {
      const removedChoices = tempChoices.filter((dimensionId) => dimensionId !== clickedDimension)
      setTempChoices(removedChoices)
      return
    }
    // 新しい選択を追加
    const addedChoices = [...tempChoices, clickedDimension]
    setTempChoices(addedChoices)
  }

  const handleApply = () => {
    setDimensionChoices(tempChoices)
    setSearchParamsDimensionChoices(tempChoices)
    resetPagerIndex()
  }

  const handleCancel = () => {
    setTempChoices(dimensionChoices)
  }

  return (
    <Container data-testid="dimension-checkboxes">
      <CheckboxesWrapper>
        {sortedDimensions.map((dimensionId) => {
          const isChecked = tempChoices.includes(dimensionId)
          const orderIndex = tempChoices.indexOf(dimensionId)

          return (
            <CheckboxWrapper
              key={dimensionId}
              onClick={() => handleClickChoice(dimensionId)}
              indexOf={orderIndex}
              data-testid={`dimension-checkbox-item`}
            >
              <SelectionIndicator isChecked={isChecked} orderIndex={orderIndex}>
                {isChecked && orderIndex + 1}
              </SelectionIndicator>
              <CheckboxLabel checked={isChecked}>{DIMENSION_LABELS[dimensionId]}</CheckboxLabel>
              <HeaderCellHelp width={DIMENSION_HELP_CONTENTS[dimensionId].width} top={36}>
                {DIMENSION_HELP_CONTENTS[dimensionId].text}
              </HeaderCellHelp>
            </CheckboxWrapper>
          )
        })}
      </CheckboxesWrapper>
      {hasChanges && (
        <ButtonsWrapper>
          <CancelButtonWithBorder onClick={handleCancel}>キャンセル</CancelButtonWithBorder>
          <InteractiveTooltip content={'最低1つは選択する必要があります'} disabled={hasChoices} offset={[15, 5]}>
            <div>
              <NormalButton onClick={handleApply} disabled={!hasChoices}>
                適用
              </NormalButton>
            </div>
          </InteractiveTooltip>
        </ButtonsWrapper>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

const CheckboxesWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 1px; // NOTE: 「適用」ボタンが表示された際に高さが拡張されちらつくのを防ぐため追加
`

interface CheckboxWrapperProps {
  indexOf: number
}

const CheckboxWrapper = styled.div<CheckboxWrapperProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid ${({ indexOf }) => getDimensionBorderByOrder(indexOf)};
  border-radius: 2px;
  padding: 5px 6px;
  background-color: ${({ indexOf }) => getDimensionBackgroundColorByOrder(indexOf)};
  min-width: 136px;
  gap: 4px;
`

interface SelectionIndicatorProps {
  isChecked: boolean
  orderIndex: number
}

const SelectionIndicator = styled.div<SelectionIndicatorProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;

  /* チェックされている場合: 番号付き丸アイコン */
  ${({ isChecked, orderIndex }) =>
    isChecked &&
    css`
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: none;
      color: ${orderIndex === 0 ? colors.white : colors.black2};
      background-color: ${getDimensionBorderByOrder(orderIndex)};
    `}

  /* チェックされていない場合: チェックボックス枠 */
  ${({ isChecked }) =>
    !isChecked &&
    css`
      margin: 3px;
      width: 14px;
      height: 14px;
      border-radius: 2px;
      border: 1px solid ${colors.gray500};
      color: ${colors.gray750};
      background-color: ${colors.white};
    `}
`

interface CheckboxLabelProps {
  checked?: boolean
}

const CheckboxLabel = styled.div<CheckboxLabelProps>`
  font-size: 12px;
  font-weight: ${({ checked }) => (checked ? 'bold' : 'normal')};
  color: ${({ checked }) => (checked ? colors.black : colors.gray750)};
`

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 8px;
`
