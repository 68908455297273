import * as React from 'react'
import { ReactElement } from 'react'
import styled from 'styled-components'

import { NEWS_SITE_URL } from '../../../../constants'
import { colors } from '../../../../styleConstants'
import { useLocation } from '@gatsbyjs/reach-router'
import { NEWS_TYPES, NewsType, useNewsList } from '../../../../util/hooks/api/useNewsList'
import { useNewsVisible } from '../../../../util/hooks/cookie/useNewsVisible'
import { CloseOutline } from '@styled-icons/evaicons-outline/CloseOutline'
import { Notifications } from '@styled-icons/material-outlined/Notifications'
import { getDateStringYMDJa } from '../../../../util/Date'
import { GlobalContext } from '../../../../GlobalState'

const ICON_SIZE = 16

/**
 * お知らせを表示するコンポーネント
 *
 * @return {ReactElement | null} コンポーネントを返す（ただし）
 */
export function NewsNotification(): ReactElement | null {
  const { actions } = React.useContext(GlobalContext)

  const location = useLocation()
  const { data, invalidate } = useNewsList()

  React.useEffect(() => {
    invalidate()
  }, [location.pathname])

  const news = data?.results[0]
  const { isNewsVisible, hideNews } = useNewsVisible(news?.id)

  React.useEffect(() => {
    actions.setNewsVisible(isNewsVisible)
  }, [isNewsVisible])

  const handleClickCloseNews = (): void => {
    hideNews()
  }

  const renderNewsContainer = () => {
    if (!data?.results?.length) {
      return null
    }
    if (!isNewsVisible || !news) {
      return null
    }

    return (
      <NewsContainer>
        <NewsLabel newsType={news.newsType}>{news.newsTypeLabel}</NewsLabel>
        <NewsDate>{getDateStringYMDJa(new Date(news.startTime))}</NewsDate>
        <NewsTitle>
          <DesignedLinkBox href={news.externalLink} target="_blank">
            {news.title}
          </DesignedLinkBox>
        </NewsTitle>
        <NewsLink>
          <DesignedLinkBox href={NEWS_SITE_URL} target="_blank">
            <StyledNotifications size={ICON_SIZE} />
            新着情報・お知らせの一覧
          </DesignedLinkBox>
        </NewsLink>
        <StyledCloseOutline size={ICON_SIZE} onClick={handleClickCloseNews} data-testid="close-news" />
      </NewsContainer>
    )
  }

  return renderNewsContainer()
}

const NewsContainer = styled.header`
  overflow-y: hidden !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: ${colors.lightYellow};
  padding: 7px 16px 7px 24px;
  font-size: 12px;
  line-height: 18px;
  min-width: 600px;
`

const NewsLink = styled.div`
  margin-left: auto;
  padding-right: 10px;
  display: flex;
  white-space: nowrap;
`

const NewsLabel = styled.div<{ newsType: NewsType }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 96px;
  background-color: ${colors.white};
  border: 1px solid ${colors.orange};
  border-radius: 2px;
  margin-right: 10px;
  color: ${colors.orange};
  white-space: nowrap;
  ${({ newsType }) =>
    newsType === NEWS_TYPES.MAINTENANCE &&
    `
      background-color: ${colors.orange};
      border: 1px solid ${colors.orange};
      color: ${colors.white};
      `}
`

const NewsDate = styled.div`
  padding: 0 5px;
  white-space: nowrap;
`

const NewsTitle = styled.div`
  padding: 0 5px;
  white-space: nowrap;
  overflow: hidden;
`

const StyledNotifications = styled(Notifications)`
  color: ${colors.orange};
  margin-right: 3px;
`

const StyledCloseOutline = styled(CloseOutline)`
  color: ${colors.gray750};
  cursor: pointer;
  flex-shrink: 0;
`
const DesignedLinkBox = styled.a`
  color: ${colors.contentOrange.orange7};
  font-weight: bold;
  text-decoration: none;
  cursor: pointer !important;
  align-items: center;
  width: 100%;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  &:hover {
    color: ${colors.orange};
  }
`
