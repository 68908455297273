import * as React from 'react'
import { request } from '../../util/request'
import { PageReportAvailablePageLayoutsData } from '../../util/Response'
import { SORT_ORDER } from '../../util/sortBy'
import { DEVICE_TYPES_FOR_API } from '../../util/hooks/useDeviceType'
import { Regist } from './regist'
import { SORT_ICONS, SortIconState } from '../../components/common/SortButton'
import {
  Content,
  ContentReportData,
  makeErrorMessage,
  SortItemKey,
  SortState,
} from '../../util/hooks/api/useContentReport'
import {
  ContentGroup,
  ContentGroupReportData,
  makeContentGroupErrorMessage,
} from '../../util/hooks/api/useContentGroupReport'
import { CustomFilterState } from '../../util/hooks/api/Filter/types'

interface PageContextType {
  readonly state: State
  readonly actions: Actions
}

export interface OnSelectProps {
  readonly ids: number[]
  readonly shiftKeyPressing?: boolean
  readonly selected?: boolean
}

type AllContentsState = {
  readonly sortState: SortState
  readonly errorMessage: string
}

type ContentGroupState = {
  readonly sortState: SortState
  readonly errorMessage: string
}

export const TAB_INDEX = {
  ALL_CONTENTS: 0,
  CONTENT_GROUP: 1,
} as const
export type TabIndex = typeof TAB_INDEX[keyof typeof TAB_INDEX]

export interface State {
  readonly initialized: boolean
  readonly reload: boolean
  readonly allContentsState: AllContentsState
  readonly contentGroupState: ContentGroupState
  readonly selectedContentIds: number[]
  readonly selectedTabIndex: TabIndex
  readonly contentScrollTo: number | null // スクロール先のコンテンツID
  readonly isScrollTop: boolean
  readonly gridScrollTo: number | null // スクロール先のコンテンツID
  readonly availablePage: boolean
  readonly editingMultipleContents: boolean // コンテンツ名一括編集用パラメータ
  readonly editingMultipleTags: boolean // タグ一括編集用パラメータ

  // コンテンツ登録用パラメータ
  readonly regist: {
    readonly selectedId: number | null
  }
}

export class Actions {
  regist: Regist

  constructor(private readonly state: State, private readonly setState: React.Dispatch<React.SetStateAction<State>>) {
    this.regist = new Regist(state, setState)
  }

  initialize = async (projectId: string, reportId: string) => {
    try {
      const layouts: PageReportAvailablePageLayoutsData = await request(
        'GET',
        `/api/projects/${projectId}/page_reports/${reportId}/available_page_layouts/`,
        true,
      )

      const isMobile = layouts.results.filter((layout) => layout.page_layout === DEVICE_TYPES_FOR_API.mobile).length > 0
      const isPc = layouts.results.filter((layout) => layout.page_layout === DEVICE_TYPES_FOR_API.pc).length > 0
      // 利用できないページ
      if (!isMobile && !isPc) {
        this.setState({
          ...this.state,
          availablePage: false,
        })
        return
      }

      this.setState({
        ...this.state,
        initialized: true,
        selectedContentIds: [],
        availablePage: true,
        allContentsState: {
          ...initialAllContentsState,
          // ソートが指定されていた場合は引き続きセットする
          sortState: this.state.allContentsState.sortState,
        },
        contentGroupState: {
          ...initialContentGroupState,
          // ソートが指定されていた場合は引き続きセットする
          sortState: this.state.contentGroupState.sortState,
        },
      })
    } catch (e) {
      const errorMessage = typeof e === 'string' ? e : 'コンテンツレポートの取得に失敗しました。'

      this.setState({
        ...this.state,
        allContentsState: {
          ...this.state.allContentsState,
          errorMessage,
        },
        contentGroupState: {
          ...this.state.contentGroupState,
          errorMessage,
        },
      })
    }
  }

  refetch = async () => {
    this.setState({
      ...this.state,
      allContentsState: {
        ...initialAllContentsState,
        sortState: this.state.allContentsState.sortState,
        errorMessage: '',
      },
      contentGroupState: {
        ...initialContentGroupState,
        sortState: this.state.contentGroupState.sortState,
        errorMessage: '',
      },
      selectedContentIds: [],
      contentScrollTo: null,
      isScrollTop: false,
      gridScrollTo: null,
    })
  }

  // グリッドでコンテンツのCheckboxを選択
  onItemCheckboxSelected = (content: Content) => {
    const checked = this.state.selectedContentIds.includes(content.id)
    this.setState({
      ...this.state,
      selectedContentIds: checked
        ? this.state.selectedContentIds.filter((prevResource) => prevResource !== content.id)
        : [...this.state.selectedContentIds, content.id],
      contentScrollTo: content.id,
      isScrollTop: false, // TOPへのスクロールは中止
      gridScrollTo: null,
    })
  }

  // 全コンテンツタブのグリッドでコンテンツを選択
  onItemSelected = (content: Content) => {
    this.setState({
      ...this.state,
      selectedContentIds: [content.id],
      contentScrollTo: content.id,
      isScrollTop: false, // TOPへのスクロールは中止
      gridScrollTo: null,
    })
  }

  onContentGroupCellSelected = (contentGroup: ContentGroup) => {
    this.setState({
      ...this.state,
      selectedContentIds: [contentGroup.id],
      contentScrollTo: contentGroup.id,
      isScrollTop: false, // TOPへのスクロールは中止
      gridScrollTo: null,
    })
  }

  onContentGroupChartItemsSelected = (contentGroups: ContentGroup[]) => {
    const firstContentGroup = contentGroups[0]
    this.setState({
      ...this.state,
      selectedContentIds: contentGroups.map((item) => item.id),
      contentScrollTo: firstContentGroup.id,
      isScrollTop: false, // TOPへのスクロールは中止
      gridScrollTo: firstContentGroup.id,
    })
  }

  // ヘッダのCheckboxを選択
  onHeaderCheckboxChange = (contents: Content[]) => {
    this.setState({
      ...this.state,
      selectedContentIds: this.state.selectedContentIds.length === 0 ? contents.map(({ id }) => id) : [],
    })
  }

  onContentGridSort = (key: SortItemKey) => {
    const currentSortState = this.state.allContentsState.sortState
    const sortState = { ...initialSortState }

    const { sortIcon } = makeSortValues(currentSortState[key])
    sortState[key] = sortIcon

    this.setState({
      ...this.state,
      allContentsState: {
        ...this.state.allContentsState,
        sortState,
      },
    })
  }

  onContentGroupGridSort = (key: SortItemKey) => {
    const currentSortState = this.state.contentGroupState.sortState
    const sortState = { ...initialSortState }

    const { sortIcon } = makeSortValues(currentSortState[key])
    sortState[key] = sortIcon

    this.setState({
      ...this.state,
      contentGroupState: {
        ...this.state.contentGroupState,
        sortState,
      },
    })
  }

  onTabChange = (_: React.SyntheticEvent, selectedTabIndex: TabIndex) => {
    this.setState({
      ...this.state,
      selectedTabIndex,
      selectedContentIds: [],
    })
  }

  onSuccessFetchAllContents = (data: ContentReportData, customFilterState: CustomFilterState[]) => {
    this.setState({
      ...this.state,
      reload: false,
      allContentsState: {
        ...this.state.allContentsState,
        errorMessage: makeErrorMessage(data, customFilterState),
      },
      contentScrollTo: null,
      isScrollTop: false,
      gridScrollTo: null,
    })
  }

  onErrorFetchAllContents = () => {
    this.setState({
      ...this.state,
      reload: false,
      allContentsState: {
        ...initialAllContentsState,
        errorMessage: 'コンテンツレポートの取得に失敗しました。',
      },
    })
  }

  onSuccessFetchContentGroup = (data: ContentGroupReportData, customFilterState: CustomFilterState[]) => {
    this.setState({
      ...this.state,
      reload: false,
      contentGroupState: {
        ...this.state.contentGroupState,
        errorMessage: makeContentGroupErrorMessage(data, customFilterState),
      },
      contentScrollTo: null,
      isScrollTop: false,
      gridScrollTo: null,
    })
  }

  onErrorFetchContentGroup = () => {
    this.setState({
      ...this.state,
      reload: false,
      contentGroupState: {
        ...initialContentGroupState,
        errorMessage: 'コンテンツレポートの取得に失敗しました。',
      },
    })
  }

  setErrorMessage = (message: string) => {
    if (this.state.selectedTabIndex === TAB_INDEX.CONTENT_GROUP) {
      this.setState({
        ...this.state,
        contentGroupState: {
          ...this.state.contentGroupState,
          errorMessage: message,
        },
      })
      return
    }

    this.setState({
      ...this.state,
      allContentsState: {
        ...this.state.allContentsState,
        errorMessage: message,
      },
    })
  }

  // ゴールの変更
  onGoalChange = () => {
    this.setState({
      ...this.state,
      reload: true,
    })
  }

  onContentCaptureSelect = (
    { ids, shiftKeyPressing = false, selected = false }: OnSelectProps,
    contents: Content[] | ContentGroup[],
  ) => {
    let items = []
    items = contents.filter((item) => ids.includes(item.id))
    if (items.length) {
      this.setState({
        ...this.state,
        selectedContentIds: ((): number[] => {
          if (shiftKeyPressing) {
            if (selected && ids.length === 1) {
              // クリックされたコンテンツの選択を解除する
              return this.state.selectedContentIds.filter((id) => id !== ids[0])
            } else {
              // 選択されたコンテンツを追加する
              return [...new Set([...this.state.selectedContentIds, ...ids])]
            }
          } else {
            // 選択されたコンテンツのみを選択する
            return ids
          }
        })(),
        contentScrollTo: null,
        gridScrollTo: ids[0],
      })
    } else {
      this.setState({
        ...this.state,
        selectedContentIds: [],
        contentScrollTo: null,
        gridScrollTo: null,
      })
    }
  }

  // グリッドからステータス変更
  onGridStateReset = () => {
    this.setState({ ...this.state, gridScrollTo: null })
  }

  // コンテンツ選択によるスクロール完了通知
  onResetScroll = () => {
    this.setState({ ...this.state, contentScrollTo: null })
  }

  // TOPへスクロールする
  onScrollToTop = () => {
    // コンテンツ選択によるスクロールを優先する
    // スクロールしていない場合にのみ
    if (!this.state.contentScrollTo) {
      this.setState({ ...this.state, isScrollTop: true })
    }
  }

  // TOPへのスクロール完了通知
  onResetScrollTop = () => {
    this.setState({ ...this.state, isScrollTop: false })
  }

  // 表示デバイス切り替え
  changeDevice = () => {
    this.setState({
      ...this.state,
      selectedContentIds: [],
    })
  }

  onEditMultipleContent = () => {
    this.setState({
      ...this.state,
      editingMultipleContents: true,
    })
  }
  onCompleteMultipleContent = () => {
    this.setState({
      ...this.state,
      editingMultipleContents: false,
      contentGroupState: {
        ...this.state.contentGroupState,
      },
    })
  }
  onEditMultipleTags = () => {
    this.setState({
      ...this.state,
      editingMultipleTags: true,
    })
  }
  onCompleteMultipleTags = () => {
    this.setState({
      ...this.state,
      editingMultipleTags: false,
    })
  }
}

export const makeSortValues = (currentSortIcon: SortIconState) => {
  if (currentSortIcon === SORT_ICONS.DOWN) {
    // 降順から昇順に変更
    return {
      order: SORT_ORDER.ASC,
      sortIcon: SORT_ICONS.UP,
    }
  }

  // 昇順・選択なしから降順に変更
  return {
    order: SORT_ORDER.DESC,
    sortIcon: SORT_ICONS.DOWN,
  }
}

const initialSortState: SortState = {
  id: SORT_ICONS.NONE,
  viewCount: SORT_ICONS.NONE,
  viewRate: SORT_ICONS.NONE,
  viewSecond: SORT_ICONS.NONE,
  goalCount: SORT_ICONS.NONE,
  goalRate: SORT_ICONS.NONE,
  clickCount: SORT_ICONS.NONE,
  clickRate: SORT_ICONS.NONE,
} as const

const initialAllContentsState: AllContentsState = {
  sortState: { ...initialSortState },
  errorMessage: '',
}

const initialContentGroupState: ContentGroupState = {
  sortState: { ...initialSortState },
  errorMessage: '',
}

const initialState: State = {
  initialized: false,
  reload: false,
  selectedContentIds: [],
  selectedTabIndex: TAB_INDEX.ALL_CONTENTS,
  allContentsState: { ...initialAllContentsState },
  contentGroupState: { ...initialContentGroupState },
  contentScrollTo: null,
  isScrollTop: false,
  gridScrollTo: null,
  availablePage: false,
  editingMultipleContents: false,
  editingMultipleTags: false,
  regist: {
    selectedId: null,
  },
}

export function usePageState(): PageContextType {
  const [state, setState] = React.useState<State>(initialState)
  const actions = new Actions(state, setState)
  return { state, actions }
}
