import * as React from 'react'
import {
  DIMENSION_STRING_WITH_EMPTY_CONDITIONS,
  DIMENSION_NUMERIC_WITH_EMPTY_CONDITIONS,
  DIMENSION_PAGE_TARGET_COLUMNS,
  DIMENSION_NUMERIC_CONDITIONS,
  CONDITION_TYPES,
  DIMENSION_CHOICE_CONDITIONS,
  DIMENSION_DATE_WITH_EMPTY_CONDITIONS,
  DIMENSION_STRING_CONDITIONS,
  AGGREGATE_SCOPES,
  AGGREGATE_CONDITIONS,
  DIMENSION_PAGE_TARGET_COLUMN_KEY_TYPES,
  DIMENSION_BOOL_CONDITIONS,
  DIMENSION_KEYS,
  DIMENSION_MATCH_CONDITIONS,
  ADDON_FILTERS_PARAM_NAME,
} from '../../util/hooks/api/Filter/constants'
import { deepCopy } from '../../util/copy'
import {
  AggregateConditions,
  AggregateScopes,
  ConditionTypes,
  CustomDimensionOption,
  CustomFilterState,
  DimensionFilterState,
  DimensionKeys,
  DimensionMatchConditions,
  DimensionOption,
  DimensionPageTargetColumns,
} from '../../util/hooks/api/Filter/types'
import { DEFAULT_DIMENSION_OPTIONS } from '../../util/hooks/api/Filter/constants'
import { ReportFilterSelectsData } from '../../util/hooks/api/Filter/useReportFilterSelects'
import { validateBigQueryRegexPattern } from '../../util/RegexUtils'
import { CalenderState } from '../../components/common/DayPickerRange'
import { ScopeType } from '../../util/hooks/useScopeType'
import { DeviceType } from '../../util/hooks/useDeviceType'

const initConditionMap = {
  [CONDITION_TYPES.NUMERIC]: DIMENSION_NUMERIC_CONDITIONS.EQ,
  [CONDITION_TYPES.NUMERIC_WITH_EMPTY]: DIMENSION_NUMERIC_WITH_EMPTY_CONDITIONS.EQ,
  [CONDITION_TYPES.STRING]: DIMENSION_STRING_CONDITIONS.FULL,
  [CONDITION_TYPES.STRING_WITH_EMPTY]: DIMENSION_STRING_WITH_EMPTY_CONDITIONS.FULL,
  [CONDITION_TYPES.CHOICE]: DIMENSION_CHOICE_CONDITIONS.INCLUDE,
  [CONDITION_TYPES.BOOL]: DIMENSION_BOOL_CONDITIONS.EQ,
  [CONDITION_TYPES.DATE_WITH_EMPTY]: DIMENSION_DATE_WITH_EMPTY_CONDITIONS.DATE_BETWEEN,
}
const VALUE_MAX_LENGTH = 100000
const REGEXP_MATCH_CONDITIONS: DimensionMatchConditions[] = [
  DIMENSION_MATCH_CONDITIONS.REGEXP,
  DIMENSION_MATCH_CONDITIONS.NOT_REGEXP,
]

const HASH_PARAMS_NAME = 'hash_params' as const

const getInitCondition = (conditionType: ConditionTypes) => {
  return initConditionMap[conditionType] || ''
}

interface CachedReportParams {
  readonly calenderState: CalenderState
  readonly goalId: number
  readonly scopeType: ScopeType
  readonly deviceType: DeviceType
  readonly customFilterState: CustomFilterState[]
  readonly filterContentEventsExists: boolean
}

export interface CustomFilterModalState {
  readonly openedFilter: boolean
  readonly customFilterState: CustomFilterState[]
  readonly tempCustomFilterState: CustomFilterState[]
  readonly selectsData: ReportFilterSelectsData | undefined
  dimensionValueErrorsMap?: { [key: string]: string[] }
  readonly cachedReportParams?: CachedReportParams
}

const initialState: CustomFilterModalState = {
  openedFilter: false,
  customFilterState: [],
  tempCustomFilterState: [],
  selectsData: undefined,
}

export class CustomFilterAction {
  constructor(
    private readonly state: CustomFilterModalState,
    private readonly setState: React.Dispatch<React.SetStateAction<CustomFilterModalState>>,
  ) {}

  get filtersCount() {
    return this.state.customFilterState.length
  }

  get isFilterApplied() {
    return this.filtersCount > 0
  }

  setReportFilterSelects(data: ReportFilterSelectsData) {
    this.setState({
      ...this.state,
      selectsData: data,
    })
  }

  // モーダル開く
  open = () => {
    this.setState({
      ...this.state,
      openedFilter: true,
      tempCustomFilterState: deepCopy(this.state.customFilterState),
    })
  }

  // フィルタ削除
  onDelete = (index: number) => {
    const temp = this.state.tempCustomFilterState
    temp.splice(index, 1)
    this.setState({
      ...this.state,
      tempCustomFilterState: temp,
    })
  }

  // フィルタ追加
  onAdd = () => {
    this.setState({
      ...this.state,
      tempCustomFilterState: [
        ...this.state.tempCustomFilterState,
        {
          aggregate: {
            unit: AGGREGATE_SCOPES.USER,
            condition: AGGREGATE_CONDITIONS.INCLUDE,
          },
          dimensions: [
            {
              states: [makeDefaultDimensionFilterState(AGGREGATE_SCOPES.USER)],
            },
          ],
        },
      ],
    })
  }

  // 集計単位変更
  onAggregateScopeChange = (event: React.FormEvent<HTMLSelectElement>) => {
    const index = Number(event.currentTarget.dataset.index)
    const newScope = event.currentTarget.value as AggregateScopes
    this.setState({
      ...this.state,
      tempCustomFilterState: [
        ...this.state.tempCustomFilterState.slice(0, index),
        {
          aggregate: {
            ...this.state.tempCustomFilterState[index].aggregate,
            unit: event.currentTarget.value as AggregateScopes,
          },
          dimensions: this.state.tempCustomFilterState[index].dimensions.map((dimension) => {
            return {
              states: dimension.states.map((state) => {
                const { id, name, ...otherProps } = state
                if (!this.validateScopeByDimensionKey(id as DimensionKeys, newScope)) {
                  return {
                    id: undefined,
                    name: '',
                    ...otherProps,
                  }
                }
                return state
              }),
            }
          }),
        },
        ...this.state.tempCustomFilterState.slice(index + 1),
      ],
    })
  }

  // 集計条件変更
  onAggregateConditionChange = (event: React.FormEvent<HTMLSelectElement>) => {
    const index = Number(event.currentTarget.dataset.index)
    this.setState({
      ...this.state,
      tempCustomFilterState: [
        ...this.state.tempCustomFilterState.slice(0, index),
        {
          ...this.state.tempCustomFilterState[index],
          aggregate: {
            ...this.state.tempCustomFilterState[index].aggregate,
            condition: event.currentTarget.value as AggregateConditions,
          },
        },
        ...this.state.tempCustomFilterState.slice(index + 1),
      ],
    })
  }

  // ディメンションの種類変更
  onDimensionUnitChange = (
    newDimensionOption: DimensionOption,
    index: number,
    dimensionIndex: number,
    stateIndex: number,
  ) => {
    const initialValue =
      newDimensionOption.conditionType === CONDITION_TYPES.NUMERIC ||
      newDimensionOption.conditionType === CONDITION_TYPES.NUMERIC_WITH_EMPTY
        ? '1'
        : ''

    this.setState({
      ...this.state,
      tempCustomFilterState: [
        ...this.state.tempCustomFilterState.slice(0, index),
        {
          ...this.state.tempCustomFilterState[index],
          dimensions: [
            ...this.state.tempCustomFilterState[index].dimensions.slice(0, dimensionIndex),
            {
              ...this.state.tempCustomFilterState[index].dimensions[dimensionIndex],
              states: [
                ...this.state.tempCustomFilterState[index].dimensions[dimensionIndex].states.slice(0, stateIndex),
                {
                  ...this.state.tempCustomFilterState[index].dimensions[dimensionIndex].states[stateIndex],
                  id: newDimensionOption.id,
                  name: newDimensionOption.name,
                  dimensionCategory: newDimensionOption.dimensionCategory,
                  conditionType: newDimensionOption.conditionType,
                  condition: getInitCondition(newDimensionOption.conditionType),
                  target: DIMENSION_PAGE_TARGET_COLUMN_KEY_TYPES.includes(newDimensionOption.id)
                    ? DIMENSION_PAGE_TARGET_COLUMNS.URL
                    : undefined,
                  value: initialValue,
                  fromValue: initialValue,
                  toValue: initialValue,
                  goalId: newDimensionOption.goalId,
                  customDimensionId: newDimensionOption.customDimensionId,
                  dataImportFieldId: newDimensionOption.dataImportFieldId,
                },
                ...this.state.tempCustomFilterState[index].dimensions[dimensionIndex].states.slice(stateIndex + 1),
              ],
            },
            ...this.state.tempCustomFilterState[index].dimensions.slice(dimensionIndex + 1),
          ],
        },
        ...this.state.tempCustomFilterState.slice(index + 1),
      ],
    })
  }

  // ディメンションの対象を変更
  onDimensionPageTargetColumnChange = (event: React.FormEvent<HTMLSelectElement>) => {
    const index = Number(event.currentTarget.dataset.index)
    const dimensionIndex = Number(event.currentTarget.dataset.dimensionindex)
    const stateIndex = Number(event.currentTarget.dataset.stateindex)
    this.setState({
      ...this.state,
      tempCustomFilterState: [
        ...this.state.tempCustomFilterState.slice(0, index),
        {
          ...this.state.tempCustomFilterState[index],
          dimensions: [
            ...this.state.tempCustomFilterState[index].dimensions.slice(0, dimensionIndex),
            {
              ...this.state.tempCustomFilterState[index].dimensions[dimensionIndex],
              states: [
                ...this.state.tempCustomFilterState[index].dimensions[dimensionIndex].states.slice(0, stateIndex),
                {
                  ...this.state.tempCustomFilterState[index].dimensions[dimensionIndex].states[stateIndex],
                  target: event.currentTarget.value as DimensionPageTargetColumns,
                },
                ...this.state.tempCustomFilterState[index].dimensions[dimensionIndex].states.slice(stateIndex + 1),
              ],
            },
            ...this.state.tempCustomFilterState[index].dimensions.slice(dimensionIndex + 1),
          ],
        },
        ...this.state.tempCustomFilterState.slice(index + 1),
      ],
    })
  }

  // 入力を更新
  onDimensionValueUpdate = (event: React.FormEvent<HTMLInputElement>) => {
    const index = Number(event.currentTarget.dataset.index)
    const dimensionIndex = Number(event.currentTarget.dataset.dimensionindex)
    const stateIndex = Number(event.currentTarget.dataset.stateindex)
    this.setState({
      ...this.state,
      tempCustomFilterState: [
        ...this.state.tempCustomFilterState.slice(0, index),
        {
          ...this.state.tempCustomFilterState[index],
          dimensions: [
            ...this.state.tempCustomFilterState[index].dimensions.slice(0, dimensionIndex),
            {
              ...this.state.tempCustomFilterState[index].dimensions[dimensionIndex],
              states: [
                ...this.state.tempCustomFilterState[index].dimensions[dimensionIndex].states.slice(0, stateIndex),
                {
                  ...this.state.tempCustomFilterState[index].dimensions[dimensionIndex].states[stateIndex],
                  value: event.currentTarget.value.toString(),
                },
                ...this.state.tempCustomFilterState[index].dimensions[dimensionIndex].states.slice(stateIndex + 1),
              ],
            },
            ...this.state.tempCustomFilterState[index].dimensions.slice(dimensionIndex + 1),
          ],
        },
        ...this.state.tempCustomFilterState.slice(index + 1),
      ],
    })
  }

  onDimensionFromValueUpdate = (event: React.FormEvent<HTMLInputElement>) => {
    const index = Number(event.currentTarget.dataset.index)
    const dimensionIndex = Number(event.currentTarget.dataset.dimensionindex)
    const stateIndex = Number(event.currentTarget.dataset.stateindex)
    this.setState({
      ...this.state,
      tempCustomFilterState: [
        ...this.state.tempCustomFilterState.slice(0, index),
        {
          ...this.state.tempCustomFilterState[index],
          dimensions: [
            ...this.state.tempCustomFilterState[index].dimensions.slice(0, dimensionIndex),
            {
              ...this.state.tempCustomFilterState[index].dimensions[dimensionIndex],
              states: [
                ...this.state.tempCustomFilterState[index].dimensions[dimensionIndex].states.slice(0, stateIndex),
                {
                  ...this.state.tempCustomFilterState[index].dimensions[dimensionIndex].states[stateIndex],
                  fromValue: event.currentTarget.value.toString(),
                },
                ...this.state.tempCustomFilterState[index].dimensions[dimensionIndex].states.slice(stateIndex + 1),
              ],
            },
            ...this.state.tempCustomFilterState[index].dimensions.slice(dimensionIndex + 1),
          ],
        },
        ...this.state.tempCustomFilterState.slice(index + 1),
      ],
    })
  }

  onDimensionToValueUpdate = (event: React.FormEvent<HTMLInputElement>) => {
    const index = Number(event.currentTarget.dataset.index)
    const dimensionIndex = Number(event.currentTarget.dataset.dimensionindex)
    const stateIndex = Number(event.currentTarget.dataset.stateindex)
    this.setState({
      ...this.state,
      tempCustomFilterState: [
        ...this.state.tempCustomFilterState.slice(0, index),
        {
          ...this.state.tempCustomFilterState[index],
          dimensions: [
            ...this.state.tempCustomFilterState[index].dimensions.slice(0, dimensionIndex),
            {
              ...this.state.tempCustomFilterState[index].dimensions[dimensionIndex],
              states: [
                ...this.state.tempCustomFilterState[index].dimensions[dimensionIndex].states.slice(0, stateIndex),
                {
                  ...this.state.tempCustomFilterState[index].dimensions[dimensionIndex].states[stateIndex],
                  toValue: event.currentTarget.value.toString(),
                },
                ...this.state.tempCustomFilterState[index].dimensions[dimensionIndex].states.slice(stateIndex + 1),
              ],
            },
            ...this.state.tempCustomFilterState[index].dimensions.slice(dimensionIndex + 1),
          ],
        },
        ...this.state.tempCustomFilterState.slice(index + 1),
      ],
    })
  }

  // ディメンションの条件変更
  onDimensionConditionChange = (event: React.FormEvent<HTMLSelectElement>) => {
    const index = Number(event.currentTarget.dataset.index)
    const dimensionIndex = Number(event.currentTarget.dataset.dimensionindex)
    const stateIndex = Number(event.currentTarget.dataset.stateindex)
    this.setState({
      ...this.state,
      tempCustomFilterState: [
        ...this.state.tempCustomFilterState.slice(0, index),
        {
          ...this.state.tempCustomFilterState[index],
          dimensions: [
            ...this.state.tempCustomFilterState[index].dimensions.slice(0, dimensionIndex),
            {
              ...this.state.tempCustomFilterState[index].dimensions[dimensionIndex],
              states: [
                ...this.state.tempCustomFilterState[index].dimensions[dimensionIndex].states.slice(0, stateIndex),
                {
                  ...this.state.tempCustomFilterState[index].dimensions[dimensionIndex].states[stateIndex],
                  condition: event.currentTarget.value as DimensionMatchConditions,
                },
                ...this.state.tempCustomFilterState[index].dimensions[dimensionIndex].states.slice(stateIndex + 1),
              ],
            },
            ...this.state.tempCustomFilterState[index].dimensions.slice(dimensionIndex + 1),
          ],
        },
        ...this.state.tempCustomFilterState.slice(index + 1),
      ],
    })
  }

  // AND,OR条件削除
  onMinus = (index: number, dimensionIndex: number, stateIndex: number) => {
    const temp = this.state.tempCustomFilterState
    if (temp[index].dimensions[dimensionIndex].states.length === 1) {
      temp[index].dimensions.splice(dimensionIndex, 1)
    } else {
      temp[index].dimensions[dimensionIndex].states.splice(stateIndex, 1)
    }
    this.setState({
      ...this.state,
      tempCustomFilterState: temp,
    })
  }

  // OR条件追加
  onOr = (index: number, dimensionIndex: number) => {
    this.setState({
      ...this.state,
      tempCustomFilterState: [
        ...this.state.tempCustomFilterState.slice(0, index),
        {
          ...this.state.tempCustomFilterState[index],
          dimensions: [
            ...this.state.tempCustomFilterState[index].dimensions.slice(0, dimensionIndex),
            {
              states: [
                ...this.state.tempCustomFilterState[index].dimensions[dimensionIndex].states,
                makeDefaultDimensionFilterState(this.state.tempCustomFilterState[index].aggregate.unit),
              ],
            },
            ...this.state.tempCustomFilterState[index].dimensions.slice(dimensionIndex + 1),
          ],
        },
        ...this.state.tempCustomFilterState.slice(index + 1),
      ],
    })
  }

  // AND条件追加
  onAnd = (index: number) => {
    this.setState({
      ...this.state,
      tempCustomFilterState: [
        ...this.state.tempCustomFilterState.slice(0, index),
        {
          ...this.state.tempCustomFilterState[index],
          dimensions: [
            ...this.state.tempCustomFilterState[index].dimensions,
            {
              states: [makeDefaultDimensionFilterState(this.state.tempCustomFilterState[index].aggregate.unit)],
            },
          ],
        },
        ...this.state.tempCustomFilterState.slice(index + 1),
      ],
    })
  }

  // モーダル閉じる
  onCancel = () => {
    this.setState({
      ...this.state,
      openedFilter: false,
      tempCustomFilterState: this.state.customFilterState.slice(), // 作業データを戻す
    })
  }

  // リセット
  onReset = () => {
    this.setState({
      ...this.state,
      openedFilter: false,
      customFilterState: [],
      tempCustomFilterState: [],
    })
  }

  // 適用
  onApply = (interceptFilter?: CustomFilterState[]) => {
    this.setState({
      ...this.state,
      openedFilter: false,
      customFilterState: interceptFilter || this.state.tempCustomFilterState.slice(),
    })
  }

  /**
   * 指定したフィルターのディメンションの値を検証する
   *
   * @param {number} filterIndex - The index of the filter in the state.
   * @param {number} dimensionIndex - The index of the dimension in the filter's dimensions array.
   */
  validateDimensionValue = (filterIndex: number, dimensionIndex: number) => {
    const value = this.state.tempCustomFilterState[filterIndex].dimensions[dimensionIndex].states[0].value ?? ''
    const matchCondition = this.state.tempCustomFilterState[filterIndex].dimensions[dimensionIndex].states[0].condition
    const errors = []

    if (value.length > VALUE_MAX_LENGTH) errors.push(`${VALUE_MAX_LENGTH.toLocaleString()}文字以内で入力してください。`)

    if (REGEXP_MATCH_CONDITIONS.includes(matchCondition)) {
      const regexErrorMessage = validateBigQueryRegexPattern(value)
      if (regexErrorMessage) errors.push(regexErrorMessage)
    }

    if (errors.length > 0) {
      this.setDimensionValueErrors(filterIndex, dimensionIndex, errors)
    } else {
      this.clearDimensionValueErrors(filterIndex, dimensionIndex)
    }
  }

  /**
   * 指定したフィルターのディメンションのエラー情報を取得する
   *
   * @param {number} filterIndex - The index of the filter.
   * @param {number} dimensionIndex - The index of the dimension.
   * @returns {string[]} An array of dimension value errors.
   */
  getDimensionValueErrors = (filterIndex: number, dimensionIndex: number): string[] => {
    const key = makeErrorKey(filterIndex, dimensionIndex)
    if (!this.state.dimensionValueErrorsMap) return []
    return this.state.dimensionValueErrorsMap[key] ?? []
  }

  /**
   * 指定したフィルターのディメンションのエラー情報を設定する
   *
   * @param {number} filterIndex - The index of the filter.
   * @param {number} dimensionIndex - The index of the dimension.
   * @param {string[]} errors - An array of error messages.
   */
  setDimensionValueErrors = (filterIndex: number, dimensionIndex: number, errors: string[]) => {
    const key = makeErrorKey(filterIndex, dimensionIndex)
    const errorsMap = this.state.dimensionValueErrorsMap ?? {}
    errorsMap[key] = errors
    this.setState({
      ...this.state,
      dimensionValueErrorsMap: errorsMap,
    })
  }

  /**
   * 指定したフィルターのディメンションのエラー情報をクリアする
   *
   * @param {number} filterIndex - The index of the filter.
   * @param {number} dimensionIndex - The index of the dimension.
   */
  clearDimensionValueErrors = (filterIndex: number, dimensionIndex: number) => {
    const key = makeErrorKey(filterIndex, dimensionIndex)
    if (!this.state.dimensionValueErrorsMap) return

    const errorsMap = this.state.dimensionValueErrorsMap
    delete errorsMap[key]

    this.setState({
      ...this.state,
      dimensionValueErrorsMap: errorsMap,
    })
  }

  /**
   * 入力エラーがあるかどうかをチェック
   * @returns {boolean} - 入力エラーがあればTrue、なければFalse
   */
  hasInputError = (): boolean => {
    if (!this.state.dimensionValueErrorsMap) return false
    return Object.keys(this.state.dimensionValueErrorsMap).length > 0
  }

  setCustomFilterState = (state: CustomFilterState[]) => {
    this.setState({
      ...this.state,
      customFilterState: state.slice(),
    })
  }

  alreadyHasTargetDimension = (targetDimension: CustomFilterState) => {
    return this.state.customFilterState.some((filter) => JSON.stringify(filter) === JSON.stringify(targetDimension))
  }

  /**
   * 指定したカスタムフィルターを追加する
   *
   * 追加に CustomFilterUrlParams を使用した場合は、その情報も合わせて保持する
   * (ページレポートからカスタムディメンションの戻り時にフィルターを元に戻すため)
   *
   * @param {CustomFilterState[]} filters - 追加するカスタムフィルター
   */
  addCustomFilterState = (filters: CustomFilterState[]) => {
    this.setState({
      ...this.state,
      customFilterState: [
        ...this.state.customFilterState,
        // 二重追加防止のため、存在チェック後に追加
        ...filters.filter((filter) => !this.alreadyHasTargetDimension(filter)),
      ],
    })
  }

  /**
   * 指定したカスタムフィルターに一致する情報を削除する
   *
   * @param {CustomFilterState} targetDimension - 削除対象のカスタムフィルター
   */
  removeCustomFilterState = (targetDimension: CustomFilterState) => {
    const targetString = JSON.stringify(targetDimension)

    this.setState({
      ...this.state,
      customFilterState: this.state.customFilterState.filter((item) => JSON.stringify(item) !== targetString),
    })
  }

  getCustomDimensionData = (key: number): CustomDimensionOption | undefined => {
    return this.state.selectsData?.customDimensions.find((dimension) => dimension.customDimensionId === key)
  }

  /**
   * 指定されたディメンションキーの集計スコープを検証する
   *
   * @param {DimensionKeys} key - The dimension key to validate.
   * @param {AggregateScopes} aggScope - The aggregate scope to validate against.
   * @returns {boolean} - True if the scope is valid, false otherwise.
   */
  validateScopeByDimensionKey = (key: DimensionKeys, aggScope: AggregateScopes): boolean => {
    const option = DEFAULT_DIMENSION_OPTIONS.find((option) => option.id === key)
    if (option) return option.targetScopes.includes(aggScope)

    const dimension = this.state.selectsData?.customDimensions.find((option) => option.id === key)
    if (dimension) return dimension.targetScopes.includes(aggScope)

    const goal = this.state.selectsData?.goals.find((option) => option.id === key)
    if (goal) return goal.targetScopes.includes(aggScope)

    const importField = this.state.selectsData?.dataImportFields.find((option) => option.id === key)
    if (importField) return importField.targetScopes.includes(aggScope)

    return false
  }

  /**
   * ハッシュパラメータとしてフィルターを追加したページレポートURLを作成する
   * @param {string} baseUrl - ベースURL
   * @param {CustomFilterState[]} customFilterState - カスタムフィルター状態
   * @param {string} search - window.location.searchの値。「?」で始まる文字列か空文字列。hashは含まない
   * @return {string} - パラメータ付きのページレポートURL
   */
  makePageReportUrlWithHashFilter(baseUrl: string, customFilterState: CustomFilterState[], search: string): string {
    const searchValue = JSON.stringify(customFilterState)
    const addonSearchParam = `${ADDON_FILTERS_PARAM_NAME}=${searchValue}`

    // addon_filtersはハッシュの後に配置
    const hashParams = `#${HASH_PARAMS_NAME}?${addonSearchParam}`

    return `${baseUrl}report/page${search}${hashParams}`
  }
}

/**
 * 指定されたスコープに基づいて、ディメンションフィルタのデフォルト値を生成する
 * スコープで選択可能なリストの先頭のディメンションをデフォルト値とする
 *
 * @param {AggregateScopes} scope - デフォルト値生成の種類を判断するスコープ
 * @throws {Error} スコープが無効な場合にエラーをスローする
 * @return {DimensionFilterState} ディメンションフィルタのデフォルト値
 */
function makeDefaultDimensionFilterState(scope: AggregateScopes): DimensionFilterState {
  const key = scope === AGGREGATE_SCOPES.PAGE_VIEW ? DIMENSION_KEYS.IS_SESSION_START_PAGE : DIMENSION_KEYS.VISITS
  const option = DEFAULT_DIMENSION_OPTIONS.find((opt) => opt.id === key)
  if (!option) throw Error('Invalid scope')

  const optionValues = {
    id: option.id,
    name: option.name,
    dimensionCategory: option.dimensionCategory,
    conditionType: option.conditionType,
    target: undefined,
  }

  if (key === DIMENSION_KEYS.IS_SESSION_START_PAGE) {
    return {
      ...optionValues,
      condition: DIMENSION_BOOL_CONDITIONS.EQ,
      value: undefined,
      fromValue: undefined,
      toValue: undefined,
    }
  }

  return {
    ...optionValues,
    condition: DIMENSION_NUMERIC_CONDITIONS.EQ,
    value: '1',
    fromValue: '1',
    toValue: '1',
  }
}

/**
 * 指定したフィルターのディメンションのErrorMapに設定するキーを生成する
 *
 * @param {number} filterIndex - The index of the filter.
 * @param {number} dimensionIndex - The index of the dimension.
 * @return {string} - The error key generated using the filter and dimension index.
 */
function makeErrorKey(filterIndex: number, dimensionIndex: number): string {
  return `${filterIndex}__${dimensionIndex}`
}

export interface GlobalContextType {
  readonly state: CustomFilterModalState
  readonly actions: CustomFilterAction
  readonly addonFilterState: CustomFilterState[] | undefined
  readonly setAddonFilterState: (value: CustomFilterState[] | undefined) => void
  readonly cachedReportParams: CachedReportParams | undefined
  readonly setCachedReportParams: (value: CachedReportParams | undefined) => void
  readonly isReportParamsChanged: boolean
  readonly setIsReportParamsChanged: (value: boolean) => void

  // ページレポートからブラウザバックなどで戻る場合に使用。戻り先でページャーなどを保持する制御などで使用
  readonly isPageReportBacked: boolean
  readonly setIsPageReportBacked: (value: boolean) => void
}

/**
 * レポートのカスタムフィルターで共通使用する state と actions を返す
 *
 * @return {GlobalContextType} The context state and actions.
 */
export function useContextState(): GlobalContextType {
  const [state, setState] = React.useState<CustomFilterModalState>(initialState)
  const [cachedReportParams, setCachedReportParams] = React.useState<CachedReportParams | undefined>(undefined)
  const [addonFilterState, setAddonFilterState] = React.useState<CustomFilterState[] | undefined>(undefined)
  const [isReportParamsChanged, setIsReportParamsChanged] = React.useState<boolean>(false)
  const [isPageReportBacked, setIsPageReportBacked] = React.useState<boolean>(false)
  const actions = new CustomFilterAction(state, setState)

  return {
    state,
    actions,
    addonFilterState,
    setAddonFilterState,
    cachedReportParams,
    setCachedReportParams,
    isReportParamsChanged,
    setIsReportParamsChanged,
    isPageReportBacked,
    setIsPageReportBacked,
  }
}

/**
 * ディメンションフィルタのDimensionFilterStateを生成する
 *
 * @param {DimensionKeys} dimensionKey - The key of the dimension.
 * @param {DimensionMatchConditions} condition - The match conditions for the dimension.
 * @param {string} value - The value of the dimension.
 * @returns {DimensionFilterState} - The dimension filter state object.
 */
export function makeDimensionState(
  dimensionKey: DimensionKeys,
  condition: DimensionMatchConditions,
  value: string,
): DimensionFilterState {
  const dimensionOption = DEFAULT_DIMENSION_OPTIONS.find((option) => option.id === dimensionKey)
  if (!dimensionOption) {
    throw new Error(`Invalid dimension key: ${dimensionKey}`)
  }

  return {
    id: dimensionKey,
    condition,
    value,
    name: dimensionOption.name,
    dimensionCategory: dimensionOption.dimensionCategory,
    conditionType: dimensionOption.conditionType,
    target: undefined,
    fromValue: undefined,
    toValue: undefined,
  }
}
