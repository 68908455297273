import * as React from 'react'
import { State } from './state'
import { request } from '../../util/request'
import { GoalType, Condition, ValueCondition, GoalEventType } from '../../util/Goal'

// 処理ステップ
export const GoalStep = {
  none: 0,
  createExec: 1, // 作成実行
  createComplete: 2, // 作成完了
  editExec: 3, // 編集実行
  editComplete: 4, // 編集完了
  deleteExec: 5, // 削除実行
  deleteComplete: 6, // 削除完了
}

export const INITIAL_GOAL = {
  selectedId: 0,
  step: GoalStep.none,
  type: GoalType.url,
  name: '',
  condition: Condition.perfect,
  url: '',
  eventSelected: [true, true, true, true],
  eventCondition: [Condition.perfect, Condition.perfect, Condition.perfect, ValueCondition.greater],
  eventValue: ['', '', '', ''],
  autoSendPurchaseEnabled: true,
  estimatedRevenueEnabled: false,
  estimatedRevenueAmount: undefined,
}

export class Goal {
  constructor(private readonly state: State, private readonly setState: React.Dispatch<React.SetStateAction<State>>) {}

  // ゴール作成モーダル表示
  onCreate = () => {
    this.setState({
      ...this.state,
      goal: {
        ...this.state.goal,
        ...INITIAL_GOAL,
        step: GoalStep.createExec,
      },
    })
  }

  // 作成するゴールの種類設定
  onSetType = (event: React.FormEvent<HTMLSelectElement>) => {
    const type = parseInt(event.currentTarget.value)

    this.setState({
      ...this.state,
      goal: {
        ...this.state.goal,
        type: type,
      },
    })
  }

  // ゴール作成モーダルでのゴール名設定
  onSetName = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      goal: {
        ...this.state.goal,
        name: event.currentTarget.value,
      },
    })
  }

  // ゴール作成モーダルでのゴール条件設定
  onSetCondition = (event: React.FormEvent<HTMLSelectElement>) => {
    this.setState({
      ...this.state,
      goal: {
        ...this.state.goal,
        condition: Number(event.currentTarget.value),
      },
    })
  }

  // ゴール作成モーダルでのゴールURL設定
  onSetUrl = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      ...this.state,
      goal: {
        ...this.state.goal,
        url: event.currentTarget.value,
      },
    })
  }

  // 作成イベントの種類選択
  onSetEventSelected = (index: number) => {
    const temp = this.state.goal.eventSelected.slice()
    temp[index] = !temp[index]
    this.setState({
      ...this.state,
      goal: {
        ...this.state.goal,
        eventSelected: temp,
      },
    })
  }

  // 作成イベントの条件設定
  onSetEventCondition = (event: React.FormEvent<HTMLSelectElement>, index: number) => {
    const temp = this.state.goal.eventCondition.slice()
    temp[index] = Number(event.currentTarget.value)
    this.setState({
      ...this.state,
      goal: {
        ...this.state.goal,
        eventCondition: temp,
      },
    })
  }

  // 作成イベントの入力
  onSetEventValue = (event: React.FormEvent<HTMLInputElement>, index: number) => {
    const temp = this.state.goal.eventValue.slice()
    temp[index] = event.currentTarget.value
    this.setState({
      ...this.state,
      goal: {
        ...this.state.goal,
        eventValue: temp,
      },
    })
  }

  // 購入イベント(Eコマースイベント)の自動送信ON/OFF変更
  onSetAutoSendPurchaseEnabled = () => {
    this.setState({
      ...this.state,
      goal: {
        ...this.state.goal,
        autoSendPurchaseEnabled: !this.state.goal.autoSendPurchaseEnabled,
      },
    })
  }

  onSetEstimatedRevenueEnabled = () => {
    this.setState({
      ...this.state,
      goal: {
        ...this.state.goal,
        estimatedRevenueEnabled: !this.state.goal.estimatedRevenueEnabled,
      },
    })
  }

  onSetEstimatedRevenueAmount = (event: React.FormEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value === '' ? undefined : Number(event.currentTarget.value)
    this.setState({
      ...this.state,
      goal: {
        ...this.state.goal,
        estimatedRevenueAmount: value,
      },
    })
  }

  // ゴール作成モーダル閉じる
  onCancelCreate = () => {
    this.setState({
      ...this.state,
      goal: {
        ...this.state.goal,
        step: GoalStep.none,
      },
    })
  }

  // ゴール作成
  onApplyCreate = async () => {
    try {
      await request('POST', `/api/projects/${this.state.project.id}/goals/`, true, this.getJson())
      this.setState({
        ...this.state,
        reload: true,
        goal: {
          ...this.state.goal,
          step: GoalStep.none,
        },
        goalErrorMessage: '',
        toastMessage: 'ゴールを追加しました',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        goal: {
          ...this.state.goal,
          step: GoalStep.none,
        },
        goalErrorMessage: typeof e === 'string' ? e : 'ゴールの作成に失敗しました。',
      })
    }
  }

  onEditModalOpen = (id: number) => {
    const goal = this.state.goal.goals.find((goal) => goal.id === id)
    if (!goal) return

    const commonGoalValues = {
      selectedId: id,
      step: GoalStep.editExec,
      disabled: true,
      name: goal.name,
      baseName: goal.name, // 比較用となる元の情報
      isNameValid: false,
    }

    const estimatedRevenueAmount = goal.estimatedRevenue?.amount || undefined
    const estimatedRevenueEnabled = !!estimatedRevenueAmount

    if (goal.type === GoalType.url && goal.url) {
      this.setState({
        ...this.state,
        goal: {
          ...this.state.goal,
          ...commonGoalValues,
          type: GoalType.url,
          condition: goal.url.condition,
          url: goal.url.url,
          estimatedRevenueAmount,
          estimatedRevenueEnabled,
        },
      })
      return
    }
    if (goal.type === GoalType.event && goal.event) {
      this.setState({
        ...this.state,
        goal: {
          ...this.state.goal,
          ...commonGoalValues,
          type: GoalType.event,
          eventSelected: [
            goal.event.category !== '',
            goal.event.action !== '',
            goal.event.label !== '',
            goal.event.value !== 0,
          ],
          eventCondition: [
            goal.event.category_condition ?? Condition.perfect,
            goal.event.action_condition ?? Condition.perfect,
            goal.event.label_condition ?? Condition.perfect,
            goal.event.value_condition ?? ValueCondition.greater,
          ],
          eventValue: [
            goal.event.category ?? '',
            goal.event.action ?? '',
            goal.event.label ?? '',
            goal.event.value?.toString() ?? '',
          ],
          estimatedRevenueAmount,
          estimatedRevenueEnabled,
        },
      })
      return
    }

    if (goal.type === GoalType.purchase && goal.purchase) {
      this.setState({
        ...this.state,
        goal: {
          ...this.state.goal,
          ...commonGoalValues,
          type: GoalType.purchase,
          autoSendPurchaseEnabled: goal.purchase.autoSendPurchaseEnabled,
        },
      })
      return
    }
  }

  // 編集状態を解除
  onCancelEdit = () => {
    this.setState({
      ...this.state,
      goal: {
        ...this.state.goal,
        ...INITIAL_GOAL,
      },
    })
  }

  onApplyEdit = async () => {
    try {
      await request(
        'PATCH',
        `/api/projects/${this.state.project.id}/goals/${this.state.goal.selectedId}/`,
        true,
        this.getJson(),
      )
      this.setState({
        ...this.state,
        reload: true,
        goal: {
          ...this.state.goal,
          ...INITIAL_GOAL,
        },
        toastMessage: 'ゴールを変更しました',
      })
    } catch (e) {
      this.setState({
        ...this.state,
        goalErrorMessage: typeof e === 'string' ? e : 'ゴールの編集に失敗しました。',
      })
    }
  }

  // ゴール削除フロー開始
  onDelete = (id: number) => {
    const goal = this.state.goal.goals.find((goal) => goal.id === id)
    if (goal) {
      this.setState({
        ...this.state,
        goal: {
          ...this.state.goal,
          deleteId: goal.id,
          deleteName: goal.name,
          step: GoalStep.deleteExec,
        },
      })
    }
  }

  // ゴール削除キャンセル
  onCancelDelete = () => {
    this.setState({
      ...this.state,
      goal: {
        ...this.state.goal,
        deleteId: 0,
        deleteName: '',
        step: GoalStep.none,
      },
    })
  }

  // ゴール削除実行
  onApplyDelete = async () => {
    const goal = this.state.goal.goals.find((goal) => goal.id === this.state.goal.deleteId)
    if (goal) {
      try {
        await request('DELETE', `/api/projects/${this.state.project.id}/goals/${this.state.goal.deleteId}/`, true)
        this.setState({
          ...this.state,
          reload: true,
          goal: {
            ...this.state.goal,
            deleteId: 0,
            deleteName: '',
            step: GoalStep.none,
          },
          toastMessage: 'ゴールを削除しました',
        })
      } catch (e) {
        this.setState({
          ...this.state,
          goal: {
            ...this.state.goal,
            deleteId: 0,
            deleteName: '',
            step: GoalStep.none,
          },
          goalErrorMessage: typeof e === 'string' ? e : 'ゴールの削除に失敗しました。',
        })
      }
    }
  }

  // APIに使用するパラメータを作成
  getJson = () => {
    let json = {
      name: this.state.goal.name,
      goal_type: this.state.goal.type,
    }

    const estimatedRevenue = this.state.goal.estimatedRevenueEnabled
      ? {
          amount: this.state.goal.estimatedRevenueAmount,
        }
      : undefined

    if (this.state.goal.type === GoalType.url) {
      const url = {
        goal_url: {
          condition: this.state.goal.condition,
          url: this.state.goal.url,
        },
        estimated_revenue: estimatedRevenue,
      }
      json = { ...json, ...url }
    } else if (this.state.goal.type === GoalType.purchase) {
      const purchase = {
        purchase: {
          auto_send_purchase_enabled: this.state.goal.autoSendPurchaseEnabled,
        },
      }
      json = { ...json, ...purchase }
    } else {
      const event = {
        goal_event: {
          category: this.state.goal.eventSelected[GoalEventType.category]
            ? this.state.goal.eventValue[GoalEventType.category]
            : '',
          action: this.state.goal.eventSelected[GoalEventType.action]
            ? this.state.goal.eventValue[GoalEventType.action]
            : '',
          label: this.state.goal.eventSelected[GoalEventType.label]
            ? this.state.goal.eventValue[GoalEventType.label]
            : '',
          value: this.state.goal.eventSelected[GoalEventType.value]
            ? Number(this.state.goal.eventValue[GoalEventType.value])
            : null,
          category_condition: this.state.goal.eventSelected[GoalEventType.category]
            ? this.state.goal.eventCondition[GoalEventType.category]
            : null,
          action_condition: this.state.goal.eventSelected[GoalEventType.action]
            ? this.state.goal.eventCondition[GoalEventType.action]
            : null,
          label_condition: this.state.goal.eventSelected[GoalEventType.label]
            ? this.state.goal.eventCondition[GoalEventType.label]
            : null,
          value_condition: this.state.goal.eventSelected[GoalEventType.value]
            ? this.state.goal.eventCondition[GoalEventType.value]
            : null,
        },
        estimated_revenue: estimatedRevenue,
      }
      json = { ...json, ...event }
    }
    return JSON.stringify(json)
  }
}
