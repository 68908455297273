import * as React from 'react'
import { colors } from '../../styleConstants'
import { PageGridItem, PageReportItem, gridLayout, gridLayoutRowGroups } from './PageGridItem'
import { SCOPE_TYPE, useScopeType } from '../../util/hooks/useScopeType'
import { SummaryRow } from '../PageReport/rows/SummaryRow'
import { PageReportsSummary } from '../../util/hooks/api/PageReport/usePageReport'
import { HELP_TEXTS } from '../../constants'
import { DataTable, DataTh, DataTr, INDEX_COLUMN_WIDTH, SortControl } from '../DataTable'
import { RevenueType } from '../../types/Report/RevenueType'
import { SORT_KEYS } from '../../types/Report/PageReport/SortKey'
import { SortIconState } from '../common/SortButton'
import { PageReportContext } from '../../contexts/PageReportContext'
import { RevenueAmountHeader } from '../report/table/headers/RevenueAmountHeader'

interface Props {
  readonly projectId: string
  readonly revenueType: RevenueType | null
  readonly items: PageReportItem[]
  readonly pageReportsSummary: PageReportsSummary
  readonly topIndex: number // 先頭のインデックス
  readonly baseUrl?: string
  readonly onSort: (newSortKey: string, newSortIcon: SortIconState) => void
}

export function PageGrid({ projectId, revenueType, items, pageReportsSummary, topIndex, baseUrl, onSort }: Props) {
  const { getSortIcon } = React.useContext(PageReportContext)
  const { scopeType, scopeTypeLabel } = useScopeType()
  const countSortKey = scopeType === SCOPE_TYPE.USER ? SORT_KEYS.USER_COUNT : SORT_KEYS.SESSION_COUNT
  const allViewCount = scopeType === SCOPE_TYPE.USER ? pageReportsSummary.allUsers : pageReportsSummary.allSessions

  const isScopeTypeUser = scopeType === SCOPE_TYPE.USER

  return (
    <DataTable>
      <thead data-testid="report-table-header">
        <DataTr>
          <DataTh noBorderRight minWidth={INDEX_COLUMN_WIDTH}></DataTh>
          <DataTh minWidth={gridLayoutRowGroups.url} textAlign={'left'} fillWidth={true}>
            ページ
          </DataTh>
          <DataTh backgroundColor={colors.lightSalmon} minWidth={gridLayout.row.viewCount}>
            <SortControl
              subTitle={'(対全体比率)'}
              helpText={isScopeTypeUser ? HELP_TEXTS.USER_COUNT : HELP_TEXTS.SESSION_COUNT}
              helpWidth={200}
              sortKey={countSortKey}
              sortIcon={getSortIcon(countSortKey)}
              onSortClick={onSort}
            >
              {scopeTypeLabel}数
            </SortControl>
          </DataTh>
          <DataTh backgroundColor={colors.lightSalmon} minWidth={gridLayout.row.pageLandingCount}>
            <SortControl
              subTitle={'(対全体比率)'}
              helpText={isScopeTypeUser ? HELP_TEXTS.USER_LANDING_COUNT : HELP_TEXTS.SESSION_LANDING_COUNT}
              helpWidth={300}
              sortKey={SORT_KEYS.LANDING_COUNT}
              sortIcon={getSortIcon(SORT_KEYS.LANDING_COUNT)}
              onSortClick={onSort}
            >
              ランディング数
            </SortControl>
          </DataTh>
          <DataTh backgroundColor={colors.lightSalmon} minWidth={gridLayout.row.pageBounceRate}>
            <SortControl
              helpText={HELP_TEXTS.BOUNCE_RATE}
              helpWidth={220}
              sortKey={SORT_KEYS.BOUNCE_RATE}
              sortIcon={getSortIcon(SORT_KEYS.BOUNCE_RATE)}
              onSortClick={onSort}
            >
              直帰率
            </SortControl>
          </DataTh>
          <DataTh backgroundColor={colors.lightSalmon} minWidth={gridLayout.row.pageExitRate}>
            <SortControl
              helpText={isScopeTypeUser ? HELP_TEXTS.USER_EXIT_RATE : HELP_TEXTS.SESSION_EXIT_RATE}
              helpWidth={300}
              sortKey={SORT_KEYS.EXIT_RATE}
              sortIcon={getSortIcon(SORT_KEYS.EXIT_RATE)}
              onSortClick={onSort}
            >
              離脱率
            </SortControl>
          </DataTh>
          <DataTh backgroundColor={colors.lightSalmon} minWidth={gridLayout.row.pageConversions}>
            <SortControl
              subTitle={'(対全体比率)'}
              helpText={isScopeTypeUser ? HELP_TEXTS.USER_GOAL_COUNT : HELP_TEXTS.SESSION_GOAL_COUNT}
              helpWidth={200}
              sortKey={SORT_KEYS.GOAL_COUNT}
              sortIcon={getSortIcon(SORT_KEYS.GOAL_COUNT)}
              onSortClick={onSort}
            >
              ゴール数
            </SortControl>
          </DataTh>
          <DataTh backgroundColor={colors.lightSalmon} minWidth={gridLayout.row.pageConversionsRate}>
            <SortControl
              helpText={isScopeTypeUser ? HELP_TEXTS.USER_GOAL_RATE : HELP_TEXTS.SESSION_GOAL_RATE}
              helpWidth={150}
              sortKey={SORT_KEYS.GOAL_RATE}
              sortIcon={getSortIcon(SORT_KEYS.GOAL_RATE)}
              onSortClick={onSort}
            >
              ゴール率
            </SortControl>
          </DataTh>
          <RevenueAmountHeader
            revenueType={revenueType}
            sortKey={SORT_KEYS.REVENUE_AMOUNT}
            sortIcon={getSortIcon(SORT_KEYS.REVENUE_AMOUNT)}
            onSortClick={onSort}
          />
        </DataTr>
      </thead>
      <tbody>
        <SummaryRow
          allViewCount={allViewCount}
          allLandingCount={pageReportsSummary.allLandingCount}
          allBounceRate={pageReportsSummary.allBounceRate}
          allExitRate={pageReportsSummary.allExitRate}
          allConversions={pageReportsSummary.allConversions}
          allConversionsRate={pageReportsSummary.allConversionsRate}
          allRevenueCount={pageReportsSummary.allRevenueCount}
          allRevenueAmount={pageReportsSummary.allRevenueAmount}
          revenueType={revenueType}
        />
        {items.map((item, index) => (
          <PageGridItem
            key={index}
            projectId={projectId}
            item={item}
            dataIndex={topIndex + index}
            baseUrl={baseUrl}
            revenueType={revenueType}
          />
        ))}
      </tbody>
    </DataTable>
  )
}
