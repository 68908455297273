import * as React from 'react'
import styled from 'styled-components'
import cc from 'classcat'
import { colors } from '../../styleConstants'
import {
  GoalType,
  GoalEventType,
  Condition,
  ValueCondition,
  GoalTypeMap,
  MatchMap,
  ValueConditionMap,
} from '../../util/Goal'
import { Input } from '../../components/common/Input'
import { Select } from '../../components/common/Select'
import { RadioButton } from '../../components/common/RadioButton'
import { CheckCircle } from '@styled-icons/boxicons-solid/CheckCircle'
import { WarningBox } from '../common/WarningBox'
import { Modal, ModalButtonWrapper, ModalContentWrapper } from '../common/Modal'
import { CancelButton, NormalButton } from '../common/Button'
import { HelpLink } from '../common/HelpLink'
import { HELP_LINKS } from '../../constants'
import { InputErrorMessage } from '../errors/InputErrorMessage'
import { HelpTooltip } from '../Tooltip/IconTooltip'

export const ConditionOptions = [
  { label: MatchMap[Condition.perfect], value: Condition.perfect },
  { label: MatchMap[Condition.head], value: Condition.head },
  { label: MatchMap[Condition.regexp], value: Condition.regexp },
]

export const ValueConditionOptions = [
  { label: ValueConditionMap[ValueCondition.greater], value: ValueCondition.greater },
  { label: ValueConditionMap[ValueCondition.orHigher], value: ValueCondition.orHigher },
  { label: ValueConditionMap[ValueCondition.equal], value: ValueCondition.equal },
  { label: ValueConditionMap[ValueCondition.orLower], value: ValueCondition.orLower },
  { label: ValueConditionMap[ValueCondition.smaller], value: ValueCondition.smaller },
  { label: ValueConditionMap[ValueCondition.exists], value: ValueCondition.exists },
  { label: ValueConditionMap[ValueCondition.notExists], value: ValueCondition.notExists },
]

const ESTIMATED_REVENUE_AMOUNT_MIN = 1
const ESTIMATED_REVENUE_AMOUNT_MAX = 99999999

interface Props {
  readonly type: number
  readonly name: string
  readonly condition: number
  readonly url: string
  readonly eventSelected: boolean[]
  readonly eventCondition: (number | null)[]
  readonly eventValue: string[]
  readonly autoSendPurchaseEnabled: boolean
  readonly estimatedRevenueEnabled: boolean
  readonly estimatedRevenueAmount?: number
  readonly onSetType?: (event: React.FormEvent<HTMLSelectElement>) => void
  readonly onSetName: (event: React.FormEvent<HTMLInputElement>) => void
  readonly onSetCondition: (event: React.FormEvent<HTMLSelectElement>) => void
  readonly onSetUrl: (event: React.FormEvent<HTMLInputElement>) => void
  readonly onSetEventSelected: (index: number) => void
  readonly onSetEventCondition: (event: React.FormEvent<HTMLSelectElement>, index: number) => void
  readonly onSetEventValue: (event: React.FormEvent<HTMLInputElement>, index: number) => void
  readonly onSetAutoSendPurchaseEnabled: () => void
  readonly onSetEstimatedRevenueEnabled: () => void
  readonly onSetEstimatedRevenueAmount: (event: React.FormEvent<HTMLInputElement>) => void
  readonly opened: boolean
  readonly onCancel: () => void // キャンセル
  readonly onApply: () => void // 次へ、または作成
  readonly isCreate?: boolean
}

export function EditGoal(props: Props) {
  const {
    type,
    name,
    condition,
    url,
    eventSelected,
    eventCondition,
    eventValue,
    autoSendPurchaseEnabled,
    estimatedRevenueEnabled,
    estimatedRevenueAmount,
    onSetType,
    onSetName,
    onSetCondition,
    onSetUrl,
    onSetEventSelected,
    onSetEventCondition,
    onSetEventValue,
    onSetAutoSendPurchaseEnabled,
    onSetEstimatedRevenueEnabled,
    onSetEstimatedRevenueAmount,
    opened,
    onCancel,
    onApply,
    isCreate,
  } = props

  // 保存ボタン活性化の判定用に初期値をオブジェクトとして保持
  const editingData = {
    name,
    type,
    condition,
    url,
    eventSelected,
    eventCondition,
    eventValue,
    autoSendPurchaseEnabled,
    estimatedRevenueEnabled,
    estimatedRevenueAmount,
  }
  const originalData = React.useMemo(() => editingData, [])
  const isFormDirty = JSON.stringify(editingData) !== JSON.stringify(originalData)

  // ゴールイベントが一つでも選択されているか
  const isSelectedEvent = eventSelected.filter((type) => type === true).length > 0

  const [estimatedRevenueAmountErrorMessage, setEstimatedRevenueAmountErrorMessage] = React.useState<string>('')

  const validateEstimatedRevenueAmount = () => {
    if (
      !estimatedRevenueAmount ||
      estimatedRevenueAmount < ESTIMATED_REVENUE_AMOUNT_MIN ||
      estimatedRevenueAmount > ESTIMATED_REVENUE_AMOUNT_MAX
    ) {
      setEstimatedRevenueAmountErrorMessage(
        `${ESTIMATED_REVENUE_AMOUNT_MIN}〜${ESTIMATED_REVENUE_AMOUNT_MAX.toLocaleString()}の数値を入力してください。`,
      )
      return
    }
    setEstimatedRevenueAmountErrorMessage('')
  }

  const hasFormError = () => {
    if (!name) return true

    const isValidEstimatedRevenueAmount =
      !estimatedRevenueEnabled || (!!estimatedRevenueAmount && !estimatedRevenueAmountErrorMessage)

    if (type === GoalType.url) {
      return !url || !isValidEstimatedRevenueAmount
    } else if (type === GoalType.event) {
      const isEmptyValue = eventValue.some((value, i) => eventSelected[i] && !value)

      return !isSelectedEvent || isEmptyValue || !isValidEstimatedRevenueAmount
    }

    return false
  }

  const isDisplayEstimatedRevenue = type === GoalType.url || type === GoalType.event
  const modalTitle = isCreate ? 'ゴールの追加' : 'ゴールの編集'
  const saveButtonLabel = isCreate ? '追加' : '変更'

  return (
    <Modal isOpen={opened} onClose={onCancel} title={modalTitle}>
      <ModalContentWrapper>
        <Container>
          <CategoryBox>
            <Category>ゴール名</Category>
            <DesignedInput
              type="text"
              value={name}
              required
              placeholder={'ゴール名'}
              style={{ width: '220px', marginLeft: '0' }}
              onChange={onSetName}
            />
          </CategoryBox>

          {isCreate && (
            <CategoryBox>
              <Category>
                ゴールタイプ
                <HelpTooltip width={210}>
                  <HelpText>
                    選択したゴールタイプに応じて、ページ到達や、ボタンクリック等の様々なゴール設定が可能です。
                  </HelpText>
                </HelpTooltip>
              </Category>
              <RadioButtons>
                <RadioButton
                  buttonSize="normal"
                  message={GoalTypeMap[GoalType.url]}
                  checked={type === GoalType.url}
                  value={GoalType.url}
                  onChange={onSetType}
                />
                <RadioButton
                  buttonSize="normal"
                  message={GoalTypeMap[GoalType.event]}
                  checked={type === GoalType.event}
                  value={GoalType.event}
                  onChange={onSetType}
                />
                <RadioButton
                  buttonSize="normal"
                  message={GoalTypeMap[GoalType.purchase]}
                  checked={type === GoalType.purchase}
                  value={GoalType.purchase}
                  onChange={onSetType}
                />
              </RadioButtons>
            </CategoryBox>
          )}
          {type === GoalType.url && (
            <>
              <CategoryBox>
                <WarningBoxContainer>
                  <WarningBox>URLの末尾にスラッシュ（/）がある場合、スラッシュを除外して設定してください。</WarningBox>
                  <WarningBox>
                    <div>
                      パラメーターを含めたURLをゴールURLにする場合は、「クエリーパラメーター」にそのパラメーターが設定されていることを確認してください。
                    </div>
                    <div>
                      クエリパラメーターに設定がない場合、URLのクエリパラメーター（?〜）は削除されるため、ゴールURLにパラメーターを付与しても計測がされません。
                    </div>
                  </WarningBox>
                </WarningBoxContainer>
              </CategoryBox>
              <CategoryBox>
                <Category>ゴールURL</Category>
                <Item>
                  <DesignedSelect options={ConditionOptions} defaultValue={condition} onChange={onSetCondition} />
                  <DesignedInput type="text" value={url} placeholder={'ゴールURL'} required onChange={onSetUrl} />
                </Item>
              </CategoryBox>
            </>
          )}
          {type === GoalType.event && (
            <>
              <CategoryBox>
                <WarningBoxContainer>
                  <WarningBox>
                    自動送信されるカスタムイベントをゴール設定する場合、アクションやラベルに入力するURLの末尾のスラッシュ（/）は除外して設定してください。
                  </WarningBox>
                </WarningBoxContainer>
              </CategoryBox>
              <CategoryBox className={cc({ bordered: !isSelectedEvent })}>
                <EventConditionLabel>
                  イベント条件
                  <HelpBox>
                    <HelpLink title={'カスタムイベントのゴール設定方法'} link={HELP_LINKS.CUSTOM_EVENT_GOAL_HOW_TO} />
                  </HelpBox>
                </EventConditionLabel>
                <Item>
                  <CheckIcon
                    className={cc({ checked: eventSelected[GoalEventType.category] })}
                    onClick={() => onSetEventSelected(GoalEventType.category)}
                  />
                  <Label className={cc({ checked: eventSelected[GoalEventType.category] })}>カテゴリ</Label>
                  <DesignedSelect
                    options={ConditionOptions}
                    defaultValue={eventCondition[GoalEventType.category] || ''}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                      onSetEventCondition(e, GoalEventType.category)
                    }
                  />
                  <DesignedInput
                    type="text"
                    value={eventValue[GoalEventType.category]}
                    placeholder={'カテゴリ'}
                    required={eventSelected[GoalEventType.category]}
                    onChange={(e) => onSetEventValue(e, GoalEventType.category)}
                  />
                </Item>
                <Item>
                  <CheckIcon
                    className={cc({ checked: eventSelected[GoalEventType.action] })}
                    onClick={() => onSetEventSelected(GoalEventType.action)}
                  />
                  <Label className={cc({ checked: eventSelected[GoalEventType.action] })}>アクション</Label>
                  <DesignedSelect
                    options={ConditionOptions}
                    defaultValue={eventCondition[GoalEventType.action] || ''}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onSetEventCondition(e, GoalEventType.action)}
                  />
                  <DesignedInput
                    type="text"
                    value={eventValue[GoalEventType.action]}
                    placeholder={'アクション'}
                    required={eventSelected[GoalEventType.action]}
                    onChange={(e) => onSetEventValue(e, GoalEventType.action)}
                  />
                </Item>
                <Item>
                  <CheckIcon
                    className={cc({ checked: eventSelected[GoalEventType.label] })}
                    onClick={() => onSetEventSelected(GoalEventType.label)}
                  />
                  <Label className={cc({ checked: eventSelected[GoalEventType.label] })}>ラベル</Label>
                  <DesignedSelect
                    options={ConditionOptions}
                    defaultValue={eventCondition[GoalEventType.label] || ''}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onSetEventCondition(e, GoalEventType.label)}
                  />
                  <DesignedInput
                    type="text"
                    value={eventValue[GoalEventType.label]}
                    placeholder={'ラベル'}
                    required={eventSelected[GoalEventType.label]}
                    onChange={(e) => onSetEventValue(e, GoalEventType.label)}
                  />
                </Item>
                <Item>
                  <CheckIcon
                    className={cc({ checked: eventSelected[GoalEventType.value] })}
                    onClick={() => onSetEventSelected(GoalEventType.value)}
                  />
                  <Label className={cc({ checked: eventSelected[GoalEventType.value] })}>値</Label>
                  <DesignedSelect
                    options={ValueConditionOptions}
                    defaultValue={eventCondition[GoalEventType.value] || ''}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onSetEventCondition(e, GoalEventType.value)}
                  />
                  <DesignedInput
                    type="number"
                    value={eventValue[GoalEventType.value]}
                    min={0}
                    required={eventSelected[GoalEventType.value]}
                    onChange={(e) => onSetEventValue(e, GoalEventType.value)}
                  />
                </Item>
                {!isSelectedEvent && <ErrorMessage>少なくとも１つのイベント条件を指定してください。</ErrorMessage>}
              </CategoryBox>
            </>
          )}
          {isDisplayEstimatedRevenue && (
            <>
              <CategoryBox>
                <Category>
                  見込売上単価の設定
                  <HelpTooltip width={220}>
                    <HelpText>
                      ONにして1ゴールあたりの見込売上単価を設定すると、レポート上でページやコンテンツの貢献金額を可視化することが可能になります。
                    </HelpText>
                  </HelpTooltip>
                </Category>
                <Item>
                  <RadioButtons>
                    <RadioButton
                      buttonSize="normal"
                      message="ON"
                      checked={estimatedRevenueEnabled}
                      onChange={onSetEstimatedRevenueEnabled}
                    />
                    <RadioButton
                      buttonSize="normal"
                      message="OFF"
                      checked={!estimatedRevenueEnabled}
                      onChange={onSetEstimatedRevenueEnabled}
                    />
                  </RadioButtons>
                </Item>
              </CategoryBox>
              {estimatedRevenueEnabled && (
                <CategoryBox>
                  <Category>1ゴールあたりの見込売上単価</Category>
                  <EstimatedRevenueAmountItem>
                    <EstimatedRevenueAmountInput
                      type="number"
                      value={estimatedRevenueAmount ?? ''}
                      required
                      look={!!estimatedRevenueAmountErrorMessage ? 'red' : undefined}
                      min={ESTIMATED_REVENUE_AMOUNT_MIN}
                      max={ESTIMATED_REVENUE_AMOUNT_MAX}
                      placeholder="例) 3000"
                      style={{ marginLeft: '0' }}
                      onChange={onSetEstimatedRevenueAmount}
                      onBlur={validateEstimatedRevenueAmount}
                    />
                    <CurrencyUnit>JPY</CurrencyUnit>
                  </EstimatedRevenueAmountItem>
                  {!!estimatedRevenueAmountErrorMessage && (
                    <InputErrorMessage>{estimatedRevenueAmountErrorMessage}</InputErrorMessage>
                  )}
                </CategoryBox>
              )}
            </>
          )}
          {type === GoalType.purchase && (
            <PurchaseContainer
              autoSendPurchaseEnabled={autoSendPurchaseEnabled}
              onChangeAutoSendPurchaseEnabled={onSetAutoSendPurchaseEnabled}
            />
          )}
        </Container>
      </ModalContentWrapper>
      <ModalButtonWrapper>
        <CancelButton onClick={onCancel}>キャンセル</CancelButton>
        <NormalButton disabled={hasFormError() || !isFormDirty} onClick={onApply}>
          {saveButtonLabel}
        </NormalButton>
      </ModalButtonWrapper>
    </Modal>
  )
}

interface PurchaseContainerProps {
  autoSendPurchaseEnabled: boolean
  onChangeAutoSendPurchaseEnabled: () => void
}

function PurchaseContainer({ autoSendPurchaseEnabled, onChangeAutoSendPurchaseEnabled }: PurchaseContainerProps) {
  return (
    <>
      <CategoryBox>
        <WarningBoxContainer>
          <WarningBox>
            ECサイトで発生するイベント情報を格納しているdataLayerの形式が、Googleが推奨する形式
            <AdjustedSmall>*1</AdjustedSmall>{' '}
            に完全に一致している場合、Eコマースイベントをゴール設定に利用することができます。
            <br />
            <AdjustedSmall>
              *1 eventが"purchase"で設定され、イベント名、変数名、値の関係性などがGoogleが推奨するものを利用していること
            </AdjustedSmall>
            <br />
            <br />
            勝手にイベントの送信が行われるわけではなく、この画面でEコマースイベントゴールを作成し、さらにdataLayer自動送信オプションをONにしている場合に限り送信されます。
            <br />
            <br />
            ただし、dataLayer構造を変更することにより、急にデータが取れなくなる可能性があるため、変更を行う予定がある場合は、手動でのイベント設定をおすすめいたします。
            <br />
            <br />
            設定方法は
            <DesignedLink href={HELP_LINKS.PURCHASE_EVENT_SEND} target="_blank">
              こちら
            </DesignedLink>
            をご覧ください。
          </WarningBox>
        </WarningBoxContainer>
      </CategoryBox>
      <CategoryBox>
        <Category>dataLayer 自動送信</Category>
        <RadioButtons>
          <RadioButton
            buttonSize="normal"
            message="ON"
            checked={autoSendPurchaseEnabled}
            onChange={onChangeAutoSendPurchaseEnabled}
          />
          <RadioButton
            buttonSize="normal"
            message="OFF"
            checked={!autoSendPurchaseEnabled}
            onChange={onChangeAutoSendPurchaseEnabled}
          />
        </RadioButtons>
      </CategoryBox>
    </>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-width: 800px;
  height: 100%;
  gap: 24px;
`

const RadioButtons = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 24px;
`

const CategoryBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-left: 2px solid ${colors.white};

  &.bordered {
    border-left: 2px solid ${colors.error};
  }
`

const Category = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-bottom: 0.5rem;
`

const EventConditionLabel = styled(Category)`
  display: flex;
  align-items: center;
`

const HelpBox = styled.div`
  margin-left: 50px;
`

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  & + & {
    margin-top: 0.5rem;
  }
`

const Label = styled.div`
  font-weight: 600;
  width: 90px;

  &:not(.checked) {
    color: ${colors.gray400};
  }
`

const DesignedInput = styled(Input)`
  flex: 1;
  width: 100%;
  height: 30px;
  margin-left: 1rem;
  background: #ddd;
`

const EstimatedRevenueAmountItem = styled(Item)`
  gap: 4px;
`

const EstimatedRevenueAmountInput = styled(DesignedInput)`
  max-width: 320px;
`

const CurrencyUnit = styled.span`
  height: 24px;
  display: inline-flex;
  align-items: flex-end;
  line-height: 1;
  padding-bottom: 3px;
`

const DesignedSelect = styled(Select)`
  width: 130px;
  height: 30px;
  font-size: 14px;
  padding: 0.2rem 0.5rem;
`

const CheckIcon = styled(CheckCircle).attrs({
  size: 24,
  color: `${colors.gray400}`,
})`
  width: 30px;
  cursor: pointer;
  border-radius: 30px;
  margin-right: 1rem;

  &.checked {
    color: ${colors.orange};
  }
`

const ErrorMessage = styled.div`
  color: ${colors.error};
  margin: 1rem 0 0.5rem;
`
const WarningBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`

const AdjustedSmall = styled.small`
  vertical-align: top;
  font-size: smaller;
`

const DesignedLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: ${colors.link.base};

  &:hover {
    color: ${colors.link.hover};
  }

  &:visited {
    color: ${colors.link.visited};
  }
`

const HelpText = styled.div`
  font-weight: normal;
`
