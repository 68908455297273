import React from 'react'
import styled from 'styled-components'
import { HelpTooltip } from '../Tooltip/IconTooltip'
import { colors } from '../../styleConstants'

const helpPopupProps = {
  width: 250,
}

export function HelpPopupKeyField() {
  return (
    <HelpTooltip {...helpPopupProps}>
      <FlexBox>
        <Contents>
          キーフィールドとは、Content Analyticsのデータとインポートデータをつなぐためのキーになるフィールドのことです。
        </Contents>
      </FlexBox>
    </HelpTooltip>
  )
}

export function HelpPopupEnableDisable() {
  return (
    <HelpTooltip {...helpPopupProps}>
      <FlexBox>
        <Contents>削除を選択すると、フィールドとして取り込まれません。</Contents>
      </FlexBox>
    </HelpTooltip>
  )
}

const FlexBox = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`

const Contents = styled.div`
  color: ${colors.white};
`
