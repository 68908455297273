import * as React from 'react'
import { SearchOption } from '../../util/hooks/api/PageReport/types'
import { SORT_KEYS, SortKey } from '../../types/Report/PageReport/SortKey'
import { SORT_ICONS, SortIconState } from '../../components/common/SortButton'

export interface ContextType {
  readonly sortKey: SortKey
  readonly setSortKey: (value: SortKey) => void
  readonly sortIcon: SortIconState
  readonly setSortIcon: (value: SortIconState) => void
  readonly setSortValues: (newSortKey: string, newSortIcon: SortIconState) => void
  readonly getSortIcon: (value: SortKey) => SortIconState
  readonly searchText: string
  readonly setSearchText: (value: string) => void
  readonly searchOptions: SearchOption[]
  readonly setSearchOptions: (value: SearchOption[]) => void
}

/**
 * ページレポートで使用するstate情報を返す
 *
 * @return {ContextType} state and setter
 */
export function useContextState(): ContextType {
  const [sortKey, setSortKey] = React.useState<SortKey>(SORT_KEYS.SESSION_COUNT)
  const [sortIcon, setSortIcon] = React.useState<SortIconState>(SORT_ICONS.DOWN)
  const [searchText, setSearchText] = React.useState('')
  const [searchOptions, setSearchOptions] = React.useState<SearchOption[]>([])

  const setSortValues = (newSortKey: string, newSortIcon: SortIconState) => {
    setSortKey(newSortKey as SortKey)
    setSortIcon(newSortIcon)
  }

  const getSortIcon = (value: SortKey) => {
    return sortKey === value ? sortIcon : SORT_ICONS.NONE
  }

  return {
    sortKey,
    setSortKey,
    sortIcon,
    setSortIcon,
    setSortValues,
    getSortIcon,
    searchText,
    setSearchText,
    searchOptions,
    setSearchOptions,
  }
}
