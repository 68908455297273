import * as React from 'react'
import styled from 'styled-components'
import { Footprint } from '@styled-icons/remix-line/Footprint'
import { User } from '@styled-icons/boxicons-solid/User'
import { colors } from '../../styleConstants'
import { SCOPE_TYPE, ScopeType, setSearchParamsScopeType, useScopeType } from '../../util/hooks/useScopeType'

interface Props {
  onScopeTypeChange?: () => void
}

export function HeaderScopeTypeButtons({ onScopeTypeChange }: Props) {
  const { scopeType, setScopeType } = useScopeType()
  const [isPopupVisible, setIsPopupVisible] = React.useState(false)

  const handleChangeScopeType = (scopeType: ScopeType) => {
    setScopeType(scopeType)
    setSearchParamsScopeType(scopeType)
    onScopeTypeChange && onScopeTypeChange()
  }

  const handleMouseEnter = () => {
    setIsPopupVisible(true)
  }

  const handleMouseLeave = () => {
    setIsPopupVisible(false)
  }

  return (
    <ButtonContainer onMouseLeave={handleMouseLeave} data-testid="scope-type-buttons">
      <ButtonIcon onMouseEnter={handleMouseEnter}>
        {scopeType === SCOPE_TYPE.SESSION && (
          <>
            <Footprint size={24} color={colors.white} />
            <ButtonIconText>セッション</ButtonIconText>
          </>
        )}
        {scopeType === SCOPE_TYPE.USER && (
          <>
            <User size={24} color={colors.white} />
            <ButtonIconText>ユーザー</ButtonIconText>
          </>
        )}
      </ButtonIcon>

      {isPopupVisible && (
        <ButtonPopup>
          <ButtonPopupButton onClick={() => handleChangeScopeType(SCOPE_TYPE.SESSION)}>
            セッション数表示
          </ButtonPopupButton>
          <ButtonPopupButton onClick={() => handleChangeScopeType(SCOPE_TYPE.USER)}>ユーザー数表示</ButtonPopupButton>
        </ButtonPopup>
      )}
    </ButtonContainer>
  )
}

const ButtonContainer = styled.div`
  margin: 0;
  position: relative;
`

const ButtonIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 48px;
  border: 1px solid ${colors.white};
  border-width: 1px 1px 1px 0;
  cursor: pointer;
  ${ButtonContainer}:hover & {
    background: ${colors.contentBlue.blue5};
  }
`

const ButtonIconText = styled.div`
  margin: 3px 0 0;
  font-size: 10px;
  font-weight: bold;
  color: ${colors.white};
`

const ButtonPopup = styled.div`
  position: absolute;
  top: calc(100%);
  right: 0;
  z-index: 1;
  padding: 8px 0;
  background: ${colors.white};
`
const ButtonPopupButton = styled.a`
  display: block;
  padding: 8px 16px;
  width: 160px;
  font-size: 16px;
  line-height: 1;
  cursor: pointer;
  &:hover {
    background: ${colors.gray100};
  }
`
