import React from 'react'
import styled from 'styled-components'

import { colors } from '../../../styleConstants'
import { ChartData } from 'chart.js'
import { Chart as ChartComponent } from 'react-chartjs-2'
import ChartDataLabels from 'chartjs-plugin-datalabels'

export const MULTIPLE_CHART_HEIGHT = 300

// 背景色付きグラフエリアの外側の余白
const LAYOUT_PADDING = {
  top: 24,
  right: 10,
  bottom: 0,
  left: 10,
}

const BAR_GRAPH_DATA_LABEL_COLOR = colors.contentBlue.blue6
const LINE_GRAPH_DATA_LABEL_COLOR = colors.contentOrange.orange7

const SCALE_BORDER_OPTIONS = {
  dash: [4, 1], // Gridを点線で表示する
  color: colors.gray650,
  z: 1, // X軸の0の実線が棒グラフデータとかぶらないように調整
}

const SCALE_GRID_OPTIONS = {
  tickLength: 4, // tickエリアの領域を狭める
  lineWidth: (context: any) => (context.tick?.value > 0 ? 1 : 0), // 0の線には点線が表示されないようにlineWidthを0でセットする
}

export interface MultipleChartDataset {
  label: string
  data: number[]
  formattedData: string[]
  isPercentage?: boolean
  isAmount?: boolean
  maxAmount?: number
}
interface Props {
  data: {
    labels: string[]
    datasets: (MultipleChartDataset | undefined)[]
  }
}

/**
 * 棒グラフと折れ線グラフを組み合わせたグラフを描画する
 * @param {Props} data - グラフに表示するデータ
 * @param {string[]} data.labels - グラフのX軸に表示するラベル
 * @param {MultipleChartDataset[]} data.datasets - グラフに表示するデータセット
 * @param {string} data.datasets[].label - データセットのラベル
 * @param {number[]} data.datasets[].data - データセットの値。率系の場合はAPI Hook側で事前にPercent.parseでパーセント表記用に変換している
 * @param {string[]} data.datasets[].formattedData - データセットの値をフォーマットした文字列。率系の場合はパーセント表記に変換している
 * @param {boolean} data.datasets[].isPercentage - データセットの値がパーセンテージかどうか。 trueにすると指標やtooltipがパーセンテージ表記になる
 * @returns {JSX.Element} - 棒グラフと折れ線グラフを組み合わせたグラフ
 */
export function MultipleChart({ data }: Props) {
  const firstDataset = data.datasets[0]
  const secondDataset = data.datasets[1]

  const graphData: ChartData = {
    labels: data.labels,
    datasets: [
      {
        type: 'bar' as const,
        label: firstDataset?.label,
        data: firstDataset?.data || [],
        backgroundColor: colors.lightCyan,
        yAxisID: 'yleft',
        datalabels: {
          color: BAR_GRAPH_DATA_LABEL_COLOR,
        },
        order: 2, // 折れ線グラフを手前、棒グラフを奥に描画し、折れ線グラフが棒グラフの影に隠れないようにする
      },
      {
        type: 'line' as const,
        label: secondDataset?.label,
        data: secondDataset?.data || [],
        borderColor: colors.contentOrange.orange4,
        borderWidth: 2,
        yAxisID: 'yright',
        datalabels: {
          color: LINE_GRAPH_DATA_LABEL_COLOR,
        },
        order: 1, // 折れ線グラフを手前、棒グラフを奥に描画し、折れ線グラフが棒グラフの影に隠れないようにする
      },
    ],
  }

  // グラフ外側のX軸・Y軸に表示される目盛りの設定
  const scales = {
    x: {
      stacked: false,
      border: SCALE_BORDER_OPTIONS,
      grid: SCALE_GRID_OPTIONS,
    },
    yleft: {
      stacked: false,
      ticks: {
        display: !!firstDataset,
        color: BAR_GRAPH_DATA_LABEL_COLOR,
        maxTicksLimit: 5,
        callback: (value: number) =>
          formatTickLabel(value, firstDataset?.isPercentage, firstDataset?.isAmount, firstDataset?.maxAmount),
      },
      border: SCALE_BORDER_OPTIONS,
      grid: {
        display: !!firstDataset,
        ...SCALE_GRID_OPTIONS,
      },
    },
    yright: {
      stacked: false,
      position: 'right',
      border: SCALE_BORDER_OPTIONS,
      ticks: {
        display: !!secondDataset,
        color: LINE_GRAPH_DATA_LABEL_COLOR,
        maxTicksLimit: 5,
        callback: (value: number) =>
          formatTickLabel(value, secondDataset?.isPercentage, secondDataset?.isAmount, secondDataset?.maxAmount),
      },
      grid: {
        display: !firstDataset && !!secondDataset, // 1軸目表示中は2軸目を非表示(線の間隔が見づらくなる場合があるため)
        ...SCALE_GRID_OPTIONS,
      },
    },
  }

  // マウスホバー時に表示されるツールチップの設定
  const tooltip = {
    displayColors: false, // ラベル右に表示される四角いボックス
    backgroundColor: colors.white + colors.opacity[90],
    titleColor: colors.gray750,
    titleSpacing: 10,
    bodySpacing: 8,
    borderColor: colors.gray250,
    borderWidth: 1,
    padding: 10,
    titleFont: {
      size: 14,
    },
    bodyFont: {
      size: 14,
      weight: 'bold',
    },
    callbacks: {
      label: (context: any) => {
        const label = context.dataset.label || ''
        const value = data.datasets[context.datasetIndex]?.formattedData[context.dataIndex]
        return `${label}: ${value}`
      },
      labelTextColor: (context: any) =>
        context.dataset.label === firstDataset?.label ? colors.contentBlue.blue5 : colors.contentOrange.orange6,
    },
    // ツールチップの表示順序をデータセットの順序に合わせる
    itemSort: (a: any, b: any) => a.datasetIndex - b.datasetIndex,
  }

  // 各バーの上に表示されるラベルの設定
  const datalabels = {
    anchor: 'end',
    align: 'top',
    offset: -5, // offset: 表示位置をどれくらい上に上げるか。defaultは4
    formatter: (_value: number, context: any) => {
      const dataset = data.datasets[context.datasetIndex]
      if (!dataset) return ''

      // 最大値のみ表示設定のデータセットは、最大値以外は非表示とする
      // 金額系は桁数が多くなり、ラベルが重なりグラフが見づらくなるため。
      if (dataset.maxAmount && dataset.data[context.dataIndex] != dataset.maxAmount) return ''

      return dataset.formattedData[context.dataIndex]
    },
    font: {
      size: 12,
      weight: 'bold',
    },
  }

  const options: {} = {
    maintainAspectRatio: false,
    animation: {
      duration: 200,
    },
    scales,
    interaction: {
      mode: 'index', // [mode: 'index']: ユーザーがグラフ上の特定の点をホバーしたときに、その x軸に沿った上下すべてのデータセット(日付 & 数 & 率など)をまとめてツールチップ表示するために指定が必要。指定しなければ2項目(日付 & 数のみ、率のみ)しか表示されなくなる
      intersect: false, // [intersect: false]: ユーザーがデータポイントの正確な位置にカーソルを合わせなくても、そのデータポイントが強調表示されるようになる。バーグラフであれば、バーとバーの間の空白でもホバーが反応するようになり利便性が上がる
    },
    layout: {
      padding: LAYOUT_PADDING,
    },
    plugins: {
      title: {
        display: false,
      },
      tooltip,
      datalabels,
      legend: {
        display: false, // デフォルトの凡例は非表示にする
      },
    },
  }

  return (
    <Container>
      <ChartComponent
        type="bar"
        data={graphData}
        options={options}
        height={MULTIPLE_CHART_HEIGHT}
        plugins={[ChartDataLabels]}
      />
    </Container>
  )
}

const Container = styled.div`
  display: block;
  font-size: 12px;
`

/**
 * 数値をフォーマットする共通関数
 * @param value - フォーマットする数値
 * @param isPercentage - パーセンテージとして表示するかどうかのフラグ
 * @param isAmount - 金額として「xx万」形式で表示するかどうかのフラグ
 * @returns フォーマットされた文字列
 */
function formatTickLabel(value: number, isPercentage?: boolean, isAmount?: boolean, maxAmount?: number): string {
  if (isAmount) {
    // 不正な値、もしくは0の位置は単位なしの0で表示する
    if (!value || !maxAmount) return '0'

    // 最大値が1万以上の場合は、単位を「万」で表示する
    if (maxAmount >= 10000) {
      // 1万で割った後の値が整数かどうかをチェック
      const valueInMan = value / 10000
      // 整数の場合はtoFixed(0)を使用し、そうでない場合はtoFixed(1)を使用
      const formattedValue = Number.isInteger(valueInMan) ? valueInMan.toFixed(0) : valueInMan.toFixed(1)
      return `${formattedValue.toLocaleString()}万円`
    }

    return `${value.toLocaleString()}円`
  }

  if (isPercentage) return `${value}%`

  return value.toLocaleString()
}
